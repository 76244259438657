import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import InstaPayLoader from '../Loader'

const PaymentStatus = () => {
    const { type, status, code } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        navigate(`/payments?payment_action=send_money&send_type=${type}&transaction_status=${status}&transaction_id=test&code=${code},`)
    }, [])
    return (
        <div style={{
            height: "100vh",
            width: "100vw"
        }}>
            <InstaPayLoader />
        </div>
    )
}

export default PaymentStatus