export const id = {
    "How Others Can Find You": "Bagaimana Orang Lain Bisa Menemukan Anda",
    "Schedules": "Jadwal",
    "Login": "Masuk",
    "Start Your InstaPay Journey Today!": "Mulai Perjalanan InstaPay Anda Hari Ini!",
    "Phone Number or Email": "Nomor Telepon atau Email",
    "Login Using Social Media": "Masuk dengan Media Sosial",
    "Continue": "Lanjutkan",
    "Don't Have an Account? Sign Up": "Belum Punya Akun? Daftar",
    "Sign Up": "Daftar",
    "Edit": "Edit",
    "Enter Your Password": "Masukkan Kata Sandi Anda",
    "Forgot Password?": "Lupa Kata Sandi?",
    "Account Type": "Jenis Akun",
    "Individual": "Individu",
    "Business": "Bisnis",
    "Already Have an Account? Sign In": "Sudah Punya Akun? Masuk",
    "Sing In": "Masuk",
    "Enter Your Personal Details": "Masukkan Detail Pribadi Anda",
    "Add Photo or Logo": "Tambahkan Foto atau Logo",
    "Enter Your Name": "Masukkan Nama Anda",
    "Back": "Kembali",
    "Phone Number": "Nomor Telepon",
    "Email Address": "Alamat Email",
    "Create a Strong Password": "Buat Kata Sandi Kuat",
    "Password Requirements": "Persyaratan Kata Sandi",
    "Must be between 9 and 64 characters": "Harus terdiri dari 9 hingga 64 karakter",
    "Include at least two of the following:": "Harus mengandung setidaknya dua dari yang berikut:",
    "Uppercase character": "Huruf besar",
    "Lowercase character": "Huruf kecil",
    "Number": "Angka",
    "Special character": "Karakter khusus",
    "Confirm Password": "Konfirmasi Kata Sandi",
    "Finalize Your Process": "Selesaikan Proses Anda",
    "Referral Code (Optional)": "Kode Referral (Opsional)",
    "It will autofill if you're signing up with an invitation link.": "Akan diisi otomatis jika Anda mendaftar dengan tautan undangan.",
    "I agree with": "Saya setuju dengan",
    "Terms & Conditions": "Ketentuan dan Syarat",
    "Privacy Policy": "Kebijakan Privasi",
    "Create Your Account": "Buat Akun Anda",
    "Enter Code": "Masukkan Kode",
    "A one-time verification code has been sent to your email address  and phone number.": "Kode verifikasi satu kali telah dikirimkan ke alamat email dan nomor telepon Anda.",
    "Resend available in ": "Kirim Ulang tersedia dalam",
    "seconds": "detik",
    "Verify": "Verifikasi",
    "Verification Code Verified": "Kode Verifikasi Terverifikasi",
    "Please continue to the next step.": "Silakan lanjut ke langkah berikutnya.",
    "Finish": "Selesai",
    "Finishing": "Menyelesaikan",
    "Company Name": "Nama Perusahaan",
    "Enter Your Business Details": "Masukkan Detail Bisnis Anda",
    "Country ": "Negara",
    "You cannot change the country once your account is verified.": "Anda tidak dapat mengubah negara setelah akun Anda diverifikasi.",
    "Learn more": "Pelajari lebih lanjut",
    "Optional": "Opsional",
    "Continuing": "Lanjutkan",
    "Didn't receive the verification code?": "Tidak menerima kode verifikasi?",
    "Resend Now": "Kirim Ulang Sekarang",
    "Search Here": "Cari Di Sini",
    "Search for users, services, and more on InstaPay": "Cari pengguna, layanan, dan lebih banyak lagi di InstaPay",
    "Search users here": "Cari pengguna di sini",
    "Earn money with our affiliate program!": "Dapatkan uang dengan program afiliasi kami!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Hasilkan penghasilan pasif dengan membagikan kode afiliasi InstaPay Anda kepada pengikut Anda. Untuk setiap transaksi yang mereka lakukan, Anda akan \nmendapatkan komisi, sehingga memaksimalkan keuntungan dari pengaruh online Anda.",
    "Learn More": "Pelajari Lebih Lanjut",
    "Balance Amount": "Jumlah Saldo",
    "You spent less compared to last month.": "Anda mengeluarkan lebih sedikit dibandingkan dengan bulan lalu.",
    "Referral Rewards": "Hadiah Referral",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Transfer Cepat ke Kontak Terbaru Anda:",
    "Number of Transactions": "Jumlah Transaksi",
    "Total Transaction Amount": "Jumlah Total Transaksi",
    "Total Sent": "Total Dikirim",
    "Total Received": "Total Diterima",
    "Add Funds": "Tambah Dana",
    "Send Money": "Kirim Uang",
    "Request Money": "Minta Uang",
    "Send a Quote": "Kirim Kutipan",
    "Pay With Crypto": "Bayar dengan Kripto",
    "Payment Insights": "Wawasan Pembayaran",
    "Recent Transactions ": "Transaksi Terbaru",
    "Today": "Hari Ini",
    "My Wallets": "Dompet Saya",
    "Balance": "Saldo",
    "Wallet ID": "ID Dompet",
    "Monthly Limit": "Batas Bulanan",
    "Top Up": "Isi Ulang",
    "Summary": "Ringkasan",
    "Settings": "Pengaturan",
    "More": "Lainnya",
    "Upgrade to Business Account": "Upgrade ke Akun Bisnis",
    "Conversion": "Konversi",
    "Enter Amount": "Masukkan Jumlah",
    "Exchanged Amount": "Jumlah yang Dikonversi",
    "Convert": "Konversi",
    "You Are Converting": "Anda Mengonversi",
    "From": "Dari",
    "To": "Ke",
    "Exchange Rate": "Kurs Tukar",
    "Fees": "Biaya",
    "You Will Receive": "Anda Akan Menerima",
    "Slide To Confirm": "Geser Untuk Konfirmasi",
    "Verification Code Confirmed - You'll be redirected to the next step": "Kode Verifikasi Dikonfirmasi - Anda akan diarahkan ke langkah berikutnya",
    "Moving to next step in": "Pindah ke langkah berikutnya dalam",
    "Thanks for using InstaPay": "Terima kasih telah menggunakan InstaPay",
    "Your funds have been credited to your wallet.": "Dana Anda telah dikreditkan ke dompet Anda.",
    "Dashboard": "Dasbor",
    "Accounts": "Akun",
    "Transactions": "Transaksi",
    "Other Transactions": "Transaksi Lainnya",
    "Payments": "Pembayaran",
    "Beneficiaries": "Penerima Manfaat",
    "Referrals": "Referral",
    "Quotations": "Kutipan",
    "Pending": "Menunggu",
    "My QR Code Sticker": "Stiker Kode QR Saya",
    "My Portfolio": "Portofolio Saya",
    "My Payment Address": "Alamat Pembayaran Saya",
    "Analytics": "Analitika",
    "Profile": "Profil",
    "Dark Mode": "Mode Gelap",
    "Support": "Dukungan",
    "Logout": "Keluar",
    "Powered By": "Diberdayakan Oleh",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Sebuah Perusahaan Fintech Swiss",
    "MAIN MENU": "MENU UTAMA",
    "OTHERS": "LAINNYA",
    "Wallet": "Wallet (Dompet)",
    "Default": "Default (Bawaan)",
    "Breakdown": "Pembagian (Pemecahan)",
    "Credit": "Kredit (Kartu Kredit)",
    "Debit": "Debit (Kartu Debit)",
    "Wallet Management": "Manajemen Wallet (Dompet)",
    "Top-up Your Wallet": "Isi Ulang Wallet Anda",
    "Download Statement": "Unduh Laporan (Pernyataan)",
    "Block the Wallet": "Blokir Wallet (Dompet)",
    "Wallet Status": "Status Wallet (Dompet)",
    "-Select-File-Type-": "-Pilih-Jenis-Berkas-",
    "Download Now": "Unduh Sekarang",
    "Downloading": "Sedang Mengunduh",
    "All": "Semua",
    "Sent": "Dikirim",
    "Received": "Diterima",
    "Requested": "Diminta",
    "Quoted": "Dikutip",
    "Search Transactions": "Cari Transaksi",
    "Date & Time": "Tanggal & Waktu",
    "Type": "Jenis",
    "Transaction ID": "ID Transaksi",
    "Recipient": "Penerima",
    "Amount": "Jumlah",
    "Status": "Status",
    "Payment Type": "Jenis Pembayaran",
    "Sent Payments": "Pembayaran Dikirim",
    "Received Payments": "Pembayaran Diterima",
    "Date": "Tanggal",
    "Cycles/Time": "Siklus/Waktu",
    "Payment As": "Pembayaran Sebagai",
    "Name": "Nama",
    "Cancel": "Batalkan",
    "Subscriptions & Scheduled": "Langganan & Jadwal",
    "Select the option that matches your needs from the list below:": "Pilih opsi yang sesuai dengan kebutuhan Anda dari daftar di bawah ini:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Mudah mengirim uang kepada teman, keluarga, atau bisnis secara lokal maupun internasional. Pilih dari berbagai opsi transfer, termasuk deposito bank, \ndompet mobile, penarikan tunai, kartu pembayaran, dan transfer antar dompet. Nikmati kenyamanan dan keamanan InstaPay untuk transfer uang yang \nlancar dan instan.",
    "International Transfer": "Transfer Internasional",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Kirim uang melintasi batas negara dengan mudah. Transfer dana secara internasional ke teman, keluarga, atau bisnis hanya dalam beberapa klik. Pilih dari\nberbagai saluran pembayaran termasuk rekening bank, uang mobile/dompet mobile, lokasi penarikan tunai, atau kartu pembayaran\nuntuk transaksi yang nyaman dan aman.",
    "Select the country where you want to send the money!": "Pilih negara tempat Anda ingin mengirim uang!",
    "Search Country": "Cari Negara",
    "Trouble Sending Money?": "Masalah dalam Mengirim Uang?",
    "Confirm": "Konfirmasi",
    "Select Payout Channel": "Pilih Saluran Pencairan",
    "Select your most convenient option to send the money.": "Pilih opsi yang paling nyaman bagi Anda untuk mengirim uang.",
    "Select The Bank": "Pilih Bank",
    "Search Bank": "Cari Bank",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Kirim uang langsung ke rekening bank penerima. Nikmati kenyamanan dan keamanan dalam mentransfer dana dengan lancar ke bank mana pun di seluruh dunia.",
    "Bank Transfer": "Transfer Bank",
    "Enter the amount": "Masukkan jumlah",
    "Tax": "Pajak",
    "Amount the recipient will receive": "Jumlah yang akan diterima oleh penerima",
    "Select Remarks / Reason": "Pilih Catatan / Alasan",
    "Comments": "Komentar",
    "Attach Files": "Lampirkan Berkas",
    "Groups": "Grup",
    "Manage Groups": "Kelola Grup",
    "Add New Group": "Tambahkan Grup Baru",
    "Invite": "Undang Seseorang",
    "Manage Beneficiaries": "Kelola Penerima",
    "Recipients List": "Daftar Penerima",
    "Search Contacts": "Cari Kontak",
    "Confirm Your Payment": "Konfirmasi Pembayaran Anda",
    "Select Country": "Pilih Negara",
    "Select the Recipient's Payment Channel": "Pilih Saluran Pembayaran Penerima",
    "Change Selection": "Ubah Pilihan",
    "You Are Transferring ": "Anda Sedang Mentransfer",
    "Oops! Something Went Wrong.": "Oops! Ada Masalah.",
    "We're Sorry About That. ": "Maaf atas itu.",
    "Go back to Payments Page.": "Kembali ke Halaman Pembayaran.",
    "Wallet to Wallet Transfer": "Transfer Dompet ke Dompet",
    "Request a customized price quote from service providers or vendors.": "Minta penawaran harga yang disesuaikan dari penyedia layanan atau vendor.",
    "Enter the Wallet ID": "Masukkan ID Dompet",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Masukkan ID Dompet, Email, atau Nomor Telepon penerima.",
    "Recipient not on InstaPay?": "Penerima Tidak Ada di InstaPay? Undang Mereka.",
    "Invite Them": "Undang Mereka",
    "My Beneficiaries": "Penerima Manfaat Saya",
    "Enter the Amount You Wish to Transfer.": "Masukkan Jumlah yang Ingin Anda Transfer.",
    "Sending to ": "Mengirim ke",
    "You Have Selected Wallet to Wallet Transfer": "Anda Telah Memilih Transfer Dompet ke Dompet",
    "Authenticate Your Payment.": "Autentikasi Pembayaran Anda.",
    "You are transferring": "Anda sedang mentransfer",
    "Total ": "Total",
    "Where Is My Payment?": "Di Mana Pembayaran Saya?",
    "Share": "Bagikan",
    "Your Payment Is Confirmed": "Pembayaran Anda Telah Dikonfirmasi",
    "QR Code Payment": "Pembayaran dengan Kode QR",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Transfer uang secara instan kepada pengguna InstaPay lainnya dengan layanan dompet-ke-dompet yang aman kami. Kirim dan terima dana tanpa masalah dalam hitungan detik.",
    "Scan QR with Your Camera or Upload from Your Device.": "Pindai Kode QR dengan Kamera Anda atau Unggah dari Perangkat Anda.",
    "Upload from Your Device.": "Unggah dari Perangkat Anda.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Atau, Gunakan Alamat Pembayaran Unik InstaPay (UPA)",
    "Enter InstaPay UPA": "Masukkan InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Penerima tidak ada di InstaPay? Undang Mereka",
    "Verify QR Code": "Verifikasi Kode QR",
    "Scan/Upload QR Code of the Recipient": "Pindai/Unggah Kode QR Penerima",
    "Payment Confirmed": "Pembayaran Dikonfirmasi",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Minta penawaran harga yang disesuaikan dari penyedia layanan atau vendor untuk menerima perkiraan biaya yang akurat untuk layanan atau produk yang Anda butuhkan.",
    "Choose The Beneficiary or Group": "Pilih Penerima Manfaat atau Grup",
    "Enter the Amount You Wish to Request.": "Masukkan Jumlah yang Ingin Anda Minta.",
    "You Are Requesting Money": "Anda Sedang Meminta Uang",
    "You are requesting": "Anda sedang meminta",
    "As": "Sebagai",
    "Conversion of Your Cryptocurrencies": "Konversi Kripto Anda",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Alami konversi Kripto ke Fiat yang berorientasi pada pengguna, aman, dan efisien.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Pilih Kripto yang Akan Anda Likuidasi.",
    "Next": "Selanjutnya",
    "Enter the Crypto Wallet Address of the Recipient.": "Masukkan Alamat Dompet Kripto Penerima.",
    "Minimum Transactional Value: $100.": "Nilai Transaksi Minimum: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Catatan: Harga Kripto sering berubah. Lihat kutipan akhir di halaman pesanan konfirmasi.",
    "Select Network": "Pilih Jaringan",
    "Send A Quote": "Kirim Kutipan",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Kirim kutipan harga yang detail kepada klien atau pelanggan Anda. Sajikan layanan, produk, atau penawaran Anda secara profesional dengan kutipan yang dipersonalisasi.",
    "Choose the Beneficiary": "Pilih Penerima Manfaat",
    "Allow Receiver to Bargain": "Izinkan Penerima untuk Tawar-menawar",
    "Enter the Title for Your Quotation.": "Masukkan Judul Kutipan Anda.",
    "Enter the Description for Your Quotation": "Masukkan Deskripsi Kutipan Anda",
    "You are Quoting": "Anda Sedang Mengutip",
    "Title": "Judul",
    "Description": "Deskripsi",
    "Attachments": "Lampiran",
    "Confirm Your Quotation": "Konfirmasi Kutipan Anda",
    "Verify The Quotation": "Verifikasi Kutipan",
    "Your quotation is confirmed": "Kutipan Anda telah dikonfirmasi",
    "Request A Quote": "Minta Penawaran",
    "Coming Soon": "Segera Hadir",
    "Add Beneficiary": "Tambah Penerima Manfaat",
    "Search for Beneficiaries": "Cari Penerima Manfaat",
    "Connected Accounts": "Akun Terhubung",
    "Details": "Detail",
    "First Name": "Nama Depan",
    "Last Name": "Nama Belakang",
    "Country": "Negara",
    "City": "Kota",
    "Address Line": "Alamat",
    "Mobile Number": "Nomor Telepon Seluler",
    "Relationship With Beneficiary": "Hubungan dengan Penerima Manfaat",
    "Bank Account": "Rekening Bank",
    "Mobile Money/Mobile Wallet": "Uang Mobile/Dompet Mobile",
    "Payment Card": "Kartu Pembayaran",
    "Crypto Wallet": "Dompet Kripto",
    "InstaPay Account": "Akun InstaPay",
    "Cash Pickup": "Penarikan Tunai",
    "IBAN Or Swift/BIC code": "IBAN atau Kode Swift/BIC",
    "Swift Code": "Kode Swift",
    "Account Number": "Nomor Rekening",
    "Bank Name": "Nama Bank",
    "Branch Name": "Nama Cabang",
    "Branch Street": "Alamat Cabang",
    "Province": "Provinsi",
    "Postal Code": "Kode Pos",
    "Mobile Money Provider": "Penyedia Uang Mobile",
    "Wallet Name": "Nama Dompet",
    "Wallet Number": "Nomor Dompet",
    "Card Holder Name": "Nama Pemegang Kartu",
    "Card Number": "Nomor Kartu",
    "Expiry Date": "Tanggal Kadaluarsa",
    "Crypto Currency": "Mata Uang Kripto",
    "Wallet Address": "Alamat Dompet",
    "Wallet Holder Name": "Nama Pemegang Dompet",
    "Wallet Currency": "Mata Uang Dompet",
    "Select Document Type": "Pilih Jenis Dokumen",
    "Enter Document Number": "Masukkan Nomor Dokumen",
    "Add Individual Account": "Tambahkan Akun Individu",
    "Add Business Account": "Tambahkan Akun Bisnis",
    "Company Address": "Alamat Perusahaan",
    "Company Email": "Alamat Email Perusahaan",
    "Company Phone No": "Nomor Telepon Perusahaan",
    "Total Referrals": "Total Referensi",
    "My Earnings": "Pendapatan Saya",
    "Your Referral ID": "ID Referensi Anda",
    "Share Your Unique Referral Link": "Bagikan Tautan Referensi Unik Anda",
    "How do I Refer a Friend to InstaPay?": "Bagaimana Cara Mengajak Teman ke InstaPay?",
    "Step 01": "Langkah 01",
    "Share your InstaPay unique referral link with your friends.": "Bagikan tautan referensi unik InstaPay Anda kepada teman-teman Anda.",
    "Step 02": "Langkah 02",
    "Earn rewards when they transact $100 or more": "Dapatkan hadiah saat mereka bertransaksi sebesar $100 atau lebih.",
    "Step 03": "Langkah 03",
    "Earn up to $0.06 for every transaction your followers make.": "Dapatkan hingga $0.06 untuk setiap transaksi yang dilakukan pengikut Anda.",
    "My Referrals": "Referensi Saya",
    "Referral Complete": "Referensi Selesai",
    "Received Quotations": "Penawaran Diterima",
    "Sent Quotations": "Penawaran Dikirim",
    "Search Pending Items": "Cari Item yang Tertunda",
    "Actions": "Tindakan",
    "Received Requests": "Permintaan Diterima",
    "Sent Requests": "Permintaan Dikirim",
    "No Actions": "Tidak Ada Tindakan",
    "You Requested": "Anda Meminta",
    "Via": "Melalui",
    "How was your experience?": "Bagaimana pengalaman Anda?",
    "Leave a Review": "Tinggalkan Ulasan",
    "Overall Ratings": "Peringkat Keseluruhan",
    "Visit Profile": "Kunjungi Profil",
    "Ratings": "Peringkat",
    "Reviews": "Ulasan",
    "No reviews available": "Tidak ada ulasan yang tersedia",
    "Select Your Currency": "Pilih Mata Uang Anda",
    "Proceed": "Lanjutkan",
    "Commision": "Komisi",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Buka Transaksi Lancar dengan Stiker Kode QR InstaPay",
    "Effortless and Swift:": "Mudah dan Cepat:",
    "Instantly download your unique InstaPay QR code sticker.": "Unduh segera stiker kode QR InstaPay unik Anda.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Nikmati pembayaran tanpa uang tunai tanpa perlu infrastruktur rumit seperti terminal pembayaran.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Kumpulkan pembayaran dengan cepat - tidak lagi perlu memasukkan nomor kartu kredit atau telepon secara manual.",
    "Each payment includes customer information for your convenience.": "Setiap pembayaran mencakup informasi pelanggan untuk kenyamanan Anda.",
    "Economically Smart:": "Ekonomis Cerdas:",
    "Zero activation fees – start immediately without upfront costs.": "Biaya aktivasi nol - mulailah segera tanpa biaya awal.",
    "Forget about fixed monthly charges; pay as you transact.": "Lupakan biaya bulanan tetap; bayar sesuai dengan transaksi Anda.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Tinggalkan perangkat keras; tidak ada biaya terminal berarti lebih banyak penghematan bagi Anda.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Nikmati biaya transaksi rendah hanya 0.75% per pembayaran kode QR.",
    "We don't impose minimum commissions; you save more with each transaction.": "Kami tidak memberlakukan komisi minimum; Anda menghemat lebih banyak dengan setiap transaksi.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Nikmati kegembiraan pembayaran instan, meningkatkan aliran kas Anda.",
    "Make Every Transaction Count with InstaPay": "Buat Setiap Transaksi Berarti dengan InstaPay",
    "Wallet QR": "Dompet QR",
    "Download QR": "Unduh QR",
    "QR Code Status": "Status Kode QR",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Bagikan kode QR atau tautan alamat pembayaran Anda untuk segera menerima pembayaran dalam dompet InstaPay Anda.",
    "QR Code Title": "Judul Kode QR",
    "View Transactions": "Lihat Transaksi",
    "My QR Codes:": "Kode QR Saya:",
    "Load More": "Muat Lebih Banyak",
    "Benefits Of Portfolio": "Manfaat Portofolio",
    "Benefits:": "Manfaat:",
    "Enhanced Visibility:": "Visibilitas Meningkat:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Tingkatkan profil publik Anda. Portofolio Anda berfungsi sebagai pameran dinamis, dapat diakses langsung dari Alamat Pembayaran Anda.",
    "Engage Your Audience:": "Engage Audience Anda:",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Terhubung dengan pengunjung melalui perjalanan kreatif atau profesional Anda. Biarkan karya Anda berbicara untuk Anda, menciptakan keterlibatan yang lebih dalam \ndengan calon klien atau pendukung.",
    "Integrated Social Proof:": "Bukti Sosial Terintegrasi:",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Tampilkan pengaruh media sosial Anda. Pengunjung dapat melihat jangkauan media sosial Anda, menambah kredibilitas dan konteks pada portofolio Anda.",
    "Seamless Transactions:": "Transaksi Lancar:",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Mengaktifkan pengunjung untuk meminta atau memulai pembayaran langsung dari halaman publik Anda. Portofolio Anda tidak hanya memamerkan karya Anda tetapi juga \nmemfasilitasi interaksi keuangan yang mudah.",
    "Personalized Storytelling:": "Penceritaan yang Dipersonalisasi:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Gunakan bagian 'Tentang Saya' untuk berbagi cerita, misi, atau visi Anda, menciptakan koneksi pribadi dengan audiens Anda.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "My Portfolio' lebih dari sekadar galeri; itu adalah alat untuk membangun merek Anda, terlibat dengan audiens Anda, dan menyederhanakan interaksi keuangan Anda di InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Tampilkan Karya Anda, Perbesar Jangkauan Anda. 'My Portfolio' adalah kanvas digital Anda di InstaPay. Unggah dan tampilkan berbagai konten - mulai dari gambar dan video yang \nmenarik hingga PDF informatif. Baik Anda seorang seniman, freelancer, atau pengusaha, fitur ini memungkinkan Anda untuk menyajikan karya atau proyek Anda dengan cara yang \nmenarik secara visual.",
    "Add/Manage Portfolio": "Tambahkan/Kelola Portofolio",
    "Add New": "Tambahkan Baru",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maksimalkan Pendapatan Media Sosial Anda dengan Alamat Pembayaran InstaPay Anda",
    "Make It Effortless for Your Audience to Support You:": "Buatnya Mudah bagi Audiens Anda untuk Mendukung Anda:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Integrasikan dengan mudah Alamat Pembayaran InstaPay Anda ke dalam postingan media sosial Anda.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Salin dan tempel tautan alamat pembayaran Anda ke setiap postingan, cerita, atau secara menonjol di profil Anda.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideal untuk pengaruh, seniman, pengusaha, atau siapa pun dengan komunitas yang ingin menunjukkan dukungannya.",
    "Instant Support, Endless Possibilities:": "Dukungan Instan, Kemungkinan Tak Terbatas:",
    "Transform the way you monetize your social media content.": "Ubah cara Anda mendapatkan uang dari konten media sosial Anda.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Izinkan pengikut Anda untuk mengirimkan dana kepada Anda secara instan dengan sekali klik, langsung dari jaringan sosial favorit mereka.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideal untuk dukungan waktu nyata selama siaran langsung, sebagai sistem tip untuk konten kreatif Anda, atau untuk mendanai proyek besar berikutnya Anda.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Tempatkan Alamat Pembayaran InstaPay Anda untuk Dampak Maksimum:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Profil Media Sosial: Tempelkan di bio atau bagian tentang Anda untuk visibilitas konstan.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Posting & Pengumuman: Gabungkan ke dalam postingan individual, khususnya yang menyoroti karya Anda atau panggilan dukungan tertentu.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Cerita & Reels: Integrasikan ke dalam cerita interaktif atau reel yang menarik untuk menangkap kontribusi instan.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Siaran Langsung: Bagikan selama sesi LANGSUNG Anda, sehingga mudah bagi pemirsa untuk berkontribusi secara waktu nyata.",
    "Simple, Fast, and Secure:": "Mudah, Cepat, dan Aman:",
    "No complex setup – get started in moments.": "Tidak ada pengaturan rumit - mulailah dalam sekejap.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Nikmati keamanan dan kecepatan pemrosesan pembayaran tepercaya InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Terima pembayaran dari siapa saja, di mana saja, tanpa mereka perlu memiliki akun InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Tingkatkan Permainan Media Sosial Anda dengan Alamat Pembayaran InstaPay",
    "InstaPay VPA (Virtual Payment Address)": "Alamat Pembayaran Virtual InstaPay (VPA)",
    "Payment Address Title": "Judul Alamat Pembayaran",
    "Currency": "Mata Uang",
    "Payment Address Description": "Deskripsi Alamat Pembayaran",
    "Update": "Perbarui",
    "Updating": "Memperbarui",
    "Total Transaction": "Total Transaksi",
    "Last 30 days": "30 Hari Terakhir",
    "Last 1 month": "1 Bulan Terakhir",
    "Last 1 year": "Terakhir 1 tahun",
    "Custom Date": "Tanggal Kustom",
    "Payment Methods": "Metode Pembayaran",
    "Payment Types": "Jenis Pembayaran",
    "Requested Amount": "Jumlah Diminta",
    "Quoted Amount": "Jumlah Ditawarkan",
    "About Me": "Tentang Saya",
    "Followers": "Pengikut",
    "InstaPay ID": "ID InstaPay",
    "Download QR Code": "Unduh Kode QR",
    "Initiate A Payment": "Mulai Pembayaran",
    "Report this person": "Laporkan orang ini",
    "Add now": "Tambahkan sekarang",
    "Recent Reviews": "Ulasan Terbaru",
    "Reviews As Seller": "Ulasan Sebagai Penjual",
    "Reviews As Buyer": "Ulasan Sebagai Pembeli",
    "Select Language": "Pilih Bahasa",
    "Profile Completion": "Pengisian Profil",
    "Profile Completed": "Profil Terisi",
    "Basic Info": "Info Dasar",
    "Username": "Nama Pengguna",
    "Gender": "Jenis Kelamin",
    "Male": "Laki-laki",
    "Female": "Perempuan",
    "-Select-Gender-": "-Pilih Jenis Kelamin-",
    "Date Of Birth": "Tanggal Lahir",
    "Address": "Alamat",
    "Edit Profile": "Edit Profil",
    "Update Profile": "Perbarui Profil",
    "Updating Profile": "Memperbarui Profil",
    "Complete Now": "Selesaikan Sekarang",
    "Password": "Kata Sandi",
    "Change Password": "Ganti Kata Sandi",
    "New Password": "Kata Sandi Baru",
    "Re-enter New Password": "Masukkan Ulang Kata Sandi Baru",
    "Please follow this guide for a strong password": "Silakan ikuti panduan ini untuk kata sandi yang kuat",
    "Include at least one special character.": "Sertakan setidaknya satu karakter khusus.",
    "Minimum of 8 characters.": "Minimal 8 karakter.",
    "Include at least one number": "Sertakan setidaknya satu angka.",
    "Change it often for enhanced security.": "Ganti secara teratur untuk keamanan yang ditingkatkan.",
    "Security Questions": "Pertanyaan Keamanan",
    "Security Question": "Pertanyaan Keamanan",
    "Select your question": "Pilih pertanyaan Anda",
    "Answer To The Question": "Jawaban untuk Pertanyaan",
    "Nominee": "Nominasi",
    "Relationship with Nominee": "Hubungan dengan Nominasi",
    "Nominee Contact Number": "Nomor Kontak Nominasi",
    "Nominee Address": "Alamat Nominasi",
    "Delete Nominee": "Hapus Nominasi",
    "Update Nominee": "Perbarui Nominasi",
    "Details Updated Successfully!": "Detail Diperbarui dengan Sukses!",
    "Success": "Sukses",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "Anda dapat memilih Nominasi pilihan Anda, asalkan mereka bukan anak di bawah umur, melalui platform InstaPay itu sendiri. Anda mengakui bahwa tidak diperlukan tanda tangan \nbasah untuk menunjuk Nominasi, dan pendaftaran online akan dianggap final dan mengikat. Dalam kejadian kematian pemegang Dompet InstaPay, saldo dalam Dompet akan \nditransfer kepada Nominasi yang terdaftar. KEMIT KINGDOM akan dibebaskan dari semua kewajibannya setelah penyerahan jumlah kepada Nominasi. Nominasi akan menyediakan \ndokumen yang diperlukan oleh InstaPay, termasuk dokumen identifikasi dan bukti kematian.\"",
    "Add Nominee": "Tambahkan Nominasi",
    "Two Factor Authentication": "Autentikasi Dua Faktor",
    "SMS Number": "Nomor SMS",
    "Updated Successfully!": "Diperbarui dengan Sukses!",
    "Social Network Accounts": "Akun Jejaring Sosial",
    "Here, you can set up and manage your integration settings.": "Di sini, Anda dapat mengatur dan mengelola pengaturan integrasi Anda.",
    "Social Network Account": "Akun Jejaring Sosial",
    "Activate Account": "Aktifkan Akun",
    "Enter the code below in the InstaPay Chatbot.": "Masukkan kode di bawah ini ke InstaPay Chatbot.",
    "The code will expire in ": "Kode akan kedaluwarsa dalam",
    "Notifications": "Notifikasi",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Sesuaikan bagaimana Anda menerima notifikasi. Pengaturan ini berlaku untuk aktivitas yang Anda pantau.",
    "Activity": "Aktivitas",
    "Email": "Email",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Sumber",
    "Payment Requests": "Permintaan Pembayaran",
    "Bulk Payments": "Pembayaran Massal",
    "Identity Verification": "Verifikasi Identitas",
    "Verify Your Identity": "Verifikasi Identitas Anda",
    "Identity Verification Status:": "Status Verifikasi Identitas:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Jelaskan secara singkat tentang diri Anda dan jelaskan alasan Anda menggunakan InstaPay.",
    "Enter Your Message": "Masukkan Pesan Anda",
    "Select Occupation": "Pilih Pekerjaan",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Itu harus menjadi dokumen identifikasi yang dikeluarkan pemerintah dengan foto. Semua dokumen identifikasi harus ditulis dalam alfabet Latin. Jika tidak, diperlukan terjemahan bersertifikat.",
    "Accepted Documents": "Dokumen yang Diterima",
    "A national identity card": "Kartu identitas nasional",
    "A valid passport": "Paspor yang sah",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Izin tinggal yang sah (hanya diterima jika dikeluarkan oleh negara Eropa atau di Swiss)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Pengenalan Wajah Biometrik: Membandingkan video dengan foto paspor",
    "Address Verification:": "Verifikasi Alamat:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Sediakan rekening bank atau tagihan utilitas (kurang dari 3 bulan) sebagai bukti alamat",
    "Additional Documents:": "Dokumen Tambahan:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom berhak untuk meminta dokumen tambahan (Enhanced Due Diligence - EDD) atau informasi lebih lanjut yang diperlukan untuk melakukan verifikasi yang cermat, \nsesuai dengan kewajiban hukumnya.",
    "Start Verification": "Mulai Verifikasi",
    "Sessions": "Sesi-sesi",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Ini adalah daftar perangkat yang telah masuk ke akun Anda. Hapus yang tidak Anda kenali.",
    "Your Withdrawal Options": "Pilihan Penarikan Anda",
    "Choose your preferred method for withdrawing payments.": "Pilih metode yang Anda sukai untuk menarik pembayaran.",
    "No withdrawal options currently available in your country.": "Saat ini tidak ada pilihan penarikan yang tersedia di negara Anda.",
    "Download Your Data": "Unduh Data Anda",
    "Request a Copy of Your Data": "Minta Salinan Data Anda",
    "Submit a request to download a copy of your data": "Ajukan permintaan untuk mengunduh salinan data Anda",
    "Which Data Should Be Included in the Download?": "Data Mana yang Harus Dimasukkan dalam Unduhan?",
    "Select all applicable options.": "Pilih semua opsi yang berlaku.",
    "Personal Information": "Informasi Pribadi",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Informasi ini digunakan untuk mengamankan akun Anda, menyesuaikan pengalaman Anda, dan menghubungi Anda jika diperlukan.",
    "Name and date of birth": "Nama dan tanggal lahir",
    "Phone number": "Nomor telepon",
    "Financial Information": "Informasi Keuangan",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Kami menggunakan informasi ini untuk memungkinkan proses checkout yang lebih cepat dan untuk mengirim atau menerima uang dalam beberapa klik saja.",
    "Bank accounts": "Rekening bank",
    "Debit or credit cards": "Kartu debit atau kredit",
    "Credit Information": "nformasi Kredit",
    "Includes your credit application information": "Termasuk informasi aplikasi kredit Anda",
    "Other Information (not included in file)": "Informasi Lainnya (tidak termasuk dalam file)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Termasuk info perangkat, data penggunaan teknis, informasi geolokasi, preferensi pemasaran, riwayat persetujuan, dan data yang digunakan untuk layanan lain seperti kredit, verifikasi identitas, \nkomunikasi dengan PayPal, dan prosesor pihak ketiga.",
    "Choose file type:": "Pilih jenis file:",
    "Select file type": "Pilih jenis file",
    "PDF file can be easily opened on any computer": "File PDF dapat dibuka dengan mudah di komputer apa pun",
    "Submit Request": "Kirim Permintaan",
    "Delete Your Account": "Hapus Akun Anda",
    "Ask us to delete your data and close this account": "Minta kami menghapus data Anda dan menutup akun ini",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Kami menggunakan data Anda untuk memberikan layanan, jadi jika kami menghapus data Anda, kami juga perlu menutup akun Anda.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Jika akun Anda terbatas, pergi ke Pusat Resolusi, pilih 'Pergi ke Pembatasan Akun', lalu klik 'Selesaikan'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Pastikan semua pembayaran tertunda sudah diselesaikan. Kami tidak akan dapat menutup akun Anda sampai itu terjadi.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Jika Anda memiliki saldo negatif, klik tautan Selesaikan di sebelah saldo Anda.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Saat akun Anda jelas, dan jika Anda memenuhi syarat, kami akan menutup akun Anda dan menghapus data Anda. Kami perlu menyimpan beberapa data Anda untuk alasan hukum dan regulasi. \nUntuk informasi lebih lanjut, lihat ",
    "privacy statement": "pernyataan privasi",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Kami akan mengirim email kepada Anda untuk memberi tahu statusnya, dan jika ada langkah lain yang perlu Anda ambil.",
    "Have more questions about data deletion?": "Punya pertanyaan lebih lanjut tentang penghapusan data?",
    "Contact us": "Hubungi kami",
    "I understand that account deletion is permanent and can't be reversed.": "Saya mengerti bahwa penghapusan akun bersifat permanen dan tidak dapat dibalikkan.",
    "Are you sure you want to close your account?": "Apakah Anda yakin ingin menutup akun Anda?",
    "Yes, Continue": "Ya, Lanjutkan",
    "Logging In": "Masuk",
    "Complete your profile to streamline your transaction process.": "Lengkapi profil Anda untuk menyederhanakan proses transaksi Anda.",
    "Skip": "Lewati",
    "Complete Your Profile": "Lengkapi Profil Anda",
    "API Key": "Kunci API",
    "Get API Key": "Dapatkan Kunci API",
    "Generating New API Key": "Menghasilkan Kunci API Baru",
    "Generate API Key": "Hasilkan Kunci API",
    "Regenerate API Key": "Hasilkan Ulang Kunci API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Untuk memanfaatkan potensi penuh transaksi tanpa batas InstaPay, ikuti langkah-langkah cepat ini untuk menghasilkan kunci API Anda. Pertama, daftar akun di [URL pendaftaran Anda] \ndan akses Dasbor Pengembang. Beralih ke bagian 'Kunci API', hasilkan kunci baru, dan pastikan penyimpanannya aman.",
    "Delete API Key": "Hapus Kunci API",
    "Webhook URL": "URL Webhook",
    "KYB Verification": "Verifikasi KYB",
    "Additional Files": "File Tambahan",
    "Business Verification": "Verifikasi Bisnis",
    "--Select-Your-Company-Type--": "--Pilih-Jenis-Perusahaan-Anda--",
    "Documents Required:": "Dokumen yang Diperlukan:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Ekstrak Resmi Pendaftaran Perusahaan (Dokumen Pendaftaran/Inkorporasi Entitas)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Dokumen ini berfungsi sebagai 'kartu identitas' resmi perusahaan dan harus diperoleh dari registrasi bisnis lokal. Ini mencerminkan status saat ini bisnis tersebut (aktif atau ditutup).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Dokumen tersebut harus berlaku, dengan tanggal penerbitan tidak lebih dari 3 bulan.",
    "The document must include the following details:": "Dokumen harus mencakup detail berikut:",
    "Legal name of the company": "Nama resmi perusahaan",
    "Full registered address": "Alamat terdaftar lengkap",
    "Company registration number": "Nomor registrasi perusahaan",
    "Date of incorporation": "Tanggal inkorporasi",
    "Description of the company's activities/purpose": "Deskripsi aktivitas/tujuan perusahaan",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Informasi perwakilan hukum: nama lengkap, tanggal dan tempat lahir, alamat tempat tinggal (persyaratan minimum: negara tempat tinggal), dan kewarganegaraan",
    "If applicable, the document should also include:": "Jika berlaku, dokumen harus juga mencakup:",
    "Brand or trading names": "Merek atau nama perdagangan",
    "Value Added Tax (VAT) number": "Nomor Pajak Pertambahan Nilai (PPN)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Daftar Pemilik Manfaat Utama (UBO) - ini wajib di beberapa negara, jika tidak, dapat diberikan sebagai dokumen terpisah.",
    "Proof of Legal Representative's Authority": "Bukti Kewenangan Perwakilan Hukum",
    "This document validates an individual's authority to represent the company": "Dokumen ini memvalidasi otoritas seseorang untuk mewakili perusahaan",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Dalam umumnya, perwakilan hukum dinamai dalam ekstrak registrasi perusahaan. Namun, jika hal ini tidak terjadi, dokumen seperti Surat Kuasa atau Delegasi Wewenang harus diperoleh.",
    "Power of Attorney/Delegation of Powers:": "Surat Kuasa/Delegasi Wewenang:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Individu yang memberikan wewenang harus memiliki hak yang sah untuk melakukannya. Individu ini harus terdaftar sebagai perwakilan hukum dalam ekstrak registrasi perusahaan.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Struktur Kepemilikan dan Dokumentasi Pemilik Manfaat Utama (UBO)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "ukti perusahaan terdaftar di bursa saham yang relevan harus disediakan. Ini bisa berupa halaman dari situs web bursa saham yang menampilkan detail perusahaan.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "ika kurang dari 75% saham perusahaan dimiliki oleh publik, semua pemegang saham yang memiliki atau mengendalikan lebih dari 25% saham akan tunduk pada persyaratan verifikasi Pemilik \nManfaat Utama (UBO).",
    "Collection of Identification Documents": "Pengumpulan Dokumen Identifikasi",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Dokumen identifikasi harus dikeluarkan oleh pemerintah, memuat foto individu. Semua dokumen identifikasi harus ditulis dalam alfabet Latin. Jika tidak, diperlukan terjemahan bersertifikat.",
    "Accepted documents:": "Dokumen yang diterima:",
    "Valid National Identity Card": "Kartu Identitas Nasional yang Valid",
    "Valid Passport": "Paspor yang Valid",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Izin Tinggal yang Valid - Ini hanya akan diterima jika diterbitkan oleh negara Eropa.",
    "Only legal representatives are subject to the identification document collection process.": "Hanya perwakilan hukum yang terlibat dalam proses pengumpulan dokumen identifikasi.",
    "Additional Requirement:": "Persyaratan Tambahan:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "Dalam kasus Surat Kuasa atau Delegasi Wewenang, kedua pihak (individu yang memberikan wewenang dan yang menerimanya) harus menyediakan salinan KTP mereka.",
    "Additional Documents": "Dokumen Tambahan",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Tagihan Utilitas (seperti tagihan gas, listrik, telepon, atau ponsel); atau dokumen yang dikeluarkan oleh departemen pemerintah yang menunjukkan alamat dan nama Pengguna Akhir \n(mis., Sertifikat Tempat Tinggal); atau",
    "Bank Statement; or": "Rekening Bank; atau\n",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "dokumen yang dikeluarkan oleh departemen pemerintah yang menunjukkan alamat dan nama Pengguna Akhir (mis., Sertifikat Tempat Tinggal).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom berhak untuk meminta dokumen tambahan atau informasi lebih lanjut yang diperlukan untuk melakukan verifikasi yang teliti, sesuai dengan kewajiban hukumnya.",
    "Note:": "Catatan:",
    "Partners": "Mitra",
    "Add New Partner": "Tambahkan Mitra Baru",
    "Designation": "Penunjukan",
    "Partner Name": "Nama Mitra",
    "Partner Email": "Email Mitra",
    "Partner Designation": "Penunjukan Mitra",
    "Add Now": "Tambahkan Sekarang",
    "Adding": "Menambahkan",
    "Delete Partner": "Hapus Mitra",
    "Delete": "Hapus",
    "Deleting": "Menghapus",
    "Are you sure, you want to remove sxz as partner?": "Apakah Anda yakin ingin menghapus sxz sebagai mitra?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Ekstrak Registrasi Entitas (Dokumen Pembuatan Entitas)",
    "This document should be either:": "Dokumen ini harus berupa salah satu dari berikut:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Bukti pembuatan entitas, menyediakan detail entitas, tanggal pembuatan, dan tujuan entitas tersebut.",
    "A local registry extract:": "Ekstrak registrasi lokal:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Diperlukan sesuai dengan regulasi negara, berfungsi sebagai 'kartu identitas' saat ini untuk entitas, menunjukkan statusnya (aktif atau ditutup).",
    "If a registry extract exists, it must not be older than 3 months.": "Jika ekstrak registrasi sudah ada, dokumen tersebut tidak boleh lebih lama dari 3 bulan.",
    "Entity name": "Nama Entitas",
    "Registration number (if applicable, based on the country's requirements)": "Nomor registrasi (jika berlaku, berdasarkan persyaratan negara)",
    "Date of creation": "Tanggal pembuatan",
    "Description of the entity's activities or purpose": "Deskripsi kegiatan atau tujuan entitas",
    "Trading names": "Nama perdagangan",
    "Articles of Association": "Anggaran Dasar",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Anggaran Dasar berfungsi sebagai dokumen konstitusi entitas, menguraikan aturan operasionalnya.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Tidak ada batasan waktu pada dokumen ini, yang berarti versi terbaru yang diminta. Biasanya, jenis entitas ini memerlukan setidaknya satu pertemuan tahunan yang dapat menghasilkan \nversi terbaru dari Anggaran Dasar.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Contoh perubahan yang dapat terjadi termasuk pembaruan nama entitas, alamat, tujuan, dewan manajemen, peran, tanggung jawab, dll.",
    "The document should contain information about:": "Dokumen harus berisi informasi tentang:",
    "The entity itself, including its legal name, registered address, and purpose": "Entitas itu sendiri, termasuk nama hukum, alamat terdaftar, dan tujuannya",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Aturan operasional internalnya, seperti proses pengangkatan anggota dewan manajemen, peran dan kekuasaan mereka, di antara detail lainnya. Daftar ini tidak lengkap.",
    "How the entity is funded": "Bagaimana entitas didanai",
    "Appointment/Removal/Changes on Board of Management Members:": "Pengangkatan/Penghapusan/Perubahan pada Anggota Dewan Manajemen:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Pembaruan ini biasanya terjadi berdasarkan kebutuhan entitas atau seperti yang ditetapkan dalam Anggaran Dasar. Perubahan didokumentasikan melalui notulen rapat dewan manajemen, \nyang didaftarkan dan disertifikasi untuk mencerminkan daftar anggota yang diperbarui, seperti presiden, wakil presiden, bendahara, dan sekretaris jenderal.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "Notulen rapat dewan manajemen terbaru, termasuk daftar anggota saat ini, akan diperlukan sebagai tambahan dari Anggaran Dasar, jika tersedia.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Catatan: Jika daftar terbaru anggota dewan manajemen tidak termasuk dalam Anggaran Dasar terbaru dan tidak ada notulen rapat dewan yang tersedia, daftar ini harus disediakan. \nDaftar tersebut harus bertanggal dan ditandatangani oleh perwakilan hukum yang paling baru diangkat, yang pengangkatannya dapat diverifikasi melalui dokumentasi terkait.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Dokumen ini menunjukkan kewenangan seseorang untuk mewakili entitas. Dokumen ini bisa berupa:",
    "The latest Articles of Association": "Anggaran Dasar terbaru",
    "The current list of board management members (which may be included in the board management meeting minutes)": "Daftar terbaru anggota dewan manajemen (yang mungkin termasuk dalam notulen rapat dewan manajemen)",
    "The document should specify:": "Dokumen harus menentukan:",
    "Full name of the individual(s)": "Nama lengkap individu",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Fungsi individu. Informasi ini akan diverifikasi silang dengan Anggaran Dasar untuk memastikan bahwa fungsi individu memungkinkan mereka mengikat entitas. Ini sangat penting ketika \npemohon bukan presiden, dan fungsi lain memiliki kewenangan perwakilan terbatas seperti yang dinyatakan dalam Anggaran Dasar.",
    "Additional information about the legal representative that will be collected includes:": "Informasi tambahan tentang perwakilan hukum yang akan dikumpulkan meliputi:",
    "Date and place of birth": "Tanggal dan tempat lahir",
    "Residential address (at least the country of residence should be stated)": "Alamat tempat tinggal (setidaknya negara tempat tinggal harus dinyatakan)",
    "Nationality": "Kewarganegaraan",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Struktur Kepemilikan & Pemilik Manfaat Akhir (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Umumnya, entitas semacam ini, mengingat struktur dan tujuannya, tidak memiliki pemilik manfaat. Namun, anggota dewan akan diidentifikasi dan diperlakukan sebagai pemilik manfaat untuk\ntujuan pemeriksaan, pengumpulan dan verifikasi ID, dll.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Sejumlah kecil asosiasi mungkin memiliki pemilik manfaat, terutama yang menghasilkan keuntungan. Dalam kasus seperti ini, detail ini akan disebutkan dalam Anggaran Dasar. Individu-individu \nini akan mudah diidentifikasi dan diperlakukan sebagai pemilik manfaat, bersama dengan anggota dewan manajemen.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Persyaratan Tambahan: Dalam kasus yang melibatkan Surat Kuasa atau Delegasi Wewenang, kedua belah pihak (yang memberikan wewenang dan yang menerimanya) harus menyediakan \nsalinan dokumen identifikasi mereka.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Ekstrak Registrasi Pedagang Tunggal (Dokumen Registrasi/Pembuatan Entitas)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Dokumen ini berfungsi sebagai registri lokal untuk pedagang tunggal, yang diperlukan di negara-negara di mana pendaftaran semacam ini adalah wajib. Dokumen ini memverifikasi bahwa \nindividu tersebut terlibat dalam aktivitas profesional sebagai pedagang tunggal. Nama dokumen ini berbeda-beda dari negara ke negara.",
    "Where applicable, the document should not be older than 3 months.": "Dimana berlaku, dokumen tidak boleh lebih dari 3 bulan.",
    "The document must contain:": "Dokumen harus berisi:",
    "Individual's name": "Nama individu",
    "Residential address": "Alamat tempat tinggal",
    "Registration number": "Nomor registrasi",
    "The sole trader is the only individual subject to the identification document collection process.": "Pemilik tunggal adalah satu-satunya individu yang terlibat dalam proses pengumpulan dokumen identifikasi.",
    "No Transactions": "Tidak Ada Transaksi",
    "No Pending Items": "Tidak Ada Item Tertunda",
    "No Beneficiaries": "Tidak Ada Penerima Manfaat",
    "Send Money To": "Kirim Uang Ke",
    "Confirmation": "Konfirmasi",
    "You are setting": "Anda sedang mengatur",
    "as default.": "sebagai bawaan.",
    "Previously": "Sebelumnya",
    "was default QR.": "adalah QR bawaan.",
    "Category": "Kategori",
    "-Select-From-The-List-": "-Pilih-Dari-Daftar-",
    "Add a note": "Tambahkan catatan",
    "Categories Added Successfully!": "Kategori Berhasil Ditambahkan!",
    "Subscription starting date": "Tanggal mulai berlangganan",
    "Until": "Hingga",
    "Next Transaction": "Transaksi Berikutnya",
    "Transaction Method": "Metode Transaksi",
    "Accept the quotation": "Terima kutipan",
    "Choose Currency": "Pilih Mata Uang",
    "Accept": "Terima",
    "Bargain the quotation": "Tawar kutipan",
    "Enter Counter Amount:": "Masukkan Jumlah Tawaran:",
    "Bargain": "Tawar",
    "Decline the quotation": "Batalkan kutipan",
    "Are you sure, you want to decline this quotation?": "Anda yakin ingin menolak kutipan ini?",
    "Decline": "Tolak",
    "Personal Account": "Akun Pribadi",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Apr",
    "May": "Mei",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Agu",
    "Sep": "Sep",
    "Oct": "Okt",
    "Nov": "Nov",
    "Dec": "Des",
    "Today": "Hari ini",
    "Spent In": "Dihabiskan di",
    "Revised Amount": "Jumlah yang Direvisi",
    "You spent more comparing to last month": "Anda menghabiskan lebih banyak dibanding bulan lalu",
    "Last": "Terakhir",
    "days": "Hari",
    "Year": "Tahun",
    "Instant Mobile Top-up Across 150+ Countries": "Isi Ulang Seluler Instan di Lebih dari 150 Negara",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Manjakan orang terkasih atau isi ulang ponsel Anda sendiri, di mana pun Anda berada! Layanan Isi Ulang Seluler InstaPay memungkinkan Anda mengisi ulang ponsel secara instan di lebih dari 150 negara. Sempurna untuk hadiah atau memastikan Anda selalu terhubung, layanan kami dirancang untuk kemudahan, kecepatan, dan keamanan.",
    "How It Works - Step-by-Step Guide:": "Cara Kerja - Panduan Langkah demi Langkah:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Pilih 'Isi Ulang Seluler': Akses fitur ini langsung dalam aplikasi InstaPay di platform seperti WhatsApp, Twitter, Telegram, atau Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Pilih Mata Uang dan Jumlah: Pilih dari beberapa mata uang (USD, GBP, EUR, dll.) dan tentukan berapa banyak yang ingin Anda isi ulang.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Masukkan Nomor Ponsel: Ketik nomor yang ingin Anda isi ulang—milik Anda atau orang terkasih. Sistem kami memastikan proses yang aman dan cepat.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Konfirmasi dan Bayar: Periksa kembali detail isi ulang, konfirmasi, dan lakukan pembayaran dengan metode pilihan Anda. Transaksi diproses secara instan dan aman.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Terima Konfirmasi Instan: Anda dan penerima (jika berlaku) akan menerima notifikasi konfirmasi setelah isi ulang berhasil.",
    "Benefits:": "Keuntungan:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Jangkauan Global: Kirim pulsa dengan mudah ke lebih dari 150 negara.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Dampak Instan: Lihat manfaatnya segera—sangat cocok untuk hadiah mendadak atau komunikasi mendesak.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Keamanan Lengkap: Nikmati ketenangan pikiran dengan sistem pembayaran kami yang aman.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Opsi Pembayaran Serbaguna: Pilih dari kartu kredit, PayPal, atau dompet InstaPay untuk pembayaran.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Siap menyebarkan kegembiraan atau tetap terhubung? Isi ulang sekarang dan buat hari seseorang—atau hari Anda sendiri!",
    "Pick a language": "Pilih bahasa",
    "French": "Prancis",
    "Spanish": "Spanyol",
    "Portuguese": "Portugis",
    "German": "Jerman",
    "Ukrainian": "Ukraina",
    "Italian": "Italia",
    "Russian": "Rusia",
    "Arabic": "Arab",
    "Polish": "Polandia",
    "Dutch (Nederlands)": "Belanda",
    "Yoruba": "Yoruba",
    "Indonesian": "Indonesia",
    "Turkish": "Turki",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Cina",
    "Select Your Preferred Language": "Pilih Bahasa yang Anda Pilih",
    "Please select the language you would \nlike to use for the eKYC process.": "Silakan pilih bahasa yang ingin Anda gunakan untuk proses eKYC.",
    "Select Your Nationality": "Pilih Kewarganegaraan Anda",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Pilih kewarganegaraan Anda untuk melihat dokumen ID yang didukung untuk negara Anda.",
    "Grant permission to use the camera.": "Izinkan penggunaan kamera.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Ketuk atau klik \"Izinkan\" saat diminta untuk memberikan izin akses kamera untuk menangkap dokumen.",
    "Upload Your Documents from \nGallery/Photos": "Unggah Dokumen Anda dari Galeri/Foto",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Jika Anda mengalami masalah dengan kamera atau jika sistem Anda tidak memiliki satu, Anda dapat mengunggah foto dokumen secara manual dari penyimpanan lokal Anda.",
    "Upload Your ID.": "Unggah ID Anda.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Pastikan ID Anda jelas dan tidak buram untuk memastikan verifikasi cepat.",
    "Valid Proof of Address": "Bukti Alamat yang Sah",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Pastikan bahwa bukti alamat Anda terkini dan tanggal pernyataan adalah dalam tiga bulan terakhir dari tanggal mulai verifikasi eKYC Anda. \n\n2. Pastikan bukti alamat Anda jelas dan tidak buram untuk memastikan verifikasi cepat.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Pemberitahuan Penting\n\nHarap baca dengan cermat semua langkah dalam panduan ini sebelum melanjutkan dengan verifikasi eKYC Anda.\n\nHindari kesalahan umum:\n\n* Pastikan semua dokumen Anda jelas, terbaca, dan terbaru.\n* Periksa apakah informasi yang Anda berikan sesuai dengan data pada dokumen resmi Anda.\n* Ikuti petunjuk dengan seksama untuk menghindari keterlambatan atau penolakan.\n\nCatatan: \nJika verifikasi eKYC Anda gagal dan Anda perlu mengirimkannya kembali, biaya tambahan akan dikenakan untuk proses pengiriman ulang.\n\nDengan mengikuti pedoman ini, Anda dapat memastikan verifikasi eKYC yang lancar dan sukses.\n\nUntuk pertanyaan atau bantuan, silakan hubungi tim dukungan kami di support@insta-pay.ch",
    "Personal Transactions": "Transaksi Pribadi",
    "Business Transactions": "Transaksi Bisnis",
    "Shopping & Purchases": "Belanja & Pembelian",
    "Bills & Utilities": "Tagihan & Utilitas",
    "Entertainment & Leisure": "Hiburan & Rekreasi",
    "Investments & Savings": "Investasi & Tabungan",
    "Health & Wellness": "Kesehatan & Kesejahteraan",
    "Transportation": "Transportasi",
    "Education": "Pendidikan",
    "Miscellaneous": "Lain-lain",
    "Family Support": "Dukungan keluarga",
    "WALLET": "WALLET",
    "Add a currency": "Tambah mata uang",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Saat Anda menambahkan mata uang, pembayaran apa pun yang Anda terima dalam mata uang tersebut akan dikreditkan ke saldo InstaPay Anda. Mata uang utama Anda akan digunakan untuk mengirim atau meminta pembayaran kecuali ditentukan lain.",

    "balance amount ": "jumlah saldo",
    "referral ": "referensi",
    "crypto wallet balance": "saldo dompet crypto",
    "money in": "uang masuk",
    "money out": "uang keluar",
    "analytics": "analitik",
    "see more": "lihat lebih banyak",
    "manage my Wallets and see the transaction details": "kelola Wallets saya dan lihat rincian transaksi saya",
    "set as default Wallet": "tetapkan sebagai Wallet default",
    "add currency": "tambahkan mata uang",
    "convert funds ": "konversi dana",
    "withdraw balance": "tarik saldo",
    "chart names": "nama grafik",
    "withdraw balance from PKR wallet": "tarik saldo dari dompet PKR",
    "select a payout channel": "pilih saluran pembayaran",
    "you have entered an amount below the minimum payment range": "Anda telah memasukkan jumlah di bawah kisaran pembayaran minimum",
    "change": "ganti",
    "edit": "sunting",
    "moving to next step in ...": "melanjutkan ke langkah berikutnya dalam ...",
    "you are withdrawing ...": "Anda menarik ...",
    "where is my payment": "di mana pembayaran saya",
    "download": "unduh",
    "IBAN or swift/BIC code": "IBAN atau kode SWIFT/BIC",
    "swift code": "kode SWIFT",
    "account number": "nomor rekening",
    "branch street": "jalan cabang",
    "city": "kota",
    "province": "provinsi",
    "postal code": "kode pos",
    "all payout channels": "semua saluran pembayaran",
    "bussiness beneficiary": "penerima bisnis",
    "no quotation": "tidak ada kutipan",
    "no pending items": "tidak ada item yang tertunda",
    "QR status": "Status QR",
    "Portfolio": "Portofolio",
    "edit whole portfolio page": "sunting seluruh halaman portofolio",
    "personal account": "akun pribadi",
    "Chart names": "Nama grafik",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "lainnya",
    "not connected": "tidak terhubung",
    "code": "kode",
    "ratings": "penilaian",
    "current password": "kata sandi saat ini",
    "include at least one uppercase and lowercase": "termasuk setidaknya satu huruf besar dan kecil",
    "my social network accounts": "akun jejaring sosial saya",
    "SMS ": "SMS",
    "view identity verfication guide": "lihat panduan verifikasi identitas",
    "boimetric face recognition": "pengenalan wajah biometrik",
    "please select a payer (withdrawl options)": "silakan pilih pembayar (opsi penarikan)",
    "IBAN number ": "nomor IBAN",
    "account holder name": "nama pemegang akun",
    "download data": "unduh data",
    "bank transfer": "transfer bank",
    "mobile wallet": "dompet seluler",
    "all bank names": "semua nama bank",
    "pay via card": "bayar dengan kartu",
    "you have entered an amount that is below the payer range": "Anda telah memasukkan jumlah yang berada di bawah kisaran pembayar",
    "trouble sending money": "masalah mengirim uang",
    "please follow these example": "silakan ikuti contoh ini",
    "wallet ID ": "ID Wallet",
    "username": "nama pengguna",
    "email": "email",
    "phone number": "nomor telepon",
    "no user found": "tidak ditemukan pengguna",
    "search": "pencarian",
    "scan/upload QR code of the recipient": "pindai/unggah kode QR penerima",
    "invite them": "undang mereka",
    "trouble quoting money": "masalah dalam mengutip uang",
    "select a country": "pilih negara",
    "airtime details": "detail pulsa",
    "enter the phone number": "masukkan nomor telepon",
    "service type": "jenis layanan",
    "select a service": "pilih layanan",
    "service list": "daftar layanan",
    "amount entered is less than the ....": "jumlah yang dimasukkan kurang dari ....",
    "total": "total",
    "total credit the receiver gets": "total kredit yang diterima penerima",
    "take ": "ambil",
    "OTP has been verified , You'll be redirected......": "OTP telah diverifikasi, Anda akan diarahkan......",
    "Link Your Instagram Account to InstaPay": "Hubungkan akun Instagram Anda ke InstaPay",
    "Step 01 – From Instagram App": "Langkah 01 – Dari aplikasi Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Buka aplikasi Instagram, cari halaman profil \"InstaPay\", pilih \"Pesan\" untuk membuka kotak obrolan, ketik/ucapkan \"Hai\" dan klik kirim untuk memulai percakapan dengan chatbot InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Langkah 02 – Dari halaman profil Instagram InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Chatbot InstaPay akan menampilkan dua tombol tindakan: \"Daftar\" dan \"Hubungkan\". Pilih \"Hubungkan\", dan chatbot akan meminta Anda memasukkan nama pengguna InstaPay Anda.",
    "Step 03 – From InstaPay Instagram Profile Page": "Langkah 03 – Dari halaman profil Instagram InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Chatbot InstaPay kemudian akan meminta kode verifikasi InstaPay. Masukkan kode yang Anda peroleh dari pengaturan InstaPay di bawah \"Akun Jejaring Sosial Saya\" dan klik kirim.",
    "Step 04 – From InstaPay Instagram Profile Page": "Langkah 04 – Dari halaman profil Instagram InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Anda akan menerima pesan ucapan selamat yang mengonfirmasi bahwa akun Instagram Anda sekarang disinkronkan dengan InstaPay. Klik \"Menu Utama\" untuk mulai menggunakan layanan InstaPay.",
    "Additional Tips": "Tips tambahan",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Amankan akun Anda: Pastikan akun Instagram Anda aman dengan mengaktifkan otentikasi dua faktor (2FA) untuk melindungi layanan InstaPay Anda yang tertaut.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Jelajahi lebih lanjut: Setelah ditautkan, jelajahi berbagai layanan yang ditawarkan InstaPay, mulai dari transaksi yang mulus hingga penarikan instan.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Tetap diperbarui: Pantau pembaruan kami, karena kami akan segera mendukung penautan akun jejaring sosial lainnya untuk membuat pengalaman Anda lebih terintegrasi.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Bantuan & Dukungan: Jika Anda mengalami masalah selama proses penautan, hubungi tim dukungan kami untuk mendapatkan bantuan.",
    "Future Enhancements": "Peningkatan masa depan",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Dalam waktu dekat, InstaPay akan memungkinkan Anda untuk menautkan lebih banyak akun jejaring sosial, memberi Anda lebih banyak fleksibilitas dan kenyamanan. Nantikan pembaruan menarik!",
    "Personal Transactions": "Transaksi Pribadi",
    "Business Transactions": "Transaksi Bisnis",
    "Shopping & Purchases": "Belanja & Pembelian",
    "Bills & Utilities": "Tagihan & Utilitas",
    "Entertainment & Leisure": "Hiburan & Rekreasi",
    "Investments & Savings": "Investasi & Tabungan",
    "Health & Wellness": "Kesehatan & Kesejahteraan",
    "Transportation": "Transportasi",
    "Education": "Pendidikan",
    "Miscellaneous": "Lain-lain",
    "Family Support": "Dukungan keluarga",
    "Web - login with phone number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Mulailah Perjalanan InstaPay Anda Hari Ini!",
    "Enter your phone number": "Masukkan nomor telepon Anda",
    "Login with email": "Masuk dengan email",
    "Don't have an account?": "Tidak punya akun?",
    "Sign Up": "Daftar",
    "Continue": "Lanjutkan",
    "We recommend you download and install the Google Authenticator app": "Kami merekomendasikan Anda mengunduh dan menginstal aplikasi Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Otentikasi dua faktor (2FA) adalah cara terbaik untuk melindungi diri Anda secara online.",
    "Strong security with Google Authenticator": "Keamanan kuat dengan Google Authenticator",
    "Enter your password": "Masukkan kata sandi Anda",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Kami merekomendasikan Anda mengunduh dan menginstal aplikasi Google Authenticator untuk memastikan Anda menerima kode verifikasi Anda secara instan, menghindari keterlambatan atau masalah dengan SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Kode Otentikasi Dua Faktor",
    "Problem with the token?": "Masalah dengan token?",
    "Verify using SMS": "Gunakan SMS untuk memverifikasi",
    "Verify": "Verifikasi",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Verifikasi SMS",
    "Enter the SMS code sent to +41********4053": "Masukkan kode SMS yang dikirim ke +41********4053",
    "Verify using Authenticator App": "Gunakan aplikasi Authenticator untuk memverifikasi",
    "Resend code in 120 seconds": "Kirim ulang kode dalam 120 detik",
    "Verify ": "Verifikasi",
    "SMS - Web Mobile": null,
    "My own account": "Akun saya sendiri",
    "Friends & Family": "Teman & Keluarga",
    "Aunt": "Bibi",
    "Brother-in-law": "Ipar laki-laki",
    "Cousin": "Sepupu",
    "Daughter": "Anak perempuan",
    "Father": "Ayah",
    "Father-in-law": "Ayah mertua",
    "Friend": "Teman",
    "Grandfather": "Kakek",
    "Grandmother": "Nenek",
    "Husband": "Suami",
    "Mother": "Ibu",
    "Mother-in-law": "Ibu mertua",
    "Nephew": "Keponakan laki-laki",
    "Niece": "Keponakan perempuan",
    "Self (i.e. the sender, himself)": "Diri sendiri (yaitu pengirim)",
    "Sister": "Saudara perempuan",
    "Sister-in-law": "Ipar perempuan",
    "Son": "Anak laki-laki",
    "Uncle": "Paman",
    "Wife": "Istri",
    "Others not listed": "Lainnya yang tidak tercantum",
    "Merchant": "Pedagang",
    "No relationship": "Tidak ada hubungan",
    "Add Funds": "Tambah Dana",
    "Enter the Amount": "Masukkan jumlah:",
    "Fees": "Biaya:",
    "You Will Get": "Anda akan menerima:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Nilai transaksi harus antara [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Jumlah Tidak Valid: Tingkat verifikasi identitas Anda saat ini membatasi jumlah maksimum yang dapat Anda tambahkan. Harap tingkatkan verifikasi identitas Anda untuk mendapatkan batas yang lebih tinggi.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Harga mata uang sering berubah berdasarkan kondisi pasar. Silakan merujuk ke harga pada halaman konfirmasi pesanan sebagai penawaran akhir Anda.",
    "Next": "Berikutnya",
    "Upgrade Identity Verification": "Tingkatkan Verifikasi Identitas",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Meminta uang dari siapa saja, di mana saja, termasuk melalui platform jejaring sosial. Dengan InstaPay, permintaan pembayaran Anda dapat diakses secara instan setelah diterima dan dana dikirim ke Akun Penerimaan Anda. Sederhanakan transaksi Anda dan kelola pembayaran dengan mudah dan aman.",
    "You’ve been logged out.": "Anda telah keluar.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Untuk keamanan Anda, Anda telah keluar setelah 15 menit tidak aktif. Silakan masuk lagi jika Anda ingin menggunakan InstaPay.",
    "LOGIN AGAIN": "MASUK LAGI",
    "Brother": "Saudara laki-laki",
    "Current Password": "Kata sandi saat ini",
   "The new password cannot be the same as the old one": "Kata sandi baru tidak boleh sama dengan yang lama.",
     "Minimum password length: [X] characters ": "Panjang kata sandi minimal: [X] karakter",
        "Include at least one uppercase and one lowercase letter.": "Sertakan setidaknya satu huruf kapital dan satu huruf kecil.",
        "Nominee Information": "Informasi Penerima",
        "Verification Code Preferences": "Preferensi Kode Verifikasi",
        "How Others Can Find You": "Bagaimana Orang Lain Dapat Menemukan Anda",
        "My Social Network Accounts": "Akun Jejaring Sosial Saya",
        "Login Activity": "Aktivitas Masuk",
        "Receiving Accounts": "Akun Penerimaan",
        "Change Your Password To Continue": "Ubah Kata Sandi Anda Untuk Melanjutkan",
        "Continue with": "Lanjutkan dengan",
        "Back to Login": "Kembali ke Login",
        "Send OTP": "Kirim OTP",
        "A Verification code has been sent to your email and number.": "Kode verifikasi telah dikirim ke email dan nomor Anda.",
        "Resend available in": "Kirim ulang tersedia dalam",
        "Create a strong Password": "Buat Kata Sandi yang Kuat",
        "Password must:": "Kata Sandi harus:",
        "Be between 9 and 64 characters": "Antara 9 dan 64 karakter",
        "Include at least two of the following:": "Sertakan setidaknya dua dari yang berikut:",
        "Lowercase Character": "Karakter Huruf Kecil",
        "Special Character": "Karakter Khusus",
        "Password Updated Successfully!": "Kata Sandi Berhasil Diperbarui!",
        "Click below to login": "Klik di bawah untuk login",
                                                                                        
    "Send Money": "Kirim Uang",
    "Enter the amount": null,
    "Recent Transfer": "Transfer Terbaru",
    "See all": "Lihat semua",
    "Send again": "Kirim lagi",
    "You Send": "Anda Kirim",
    "They Receive": "Mereka Terima",
    "Receive Method": "Metode Penerimaan",
    "Select method": "Pilih metode",
    "Next": "Berikutnya",
    "Trouble sending money?": "Masalah mengirim uang?",
    "Where do you want to send money ?": "Di mana Anda ingin mengirim uang?",
    "Search ": "Cari",
    "Confirm": "Konfirmasi",
    "Bank Deposit": "Setoran Bank",
    "Cash Pick-up": "Pengambilan Tunai",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Kartu Bank",
    "Crypto Wallet": "Wallet Kripto",
    "What is the receiver’s Mobile Wallet?": "Apa Wallet seluler penerima?",
    "Transfer fees": "Biaya transfer",
    "Total to pay": "Total yang harus dibayar",
    "Choose the beneficiary!": "Pilih penerima!",
    "Country": "Negara",
    "Receive method": "Metode penerimaan",
    "You send": "Anda kirim",
    "change": "edit",
    "edit": "tambahkan baru",
    "add new": "Penerima",
    "Beneficiaries": "Kelola Penerima",
    "Manage Beneficiaries": "Cari",
    "Search": "Berikutnya",
    "CASH PICK-UP": "PENGAMBILAN TUNAI",
    "Where will your receiver collect the money": "Di mana penerima Anda akan mengambil uangnya?",
    "Cash is available to collect within minutes from any branch of [name] ": "Uang tunai tersedia untuk diambil dalam beberapa menit dari cabang [nama] mana pun",
    "BANK ACCOUNT": "REKENING BANK",
    "Transfer to recipient’s bank account": "Transfer ke rekening bank penerima",
    "BANK CARD": "KARTU BANK",
    "Fast transfer directly to recipient’s bank card": "Transfer cepat langsung ke kartu bank penerima",
    "CRYPTO WALLET": "WALLET KRIPTO",
    "Fast transfer directly to a crypto wallet": "Transfer cepat langsung ke Wallet kripto",
    "Review transfer": "Tinjau transfer",
    "Receiver's Details": "Detail penerima",
    "Edit": "Edit",
    "Name": "Nama",
    "Reason for sending": "Alasan mengirim",
    "Sending": "Mengirim",
    "Receiver gets": "Penerima menerima",
    "Exchange rate": "Kurs",
    "Estimated time": "Perkiraan waktu",
    "Select Payment Type": "Pilih Jenis Pembayaran",
    "Credit cards": "Kartu kredit",
    "Domestic cards": "Kartu Domestik",
    "Mobile Wallets": "Wallet Seluler",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KARTU KREDIT",
    "Credit Card": "Kartu Kredit",
    "Select card": "Pilih kartu",
    "Card Number": "Nomor kartu",
    "Enter the 16-digit card number on the card": "Masukkan nomor kartu 16 digit",
    "Expiry Date": "Tanggal Kedaluwarsa",
    "Enter the expiration date of the card": "Masukkan tanggal kedaluwarsa kartu",
    "CVV Number": "Nomor CVV",
    "Enter the 3 or 4 digit number on the card": "Masukkan nomor 3 atau 4 digit pada kartu",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Pilih Wallet",
    "My Wallet": "Wallet saya",
    "Conversion": "Konversi",
    "Convert": "Konversi",
    "Your payment is confirmed": "Pembayaran Anda dikonfirmasi",
    "Thanks for using InstaPay": "Terima kasih telah menggunakan InstaPay",
    "Status": "Status",
    "Share": "Bagikan",
    "Transfer created": "Transfer dibuat",
    "Payment received": "Pembayaran diterima",
    "Payment processed": "Pembayaran diproses",
    "Transfer successful": "Transfer berhasil",
    "It may take few hours for the funds to appear in [user name] account": "Mungkin perlu beberapa jam agar dana muncul di akun [nama pengguna]",
    "Transfer failed": "Transfer gagal",
    "Transaction Details": "Detail Transaksi",
    "Need help?": "Butuh bantuan?",
    "Service Details": "Rincian Layanan",
    "Funds Deposit Speed": "Ketersediaan Dana",
    "Cut-off Time": "Batas Waktu",
    "Bank holiday list": "Daftar Libur Bank",
    "Threshold limits": "Batas Transaksi",
    "null": null,
    "Instant, Real Time": "Instan, waktu nyata",
    "Same day, T+1": "Hari yang sama, T+1",
    "Non-instant, T+1": "Tidak instan, T+1",
    "Instant Payment, Real Time": "Pembayaran instan, waktu nyata",
    "Transaction processed before 3:00 PM Local time": "Transaksi diproses sebelum pukul 15:00 waktu setempat",
    "Delivered the same day": "Dikirim pada hari yang sama",
    "Transactions processed post 3:00 PM": "Transaksi diproses setelah pukul 15:00",
    "T+1 business days": "T+1 hari kerja",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Pembayaran yang dilakukan pada hari kerja sebelum pukul 15:00 akan dikreditkan ke rekening bank penerima pada tengah malam hari yang sama.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Pembayaran yang dilakukan pada hari kerja setelah pukul 15:00 akan dikreditkan pada tengah malam hari berikutnya.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Pembayaran yang dilakukan pada hari Sabtu, Minggu, atau hari libur nasional akan dikreditkan ke rekening pada tengah malam hari kerja berikutnya.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 Waktu Setempat",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Batas minimum per transaksi:",
    "Maximum per transaction limit:": "Batas maksimum per transaksi:",
    "Daily threshold limit:": "Batas harian:",
    "Weekly threshold limit:": "Batas mingguan:",
    "Monthly threshold limit:": "Batas bulanan:",
    "Yearly threshold limit:": "Batas tahunan:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Akun Dasar (Tidak Terverifikasi)",
    "Identity Verified": "Identitas Terverifikasi"
  
}