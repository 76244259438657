// import { useSelector } from "react-redux"
import styles from "./styles/index.module.scss"
import { Check, ChevronDown, ChevronRight } from "react-feather"
import { useTranslation } from "react-i18next"
import CategoriesDropdown from "./CategoriesDropdown"
import { Button, Input, Label } from "reactstrap"
import { useContext, useMemo, useState } from "react"
import axios from "axios"
import { UserContext } from "../../Context/UserContext"
import { encryptData } from "../../Helper/EssentilaMethods/Encryption"
import { decryptData } from "../../Helper/EssentilaMethods/Decryption"
import CatDown from "./CatDown"
import ImagePreviewModal from "../ImagePreviewModal.jsx"
import statuses from "./files/thunesTransactionStatus.json"

export const service_name_formatter = (itemName) => {
    if (itemName) {
        const nameArr = itemName.split("_")
        const newName = nameArr.map(item => item = `${item[0].toUpperCase()}${item?.slice(1)}`).join(" ")
        return newName;
    }
}

const TransactionItem = ({ color, item, activeItem, setActiveItem, index }) => {
    const { t, i18n } = useTranslation()
    const [selectedCategory, setSelectedCategory] = useState([]);
    const { userDetails, authHeader, beneficiaries } = useContext(UserContext)
    const [note, setNote] = useState(item?.note)
    const [isSaving, setIsSaving] = useState(false)
    const [isEditMode, setIsEditMode] = useState(item?.note ? false : true)
    const [message, setMessage] = useState("")

    const badgeColor = useMemo(() => {
        switch (item?.status?.toLowerCase()) {
            case "initiated":
                return "#ADD8E6"
            case "completed":
                return "#28A745"
            case "rejected":
                return "#DC3545"
            case "cancelled":
                return "#C82333"
            case "submitted":
                return "#FFA500"
            case "available":
                return "#007BFF"
            case "confirmed":
                return "#218838"
            case "reversed":
                return "#FFC107"
            case "declined":
            case "failed":
                return "#C82333"
            default:
                break;
        }
    }, [item])

    const addCategoriesToTransaction = () => {
        setMessage("")
        setIsSaving(true)
        const data = {
            transaction_id: item._id,
            categories: selectedCategory.map(item => item._id),
            note
        }
        // console.log(data)
        const encrypted = encryptData(data)
        axios.post(`${process.env.REACT_APP_IP_DEV_API}/api/transaction/add-transactions-category`, {
            data: encrypted
        }, {
            headers: authHeader
        })
            .then(res => {
                const decrypted = decryptData(res.data.data)
                // console.log(decrypted)
                setIsSaving(false)
                setIsEditMode(false)
                setMessage("Categories Added Successfully!")
                setTimeout(() => {

                    setMessage("")
                }, 3000);
            })
            .catch(err => {
                const decrypted = decryptData(err.response.data.data)
                // console.log(decrypted)
                setIsSaving(false)
                setMessage("Something went wrong, try again.")
                setTimeout(() => {

                    setMessage("")
                }, 3000);
            })
    }

    const internationalReceiver = useMemo(() => {
        if (!item?.beneficiary) return null;
        return beneficiaries.filter(benef => benef?._id === item?.beneficiary)?.[0]
    }, [])


    const [currentImage, setCurrentImage] = useState("")

    const externalStatusDescription = useMemo(() => {
        return statuses?.[item?.external_status?.split("_")?.[1]]?.[i18n.language]?.message ?? ""
    }, [item, i18n])
    return (
        <div>
            <div className={styles.body_item} onClick={() => {
                if (!activeItem || activeItem !== index + 1) {
                    setActiveItem(index + 1)
                } else {
                    setActiveItem(0)
                }
            }}>
                <div style={{ minWidth: "155px" }}>{new Date(item?.createdAt)?.toLocaleString()}</div>
                <div>{service_name_formatter(item?.service_type)}</div>
                <div>{item?.reference_id}</div>
                <div>
                    {
                        item?.service_type === "withdraw_by_admin" || item?.service_type === "deposite_by_admin"
                            ?
                            "Admin Funds"
                            :
                            item?.payment_type?.includes("bank")
                                ?
                                "Bank Transfer"
                                :
                                item?.payment_type?.includes("mobile")
                                    ?
                                    "Mobile Money"
                                    :
                                    service_name_formatter(item?.payment_type)
                    }
                </div>
                <div>
                    {
                        item?.service_type === "airtime"
                            ?
                            item?.airtime_number
                            :
                            item?.payment_type === "conversion" /*|| item?.service_type === "withdrawal"*/
                                ?
                                item?.wallet_id
                                :
                                item?.transaction_type?.toLowerCase() === "debit"
                                    ?
                                    <>
                                        {
                                            item?.service_type === "withdraw_by_admin"
                                                ?
                                                "Admin"
                                                :
                                                item?.service_type === "kyc_verification"
                                                    ?
                                                    "InstaPay"
                                                    :
                                                    item?.service_type === "withdrawal"
                                                        ?
                                                        `${item?.sender?.user?.first_name} ${item?.sender?.user?.last_name}`.slice(0, 20)
                                                        :
                                                        item?.receiver
                                                            ?
                                                            `${item?.receiver?.user?.first_name} ${item?.receiver?.user?.last_name}`.slice(0, 20)
                                                            :
                                                            `${internationalReceiver?.first_name} ${internationalReceiver?.last_name}`.slice(0, 20)
                                        }
                                    </>
                                    :
                                    item?.service_type === "topup"
                                        ?
                                        `${item?.receiver?.user?.first_name} ${item?.receiver?.user?.last_name}`.slice(0, 20)
                                        :
                                        `${item?.sender?.user?.first_name} ${item?.sender?.user?.last_name}`.slice(0, 20)
                    }
                </div>
                <div style={{ color: item?.transaction_type?.toLowerCase() === "debit" ? "#FF7293" : "#1CB234" }} >{item?.transaction_type?.toLowerCase() === "debit" ? "-" : "+"}{item?.currency?.symbol}{item?.total ? item?.total?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""}</div>
                <div>
                    <div style={{
                        background: badgeColor,
                        fontSize: "12px",
                        color: "white",
                        width: "100px",
                        margin: "0 auto",
                        padding: "5px",
                        borderRadius: "30px",
                        textAlign: "center"
                    }} >{t(item?.status?.toUpperCase())}</div>
                </div>
                <div style={{ maxWidth: "15px" }}>{
                    activeItem === index + 1
                        ?
                        <ChevronDown />
                        :
                        <ChevronRight />
                }</div>
            </div>
            {
                activeItem === index + 1
                &&
                <div className={styles.item_description}>
                    <div className={styles.details}>
                        <div className={styles.colored_card}>
                            <button>{item?.status === "Pending" ? "Processing" : "Completed"}</button>
                            <span>{t("Total")}:</span>
                            <h4>{item?.currency?.symbol} {item?.total?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 0.00}</h4>
                            <div className="mb-3 mt-3">
                                <span>{t("Amount")}</span>
                                <h5>{item?.currency?.symbol} {item?.amount ? item?.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</h5>
                            </div>
                            <div>
                                <span>{t("Fees")}:</span>
                                <h5>{item?.currency?.symbol} {item?.fee ? item?.fee.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</h5>
                            </div>
                        </div>
                        <div className={styles.trans_dets}>
                            <div className={styles.t_item}>
                                <span>{t("Recipient")}:</span>
                                <div>
                                    <div>
                                        <div style={{
                                            minWidth: "50px",
                                            minHeight: "50px",
                                            borderRadius: "25px",
                                            display: "grid",
                                            placeItems: "center",
                                            background: "linear-gradient(-24deg, rgb(210, 60, 122) 0%, rgb(164, 52, 167) 43.66%, rgb(88, 38, 239) 100%)",
                                            color: "white",
                                            fontSize: "1rem",
                                            fontWeight: 800
                                        }}>
                                            {
                                                item?.service_type === "airtime"
                                                    ?
                                                    item?.airtime_number?.[1]
                                                    :
                                                    item?.payment_type === "conversion" || item?.service_type === "withdrawal"
                                                        ?
                                                        item?.wallet_id?.[0]
                                                        :
                                                        item?.transaction_type?.toLowerCase() === "debit"
                                                            ?
                                                            <>
                                                                {
                                                                    item?.service_type === "withdraw_by_admin"
                                                                        ?
                                                                        "Admin"?.[0]
                                                                        :
                                                                        item?.service_type === "kyc_verification"
                                                                            ?
                                                                            "InstaPay"?.[0]
                                                                            :
                                                                            item?.service_type === "withdrawal"
                                                                                ?
                                                                                `${item?.sender?.user?.first_name} ${item?.sender?.user?.last_name}`.slice(0, 20)?.[0]
                                                                                :
                                                                                item?.receiver
                                                                                    ?
                                                                                    `${item?.receiver?.user?.first_name} ${item?.receiver?.user?.last_name}`.slice(0, 20)?.[0]
                                                                                    :
                                                                                    `${internationalReceiver?.first_name} ${internationalReceiver?.last_name}`.slice(0, 20)?.[0]
                                                                }
                                                            </>
                                                            :
                                                            item?.service_type === "topup"
                                                                ?
                                                                `${item?.receiver?.user?.first_name} ${item?.receiver?.user?.last_name}`.slice(0, 20)?.[0]
                                                                :
                                                                `${item?.sender?.user?.first_name} ${item?.sender?.user?.last_name}`.slice(0, 20)?.[0]
                                            }
                                        </div>
                                        <div>
                                            <h5>
                                                {
                                                    item?.service_type === "airtime"
                                                        ?
                                                        item?.airtime_number
                                                        :
                                                        item?.payment_type === "conversion" || item?.service_type === "withdrawal"
                                                            ?
                                                            item?.wallet_id
                                                            :
                                                            item?.transaction_type?.toLowerCase() === "debit"
                                                                ?
                                                                <>
                                                                    {
                                                                        item?.service_type === "kyc_verification"
                                                                            ?
                                                                            "InstaPay"
                                                                            :
                                                                            item?.service_type === "withdrawal"
                                                                                ?
                                                                                `${item?.sender?.user?.first_name} ${item?.sender?.user?.last_name}`.slice(0, 20)
                                                                                :
                                                                                item?.receiver
                                                                                    ?
                                                                                    `${item?.receiver?.user?.first_name} ${item?.receiver?.user?.last_name}`.slice(0, 20)
                                                                                    :
                                                                                    `${internationalReceiver?.first_name} ${internationalReceiver?.last_name}`.slice(0, 20)
                                                                    }
                                                                </>
                                                                :
                                                                item?.service_type === "topup"
                                                                    ?
                                                                    `${item?.receiver?.user?.first_name} ${item?.receiver?.user?.last_name}`.slice(0, 20)
                                                                    :
                                                                    `${item?.sender?.user?.first_name} ${item?.sender?.user?.last_name}`.slice(0, 20)
                                                }
                                            </h5>
                                            <p>INSTA-{item?.wallet_id}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                item?.description
                                &&
                                <div className={styles.t_item}>
                                    <span>{t("Note")}:</span>
                                    <div>
                                        <h5>{item?.description}</h5>
                                    </div>
                                </div>
                            }
                            {
                                item?.attachments?.length > 0
                                &&
                                <div className={styles.t_item}>
                                    <span>{t("Attachments")}:</span>
                                    <div style={{
                                        display: "flex",
                                        gap: ".75rem",
                                        maxWidth: "100%",
                                        overflowX: "auto",
                                        marginLeft: ".7rem"
                                    }}>
                                        {
                                            item?.attachments?.map(it => (
                                                <img key={it?.key} src={it?.url} alt="" style={{
                                                    height: "60px",
                                                    width: "60px",
                                                    objectFit: "cover",
                                                    borderRadius: "10px"
                                                }}
                                                    onClick={() => setCurrentImage({
                                                        src: it?.url,
                                                        index: it?.key
                                                    })}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            {
                                currentImage
                                &&
                                <ImagePreviewModal
                                    currentImage={currentImage}
                                    isOpen={currentImage}
                                    toggle={() => setCurrentImage("")}
                                />
                            }
                            <div className={styles.t_item}>
                                <span>{t("Category")}:</span>
                                <CatDown selectedCategories={selectedCategory} setSelectedCategories={setSelectedCategory} isEditMode={isEditMode} item={item} />
                            </div>
                            {
                                selectedCategory.length > 0
                                &&
                                <>
                                    <div className={styles.t_item}>
                                        <span>{t("Note")}:</span>
                                        <div>
                                            <Input placeholder="Add a note" style={{ borderRadius: "14px" }} value={note} onChange={e => setNote(e.target.value)} disabled={!isEditMode} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        {
                                            message
                                                ?
                                                <span style={{ fontWeight: 700 }}>{message}</span>
                                                :
                                                <div></div>
                                        }
                                        {
                                            isEditMode
                                                ?
                                                <Button style={{
                                                    background: "linear-gradient(to right bottom, #e64067, #5926f0, #4828a0)",
                                                    border: "none",
                                                    outline: "none",
                                                    borderRadius: "10px"
                                                }}
                                                    onClick={addCategoriesToTransaction}
                                                    disabled={isSaving || selectedCategory.length < 1}
                                                >
                                                    {isSaving ? "Saving" : "Save"}
                                                </Button>
                                                :
                                                <Button style={{
                                                    background: "linear-gradient(to right bottom, #e64067, #5926f0, #4828a0)",
                                                    border: "none",
                                                    outline: "none",
                                                    borderRadius: "10px"
                                                }}
                                                    onClick={() => {
                                                        setIsEditMode(true)
                                                        setMessage("")
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className={styles.timeline}>
                        <span>{t("Status")}</span>
                        <div>
                            {
                                item?.timeline?.length > 0
                                &&
                                item?.timeline?.map(i => (
                                    <div className={styles.timeline_item}>
                                        <div className={styles.circle}>
                                            <Check color="white" size={15} />
                                        </div>
                                        <div>
                                            <h5>{i?.status}</h5>
                                            <p>{new Date(i?.date).toLocaleString()}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            externalStatusDescription
                            &&
                            <>
                                <span>{t("Description")}: </span>
                                <div style={{ fontSize: ".9rem", fontWeight: 600, color: "#afafaf", marginTop: ".5rem" }}>
                                    {
                                        externalStatusDescription
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default TransactionItem
