export const en = {
    "How Others Can Find You": "How Others Can Find You",
    "Schedules": "Schedules",
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Start Your InstaPay Journey Today!",
    "Phone Number or Email": "Phone Number or Email",
    "Login Using Social Media": "Login Using Social Media",
    "Continue": "Continue",
    "Don't Have an Account? Sign Up": "Don't Have an Account? Sign Up",
    "Sign Up": "Sign Up",
    "Edit": "Edit",
    "Enter Your Password": "Enter Your Password",
    "Forgot Password?": "Forgot Password?",
    "Account Type": "Account Type",
    "Individual": "Individual",
    "Business": "Business",
    "Already Have an Account? Sign In": "Already Have an Account? Sign In",
    "Sing In": "Sing In",
    "Enter Your Personal Details": "Enter Your Personal Details",
    "Add Photo or Logo": "Add Photo or Logo",
    "Enter Your Name": "Enter Your Name",
    "Back": "Back",
    "Phone Number": "Phone Number",
    "Email Address": "Email Address",
    "Create a Strong Password": "Create a Strong Password",
    "Password Requirements": "Password Requirements",
    "Must be between 9 and 64 characters": "Must be between 9 and 64 characters",
    "Include at least two of the following:": "Include at least two of the following:",
    "Uppercase character": "Uppercase character",
    "Lowercase character": "Lowercase character",
    "Number": "Number",
    "Special character": "Special character",
    "Confirm Password": "Confirm Password",
    "Finalize Your Process": "Finalize Your Process",
    "Referral Code (Optional)": "Referral Code (Optional)",
    "It will autofill if you're signing up with an invitation link.": "It will autofill if you're signing up with an invitation link.",
    "I agree with": "I agree with",
    "Terms & Conditions": "Terms & Conditions",
    "Privacy Policy": "Privacy Policy",
    "Create Your Account": "Create Your Account",
    "Enter Code": "Enter Code",
    "A one-time verification code has been sent to your email address  and phone number.": "A one-time verification code has been sent to your email address  and phone number.",
    "Resend available in ": "Resend available in ",
    "seconds": "seconds",
    "Verify": "Verify",
    "Verification Code Verified": "Verification Code Verified",
    "Please continue to the next step.": "Please continue to the next step.",
    "Finish": "Finish",
    "Finishing": "Finishing",
    "Company Name": "Company Name",
    "Enter Your Business Details": "Enter Your Business Details",
    "Country ": "Country ",
    "You cannot change the country once your account is verified.": "You cannot change the country once your account is verified.",
    "Learn more": "Learn more",
    "Optional": "Optional",
    "Continuing": "Continuing",
    "Didn't receive the verification code?": "Didn't receive the verification code?",
    "Resend Now": "Resend Now",
    "Search Here": "Search Here",
    "Search for users, services, and more on InstaPay": "Search for users, services, and more on InstaPay",
    "Search users here": "Search users here",
    "Earn money with our affiliate program!": "Earn money with our affiliate program!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.",
    "Learn More": "Learn More",
    "Balance Amount": "Balance Amount",
    "You spent less compared to last month.": "You spent less compared to last month.",
    "Referral Rewards": "Referral Rewards",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Quick Transfer to Your Most Recent Contacts:",
    "Number of Transactions": "Number of Transactions",
    "Total Transaction Amount": "Total Transaction Amount",
    "Total Sent": "Total Sent",
    "Total Received": "Total Received",
    "Add Funds": "Add Funds",
    "Send Money": "Send Money",
    "Request Money": "Request Money",
    "Send a Quote": "Send a Quote",
    "Pay With Crypto": "Pay With Crypto",
    "Payment Insights": "Payment Insights",
    "Recent Transactions ": "Recent Transactions ",
    "Today": "Today",
    "My Wallets": "My Wallets",
    "Balance": "Balance",
    "Wallet ID": "Wallet ID",
    "Monthly Limit": "Monthly Limit",
    "Top Up": "Top Up",
    "Summary": "Summary",
    "Settings": "Settings",
    "More": "More",
    "Upgrade to Business Account": "Upgrade to Business Account",
    "Conversion": "Conversion",
    "Enter Amount": "Enter Amount",
    "Exchanged Amount": "Exchanged Amount",
    "Convert": "Convert",
    "You Are Converting": "You Are Converting",
    "From": "From",
    "To": "To",
    "Exchange Rate": "Exchange Rate",
    "Fees": "Fees",
    "You Will Receive": "You Will Receive",
    "Slide To Confirm": "Slide To Confirm",
    "Verification Code Confirmed - You'll be redirected to the next step": "Verification Code Confirmed - You'll be redirected to the next step",
    "Moving to next step in": "Moving to next step in",
    "Thanks for using InstaPay": "Thanks for using InstaPay",
    "Your funds have been credited to your wallet.": "Your funds have been credited to your wallet.",
    "Dashboard": "Dashboard",
    "Accounts": "Accounts",
    "Transactions": "Transactions",
    "Other Transactions": "Other Transactions",
    "Payments": "Payments",
    "Beneficiaries": "Beneficiaries",
    "Referrals": "Referrals",
    "Quotations": "Quotations",
    "Pending": "Pending",
    "My QR Code Sticker": "My QR Code Sticker",
    "My Portfolio": "My Portfolio",
    "My Payment Address": "My Payment Address",
    "Analytics": "Analytics",
    "Profile": "Profile",
    "Dark Mode": "Dark Mode",
    "Support": "Support",
    "Logout": "Logout",
    "Powered By": "Powered By",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "A Swiss Company",
    "MAIN MENU": "MAIN MENU",
    "OTHERS": "OTHERS",
    "Wallet": "Wallet",
    "Default": "Default",
    "Breakdown": "Breakdown",
    "Credit": "Credit",
    "Debit": "Debit",
    "Wallet Management": "Wallet Management",
    "Top-up Your Wallet": "Top-up Your Wallet",
    "Download Statement": "Download Statement",
    "Block the Wallet": "Block the Wallet",
    "Wallet Status": "Wallet Status",
    "-Select-File-Type-": "-Select-File-Type-",
    "Download Now": "Download Now",
    "Downloading": "Downloading",
    "All": "All",
    "Sent": "Sent",
    "Received": "Received",
    "Requested": "Requested",
    "Quoted": "Quoted",
    "Search Transactions": "Search Transactions",
    "Date & Time": "Date & Time",
    "Type": "Type",
    "Transaction ID": "Transaction ID",
    "Recipient": "Recipient",
    "Amount": "Amount",
    "Status": "Status",
    "Payment Type": "Payment Type",
    "Sent Payments": "Sent Payments",
    "Received Payments": "Received Payments",
    "Date": "Date",
    "Cycles/Time": "Cycles/Time",
    "Payment As": "Payment As",
    "Name": "Name",
    "Cancel": "Cancel",
    "Subscriptions & Scheduled": "Subscriptions & Scheduled",
    "Select the option that matches your needs from the list below:": "Select the option that matches your needs from the list below:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.",
    "International Transfer": "International Transfer",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.",
    "Select the country where you want to send the money!": "Select the country where you want to send the money!",
    "Search Country": "Search Country",
    "Trouble Sending Money?": "Trouble Sending Money?",
    "Confirm": "Confirm",
    "Select Payout Channel": "Select Payout Channel",
    "Select your most convenient option to send the money.": "Select your most convenient option to send the money.",
    "Select The Bank": "Select The Bank",
    "Search Bank": "Search Bank",
    "Send money directly to a recipient's bank account. Enjoy the convenience \nand security of transferring funds seamlessly to any bank around the world.": "Send money directly to a recipient's bank account. Enjoy the convenience \nand security of transferring funds seamlessly to any bank around the world.",
    "Bank Transfer": "Bank Transfer",
    "Enter the amount": "Enter the amount",
    "Tax": "Tax",
    "Amount the recipient will receive": "Amount the recipient will receive",
    "Select Remarks / Reason": "Select Remarks / Reason",
    "Comments": "Comments",
    "Attach Files": "Attach Files",
    "Groups": "Groups",
    "Manage Groups": "Manage Groups",
    "Add New Group": "Add New Group",
    "Invite": "Invite",
    "Manage Beneficiaries": "Manage Beneficiaries",
    "Recipients List": "Recipients List",
    "Search Contacts": "Search Contacts",
    "Confirm Your Payment": "Confirm Your Payment",
    "Select Country": "Select Country",
    "Select the Recipient's Payment Channel": "Select the Recipient's Payment Channel",
    "Change Selection": "Change Selection",
    "You Are Transferring ": "You Are Transferring ",
    "Oops! Something Went Wrong.": "Oops! Something Went Wrong.",
    "We're Sorry About That. ": "We're Sorry About That. ",
    "Go back to Payments Page.": "Go back to Payments Page.",
    "Wallet to Wallet Transfer": "Wallet to Wallet Transfer",
    "Request a customized price quote from service providers or vendors.": "Request a customized price quote from service providers or vendors.",
    "Enter the Wallet ID": "Enter the Wallet ID",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Enter the Wallet ID, Email, or Phone Number of the recipient.",
    "Recipient not on InstaPay?": "Recipient not on InstaPay?",
    "Invite Them": "Invite Them",
    "My Beneficiaries": "My Beneficiaries",
    "Enter the Amount You Wish to Transfer.": "Enter the Amount You Wish to Transfer.",
    "Sending to ": "Sending to ",
    "You Have Selected Wallet to Wallet Transfer": "You Have Selected Wallet to Wallet Transfer",
    "Authenticate Your Payment.": "Authenticate Your Payment.",
    "You are transferring": "You are transferring",
    "Total ": "Total ",
    "Where Is My Payment?": "Where Is My Payment?",
    "Share": "Share",
    "Your Payment Is Confirmed": "Your Payment Is Confirmed",
    "QR Code Payment": "QR Code Payment",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.",
    "Scan QR with Your Camera or Upload from Your Device.": "Scan QR with Your Camera or Upload from Your Device.",
    "Upload from Your Device.": "Upload from Your Device.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Alternatively, Use the InstaPay Unique Payment Address (UPA)",
    "Enter InstaPay UPA": "Enter InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Recipient not on InstaPay? Invite Them",
    "Verify QR Code": "Verify QR Code",
    "Scan/Upload QR Code of the Recipient": "Scan/Upload QR Code of the Recipient",
    "Payment Confirmed": "Payment Confirmed",
    "Request customized price quotes from service providers or vendors to receive \naccurate cost estimates for the services or products you need.": "Request customized price quotes from service providers or vendors to receive \naccurate cost estimates for the services or products you need.",
    "Choose The Beneficiary or Group": "Choose The Beneficiary or Group",
    "Enter the Amount You Wish to Request.": "Enter the Amount You Wish to Request.",
    "You Are Requesting Money": "You Are Requesting Money",
    "You are requesting": "You are requesting",
    "As": "As",
    "Conversion of Your Cryptocurrencies": "Conversion of Your Cryptocurrencies",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Choose the Cryptocurrency You Intend to Liquidate.",
    "Next": "Next",
    "Enter the Crypto Wallet Address of the Recipient.": "Enter the Crypto Wallet Address of the Recipient.",
    "Minimum Transactional Value: $100.": "Minimum Transactional Value: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.",
    "Select Network": "Select Network",
    "Send A Quote": "Send A Quote",
    "Send a detailed price quote to your clients or customers. Present your services, products, \nor offers professionally with a personalized quotation.": "Send a detailed price quote to your clients or customers. Present your services, products, \nor offers professionally with a personalized quotation.",
    "Choose the Beneficiary": "Choose the Beneficiary",
    "Allow Receiver to Bargain": "Allow Receiver to Bargain",
    "Enter the Title for Your Quotation.": "Enter the Title for Your Quotation.",
    "Enter the Description for Your Quotation": "Enter the Description for Your Quotation",
    "You are Quoting": "You are Quoting",
    "Title": "Title",
    "Description": "Description",
    "Attachments": "Attachments",
    "Confirm Your Quotation": "Confirm Your Quotation",
    "Verify The Quotation": "Verify The Quotation",
    "Your quotation is confirmed": "Your quotation is confirmed",
    "Request A Quote": "Request A Quote",
    "Coming Soon": "Coming Soon",
    "Add Beneficiary": "Add Beneficiary",
    "Search for Beneficiaries": "Search for Beneficiaries",
    "Connected Accounts": "Connected Accounts",
    "Details": "Details",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Country": "Country",
    "City": "City",
    "Address Line": "Address Line",
    "Mobile Number": "Mobile Number",
    "Relationship With Beneficiary": "Relationship With Beneficiary",
    "Bank Account": "Bank Account",
    "Mobile Money/Mobile Wallet": "Mobile Money/Mobile Wallet",
    "Payment Card": "Payment Card",
    "Crypto Wallet": "Crypto Wallet",
    "InstaPay Account": "InstaPay Account",
    "Cash Pickup": "Cash Pickup",
    "IBAN Or Swift/BIC code": "IBAN Or Swift/BIC code",
    "Swift Code": "Swift Code",
    "Account Number": "Account Number",
    "Bank Name": "Bank Name",
    "Branch Name": "Branch Name",
    "Branch Street": "Branch Street",
    "Province": "Province",
    "Postal Code": "Postal Code",
    "Mobile Money Provider": "Mobile Money Provider",
    "Wallet Name": "Wallet Name",
    "Wallet Number": "Wallet Number",
    "Card Holder Name": "Card Holder Name",
    "Card Number": "Card Number",
    "Expiry Date": "Expiry Date",
    "Crypto Currency": "Crypto Currency",
    "Wallet Address": "Wallet Address",
    "Wallet Holder Name": "Wallet Holder Name",
    "Wallet Currency": "Wallet Currency",
    "Select Document Type": "Select Document Type",
    "Enter Document Number": "Enter Document Number",
    "Add Individual Account": "Add Individual Account",
    "Add Business Account": "Add Business Account",
    "Company Address": "Company Address",
    "Company Email": "Company Email",
    "Company Phone No": "Company Phone No",
    "Total Referrals": "Total Referrals",
    "My Earnings": "My Earnings",
    "Your Referral ID": "Your Referral ID",
    "Share Your Unique Referral Link": "Share Your Unique Referral Link",
    "How do I Refer a Friend to InstaPay?": "How do I Refer a Friend to InstaPay?",
    "Step 01": "Step 01",
    "Share your InstaPay unique referral link with your friends.": "Share your InstaPay unique referral link with your friends.",
    "Step 02": "Step 02",
    "Earn rewards when they transact $100 or more": "Earn rewards when they transact $100 or more",
    "Step 03": "Step 03",
    "Earn up to $0.06 for every transaction your followers make.": "Earn up to $0.06 for every transaction your followers make.",
    "My Referrals": "My Referrals",
    "Referral Complete": "Referral Complete",
    "Received Quotations": "Received Quotations",
    "Sent Quotations": "Sent Quotations",
    "Search Pending Items": "Search Pending Items",
    "Actions": "Actions",
    "Received Requests": "Received Requests",
    "Sent Requests": "Sent Requests",
    "No Actions": "No Actions",
    "You Requested": "You Requested",
    "Via": "Via",
    "How was your experience?": "How was your experience?",
    "Leave a Review": "Leave a Review",
    "Overall Ratings": "Overall Ratings",
    "Visit Profile": "Visit Profile",
    "Ratings": "Ratings",
    "Reviews": "Reviews",
    "No reviews available": "No reviews available",
    "Select Your Currency": "Select Your Currency",
    "Proceed": "Proceed",
    "Commision": "Commision",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Unlock Seamless Transactions with InstaPay QR Code Sticker",
    "Effortless and Swift:": "Effortless and Swift:",
    "Instantly download your unique InstaPay QR code sticker.": "Instantly download your unique InstaPay QR code sticker.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Swiftly collect payments – no more tedious entry of credit card or phone numbers.",
    "Each payment includes customer information for your convenience.": "Each payment includes customer information for your convenience.",
    "Economically Smart:": "Economically Smart:",
    "Zero activation fees – start immediately without upfront costs.": "Zero activation fees – start immediately without upfront costs.",
    "Forget about fixed monthly charges; pay as you transact.": "Forget about fixed monthly charges; pay as you transact.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Ditch the hardware; no terminal costs mean more savings for you.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Enjoy low transaction fees at just 0.75% per QR code payment.",
    "We don't impose minimum commissions; you save more with each transaction.": "We don't impose minimum commissions; you save more with each transaction.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Experience the joy of instant payments, enhancing your cash flow.",
    "Make Every Transaction Count with InstaPay": "Make Every Transaction Count with InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Download QR",
    "QR Code Status": "QR Code Status",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.",
    "QR Code Title": "QR Code Title",
    "View Transactions": "View Transactions",
    "My QR Codes:": "My QR Codes:",
    "Load More": "Load More",
    "Benefits Of Portfolio": "Benefits Of Portfolio",
    "Benefits:": "Benefits:",
    "Enhanced Visibility:": "Enhanced Visibility:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible \ndirectly from your Payment Address.": "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible \ndirectly from your Payment Address.",
    "Engage Your Audience:": "Engage Your Audience:",
    "Connect with visitors through your creative or professional journey. Let your work \nspeak for you, creating a deeper engagement with potential clients or supporters.": "Connect with visitors through your creative or professional journey. Let your work \nspeak for you, creating a deeper engagement with potential clients or supporters.",
    "Integrated Social Proof:": "Integrated Social Proof:",
    "Display your social media influence. Visitors can see your social media reach, \nadding credibility and context to your portfolio.": "Display your social media influence. Visitors can see your social media reach, \nadding credibility and context to your portfolio.",
    "Seamless Transactions:": "Seamless Transactions:",
    "Enable visitors to request or initiate payments right from your public page. Your \nportfolio not only showcases your work but also facilitates easy financial interactions.": "Enable visitors to request or initiate payments right from your public page. Your \nportfolio not only showcases your work but also facilitates easy financial interactions.",
    "Personalized Storytelling:": "Personalized Storytelling:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a\n personal connection with your audience.": "Use the 'About Me' section to share your story, mission, or vision, creating a\n personal connection with your audience.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your \naudience, and streamline your financial interactions on InstaPay.": "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your \naudience, and streamline your financial interactions on InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.",
    "Add/Manage Portfolio": "Add/Manage Portfolio",
    "Add New": "Add New",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maximize Your Social Media Earnings with Your InstaPay Payment Address",
    "Make It Effortless for Your Audience to Support You:": "Make It Effortless for Your Audience to Support You:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Easily integrate your InstaPay Payment Address into your social media posts.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.",
    "Instant Support, Endless Possibilities:": "Instant Support, Endless Possibilities:",
    "Transform the way you monetize your social media content.": "Transform the way you monetize your social media content.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Where to Place Your InstaPay Payment Address for Maximum Impact:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Social Media Profiles: Pin it to your bio or about section for constant visibility.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.",
    "Simple, Fast, and Secure:": "Simple, Fast, and Secure:",
    "No complex setup – get started in moments.": "No complex setup – get started in moments.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Enjoy the security and speed of InstaPay's trusted payment processing.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Receive payments from anyone, anywhere, without them needing an InstaPay account.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Elevate Your Social Media Game with InstaPay Payment Address",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Virtual Payment Address)",
    "Payment Address Title": "Payment Address Title",
    "Currency": "Currency",
    "Payment Address Description": "Payment Address Description",
    "Update": "Update",
    "Updating": "Updating",
    "Total Transaction": "Total Transaction",
    "Last 30 days": "Last 30 days",
    "Last 1 month": "Last 1 month",
    "Last 1 year": "Last 1 year",
    "Custom Date": "Custom Date",
    "Payment Methods": "Payment Methods",
    "Payment Types": "Payment Types",
    "Requested Amount": "Requested Amount",
    "Quoted Amount": "Quoted Amount",
    "About Me": "About Me",
    "Followers": "Followers",
    "InstaPay ID": "InstaPay ID",
    "Download QR Code": "Download QR Code",
    "Initiate A Payment": "Initiate A Payment",
    "Report this person": "Report this person",
    "Add now": "Add now",
    "Recent Reviews": "Recent Reviews",
    "Reviews As Seller": "Reviews As Seller",
    "Reviews As Buyer": "Reviews As Buyer",
    "Select Language": "Select Language",
    "Profile Completion": "Profile Completion",
    "Profile Completed": "Profile Completed",
    "Basic Info": "Basic Info",
    "Username": "Username",
    "Gender": "Gender",
    "Male": "Male",
    "Female": "Female",
    "-Select-Gender-": "-Select-Gender-",
    "Date Of Birth": "Date Of Birth",
    "Address": "Address",
    "Edit Profile": "Edit Profile",
    "Update Profile": "Update Profile",
    "Updating Profile": "Updating Profile",
    "Complete Now": "Complete Now",
    "Password": "Password",
    "Change Password": "Change Password",
    "New Password": "New Password",
    "Re-enter New Password": "Re-enter New Password",
    "Please follow this guide for a strong password": "Please follow this guide for a strong password",
    "Include at least one special character.": "Include at least one special character.",
    "Minimum of 8 characters.": "Minimum of 8 characters.",
    "Include at least one number": "Include at least one number",
    "Change it often for enhanced security.": "Change it often for enhanced security.",
    "Security Questions": "Security Questions",
    "Security Question": "Security Question",
    "Select your question": "Select your question",
    "Answer To The Question": "Answer To The Question",
    "Nominee": "Nominee",
    "Relationship with Nominee": "Relationship with Nominee",
    "Nominee Contact Number": "Nominee Contact Number",
    "Nominee Address": "Nominee Address",
    "Delete Nominee": "Delete Nominee",
    "Update Nominee": "Update Nominee",
    "Details Updated Successfully!": "Details Updated Successfully!",
    "Success": "Success",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay \nplatform itself. You acknowledge that no wet signature is required to appoint the nominee, and \nthe online registration will be considered final and binding. In the event of the death of an \nInstaPay Wallet holder, the balance in the Wallet will be transferred to the registered Nominee. \nKEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to \nthe Nominee. The Nominee will provide any documents required by InstaPay, including those \nfor identification and proof of death.": "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay \nplatform itself. You acknowledge that no wet signature is required to appoint the nominee, and \nthe online registration will be considered final and binding. In the event of the death of an \nInstaPay Wallet holder, the balance in the Wallet will be transferred to the registered Nominee. \nKEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to \nthe Nominee. The Nominee will provide any documents required by InstaPay, including those \nfor identification and proof of death.",
    "Add Nominee": "Add Nominee",
    "Two Factor Authentication": "Two Factor Authentication",
    "SMS Number": "SMS Number",
    "Updated Successfully!": "Updated Successfully!",
    "Social Network Accounts": "Social Network Accounts",
    "Here, you can set up and manage your integration settings.": "Here, you can set up and manage your integration settings.",
    "Social Network Account": "Social Network Account",
    "Activate Account": "Activate Account",
    "Enter the code below in the InstaPay Chatbot.": "Enter the code below in the InstaPay Chatbot.",
    "The code will expire in ": "The code will expire in ",
    "Notifications": "Notifications",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Customize how you receive notifications. These settings apply to the activities you are monitoring.",
    "Activity": "Activity",
    "Email": "Email",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Source",
    "Payment Requests": "Payment Requests",
    "Bulk Payments": "Bulk Payments",
    "Identity Verification": "Identity Verification",
    "Verify Your Identity": "Verify Your Identity",
    "Identity Verification Status:": "Identity Verification Status:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Briefly describe yourself and explain your reasons for using InstaPay.",
    "Enter Your Message": "Enter Your Message",
    "Select Occupation": "Select Occupation",
    "It must be a government-issued identification document with a photo. All identification documents \nmust be written in the Latin alphabet. If not, a certified translation is required.": "It must be a government-issued identification document with a photo. All identification documents \nmust be written in the Latin alphabet. If not, a certified translation is required.",
    "Accepted Documents": "Accepted Documents",
    "A national identity card": "A national identity card",
    "A valid passport": "A valid passport",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Valid residence permits (accepted only if issued by a European country or in Switzerland)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometric Facial Recognition: Comparing video with passport photograph",
    "Address Verification:": "Address Verification:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Provide a bank statement or utility bill (less than 3 months old) as proof of address",
    "Additional Documents:": "Additional Documents:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) \nor further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) \nor further information necessary for conducting thorough verifications, in accordance with its legal obligations.",
    "Start Verification": "Start Verification",
    "Sessions": "Sessions",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "This is a list of devices that have logged into your account. Remove any that you do not recognize.",
    "Your Withdrawal Options": "Your Withdrawal Options",
    "Choose your preferred method for withdrawing payments.": "Choose your preferred method for withdrawing payments.",
    "No withdrawal options currently available in your country.": "No withdrawal options currently available in your country.",
    "Download Your Data": "Download Your Data",
    "Request a Copy of Your Data": "Request a Copy of Your Data",
    "Submit a request to download a copy of your data": "Submit a request to download a copy of your data",
    "Which Data Should Be Included in the Download?": "Which Data Should Be Included in the Download?",
    "Select all applicable options.": "Select all applicable options.",
    "Personal Information": "Personal Information",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "This information is used to secure your account, tailor your experience, and contact you as necessary.",
    "Name and date of birth": "Name and date of birth",
    "Phone number": "Phone number",
    "Financial Information": "Financial Information",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "We use this information to enable faster checkout and to send or receive money in just a few clicks.",
    "Bank accounts": "Bank accounts",
    "Debit or credit cards": "Debit or credit cards",
    "Credit Information": "Credit Information",
    "Includes your credit application information": "Includes your credit application information",
    "Other Information (not included in file)": "Other Information (not included in file)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.",
    "Choose file type:": "Choose file type:",
    "Select file type": "Select file type",
    "PDF file can be easily opened on any computer": "PDF file can be easily opened on any computer",
    "Submit Request": "Submit Request",
    "Delete Your Account": "Delete Your Account",
    "Ask us to delete your data and close this account": "Ask us to delete your data and close this account",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "We use your data to provide services, so if we delete your data, we also need to close your account.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.",
    "If you have a negative balance, click the Resolve link next to your balance.": "If you have a negative balance, click the Resolve link next to your balance.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ",
    "privacy statement": "privacy statement",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "We'll email you to let you know the status, and if there are any other steps you need to take.",
    "Have more questions about data deletion?": "Have more questions about data deletion?",
    "Contact us": "Contact us",
    "I understand that account deletion is permanent and can't be reversed.": "I understand that account deletion is permanent and can't be reversed.",
    "Are you sure you want to close your account?": "Are you sure you want to close your account?",
    "Yes, Continue": "Yes, Continue",
    "Logging In": "Logging In",
    "Complete your profile to streamline your transaction process.": "Complete your profile to streamline your transaction process.",
    "Skip": "Skip",
    "Complete Your Profile": "Complete Your Profile",
    "API Key": "API Key",
    "Get API Key": "Get API Key",
    "Generating New API Key": "Generating New API Key",
    "Generate API Key": "Generate API Key",
    "Regenerate API Key": "Regenerate API Key",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. \nFirst, sign up for an account at [your registration URL] and access the Developer Dashboard. Navigate to the \n'API Keys' section, generate a new key, and ensure its secure storage.": "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. \nFirst, sign up for an account at [your registration URL] and access the Developer Dashboard. Navigate to the \n'API Keys' section, generate a new key, and ensure its secure storage.",
    "Delete API Key": "Delete API Key",
    "Webhook URL": "Webhook URL",
    "KYB Verification": "KYB Verification",
    "Additional Files": "Additional Files",
    "Business Verification": "Business Verification",
    "--Select-Your-Company-Type--": "--Select-Your-Company-Type--",
    "Documents Required:": "Documents Required:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Official Company Registry Extract (Entity's Registration/Incorporation Document)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. \nIt reflects the current status of the business (active or closed).": "This document serves as the company's official 'identity card' and should be obtained from the local business registry. \nIt reflects the current status of the business (active or closed).",
    "The document should be current, with an issuance date not exceeding 3 months.": "The document should be current, with an issuance date not exceeding 3 months.",
    "The document must include the following details:": "The document must include the following details:",
    "Legal name of the company": "Legal name of the company",
    "Full registered address": "Full registered address",
    "Company registration number": "Company registration number",
    "Date of incorporation": "Date of incorporation",
    "Description of the company's activities/purpose": "Description of the company's activities/purpose",
    "Legal representative's information: full name, date and place of birth, residential address \n(minimum requirement: country of residence), and nationality": "Legal representative's information: full name, date and place of birth, residential address \n(minimum requirement: country of residence), and nationality",
    "If applicable, the document should also include:": "If applicable, the document should also include:",
    "Brand or trading names": "Brand or trading names",
    "Value Added Tax (VAT) number": "Value Added Tax (VAT) number",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, \nit may be provided as a separate document.": "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, \nit may be provided as a separate document.",
    "Proof of Legal Representative's Authority": "Proof of Legal Representative's Authority",
    "This document validates an individual's authority to represent the company": "This document validates an individual's authority to represent the company",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a \ndocument such as a Power of Attorney or Delegation of Powers must be secured.": "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a \ndocument such as a Power of Attorney or Delegation of Powers must be secured.",
    "Power of Attorney/Delegation of Powers:": "Power of Attorney/Delegation of Powers:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal \nrepresentative in the company's registry extract.": "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal \nrepresentative in the company's registry extract.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the \nstock exchange website displaying the company's details.": "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the \nstock exchange website displaying the company's details.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of \nthe shares will be subject to Ultimate Beneficial Owner (UBO) verification requirements.": "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of \nthe shares will be subject to Ultimate Beneficial Owner (UBO) verification requirements.",
    "Collection of Identification Documents": "Collection of Identification Documents",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification \ndocuments must be written in the Latin alphabet. If not, a certified translation is required.": "The identification document must be government-issued, bearing a photograph of the individual. All identification \ndocuments must be written in the Latin alphabet. If not, a certified translation is required.",
    "Accepted documents:": "Accepted documents:",
    "Valid National Identity Card": "Valid National Identity Card",
    "Valid Passport": "Valid Passport",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Valid Resident Permits - These will be accepted only if issued by a European country.",
    "Only legal representatives are subject to the identification document collection process.": "Only legal representatives are subject to the identification document collection process.",
    "Additional Requirement:": "Additional Requirement:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their ID.": "In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their ID.",
    "Additional Documents": "Additional Documents",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department \nthat shows the End-user’s address and name (e.g., Residential Certificate); or": "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department \nthat shows the End-user’s address and name (e.g., Residential Certificate); or",
    "Bank Statement; or": "Bank Statement; or",
    "a document issued by a government department that shows the End-user’s address and name \n(e.g., Residential Certificate).": "a document issued by a government department that shows the End-user’s address and name \n(e.g., Residential Certificate).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting \nthorough verifications, in line with its legal obligations.": "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting \nthorough verifications, in line with its legal obligations.",
    "Note:": "Note:",
    "Partners": "Partners",
    "Add New Partner": "Add New Partner",
    "Designation": "Designation",
    "Partner Name": "Partner Name",
    "Partner Email": "Partner Email",
    "Partner Designation": "Partner Designation",
    "Add Now": "Add Now",
    "Adding": "Adding",
    "Delete Partner": "Delete Partner",
    "Delete": "Delete",
    "Deleting": "Deleting",
    "Are you sure, you want to remove sxz as partner?": "Are you sure, you want to remove sxz as partner?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Entity's Registry Extract (Entity'sRegistration/Creation Document)",
    "This document should be either:": "This document should be either:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "A proof of creation of the entity, providing the entity's details, creation date, and purpose.",
    "A local registry extract:": "A local registry extract:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status \n(active or closed).": "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status \n(active or closed).",
    "If a registry extract exists, it must not be older than 3 months.": "If a registry extract exists, it must not be older than 3 months.",
    "Entity name": "Entity name",
    "Registration number (if applicable, based on the country's requirements)": "Registration number (if applicable, based on the country's requirements)",
    "Date of creation": "Date of creation",
    "Description of the entity's activities or purpose": "Description of the entity's activities or purpose",
    "Trading names": "Trading names",
    "Articles of Association": "Articles of Association",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating \nrules.": "The Articles of Association serve as the constitutional document of the entity, outlining its operating \nrules.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of \nentities require at least one annual meeting that may result in an updated version of the Articles of Association.": "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of \nentities require at least one annual meeting that may result in an updated version of the Articles of Association.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, \nroles, responsibilities, etc.": "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, \nroles, responsibilities, etc.",
    "The document should contain information about:": "The document should contain information about:",
    "The entity itself, including its legal name, registered address, and purpose": "The entity itself, including its legal name, registered address, and purpose",
    "Its internal operating rules, such as the appointment process for management board members, their \nroles and powers, among other details. This list is not exhaustive.": "Its internal operating rules, such as the appointment process for management board members, their \nroles and powers, among other details. This list is not exhaustive.",
    "How the entity is funded": "How the entity is funded",
    "Appointment/Removal/Changes on Board of Management Members:": "Appointment/Removal/Changes on Board of Management Members:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are \ndocumented through board of management meeting minutes, which are registered and certified to reflect the updated \nlist of members, such as the president, vice-president, treasurer, and general secretary.": "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are \ndocumented through board of management meeting minutes, which are registered and certified to reflect the updated \nlist of members, such as the president, vice-president, treasurer, and general secretary.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the \nArticles of Association, if available.": "The latest board management meeting minutes, including the current list of members, will be required in addition to the \nArticles of Association, if available.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there \nare no board meeting minutes available, this list must be provided. It should be dated and signed by the most recently \nappointed legal representative, whose appointment can be verified through related documentation.": "Note: If the current list of board management members is not included in the latest Articles of Association and there \nare no board meeting minutes available, this list must be provided. It should be dated and signed by the most recently \nappointed legal representative, whose appointment can be verified through related documentation.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "This document demonstrates an individual's authority to represent the entity. This document could be:",
    "The latest Articles of Association": "The latest Articles of Association",
    "The current list of board management members \n(which may be included in the board management meeting minutes)": "The current list of board management members \n(which may be included in the board management meeting minutes)",
    "The document should specify:": "The document should specify:",
    "Full name of the individual(s)": "Full name of the individual(s)",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to \nensure that the individual's function permits them to bind the entity. This is particularly important when the \napplicant is not the president, and other functions have limited representation authority as stated in \nthe Articles of Association.": "Function of the individual(s). This information will be cross-verified with the Articles of Association to \nensure that the individual's function permits them to bind the entity. This is particularly important when the \napplicant is not the president, and other functions have limited representation authority as stated in \nthe Articles of Association.",
    "Additional information about the legal representative that will be collected includes:": "Additional information about the legal representative that will be collected includes:",
    "Date and place of birth": "Date and place of birth",
    "Residential address (at least the country of residence should be stated)": "Residential address (at least the country of residence should be stated)",
    "Nationality": "Nationality",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Ownership Structure & Ultimate Beneficial Owners (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board \nmembers will be identified and treated as such for screening purposes, ID collection and verification, etc.": "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board \nmembers will be identified and treated as such for screening purposes, ID collection and verification, etc.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, \nthis detail will be mentioned in the Articles of Association. These individuals will be easily identified and treated as such, \nalongside management board members.": "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, \nthis detail will be mentioned in the Articles of Association. These individuals will be easily identified and treated as such, \nalongside management board members.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Sole Trader's Registry Extract (Entity's Registration/Creation Document)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.",
    "Where applicable, the document should not be older than 3 months.": "Where applicable, the document should not be older than 3 months.",
    "The document must contain:": "The document must contain:",
    "Individual's name": "Individual's name",
    "Residential address": "Residential address",
    "Registration number": "Registration number",
    "The sole trader is the only individual subject to the identification document collection process.": "The sole trader is the only individual subject to the identification document collection process.",
    "No Transactions": "No Transactions",
    "No Pending Items": "No Pending Items",
    "No Beneficiaries": "No Beneficiaries",
    "Send Money To": "Send Money To",
    "Confirmation": "Confirmation",
    "You are setting": "You are setting",
    "as default.": "as default.",
    "Previously": "Previously",
    "was default QR.": "was default QR.",
    "Category": "Category",
    "-Select-From-The-List-": "-Select-From-The-List-",
    "Add a note": "Add a note",
    "Categories Added Successfully!": "Categories Added Successfully!",
    "Subscription starting date": "Subscription starting date",
    "Until": "Until",
    "Next Transaction": "Next Transaction",
    "Transaction Method": "Transaction Method",
    "Accept the quotation": "Accept the quotation",
    "Choose Currency": "Choose Currency",
    "Accept": "Accept",
    "Bargain the quotation": "Bargain the quotation",
    "Enter Counter Amount:": "Enter Counter Amount:",
    "Bargain": "Bargain",
    "Decline the quotation": "Decline the quotation",
    "Are you sure, you want to decline this quotation?": "Are you sure, you want to decline this quotation?",
    "Decline": "Decline",
    "Personal Account": "Personal Account",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Apr",
    "May": "May",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Aug",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dec",
    "Today": "Today",
    "Spent In": "Spent In",
    "Revised Amount": "Revised Amount",
    "You spent more comparing to last month": "You spent more comparing to last month",
    "Last": "Last",
    "days": "Days",
    "Year": "Years",
    "Instant Mobile Top-up Across 150+ Countries": "Instant Mobile Top-up Across 150+ Countries",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.",
    "How It Works - Step-by-Step Guide:": "How It Works - Step-by-Step Guide:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.",
    "Benefits:": "Benefits:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Global Reach: Effortlessly send airtime to over 150 countries.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Complete Security: Enjoy peace of mind with our secure payment system.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!",
    "Pick a language": "Pick a language",
    "French": "French",
    "Spanish": "Spanish",
    "Portuguese": "Portuguese",
    "German": "German",
    "Ukrainian": "Ukrainian",
    "Italian": "Italian",
    "Russian": "Russian",
    "Arabic": "Arabic",
    "Polish": "Polish",
    "Dutch (Nederlands)": "Dutch (Nederlands)",
    "Yoruba": "Yoruba",
    "Indonesian": "Indonesian",
    "Turkish": "Turkish",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chinese",
    "Select Your Preferred Language": "Select Your Preferred Language",
    "Please select the language you would \nlike to use for the eKYC process.": "Please select the language you would \nlike to use for the eKYC process.",
    "Select Your Nationality": "Select Your Nationality",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Choose your nationality to see the \nsupported ID documents for  your country.",
    "Grant permission to use the camera.": "Grant permission to use the camera.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.",
    "Upload Your Documents from \nGallery/Photos": "Upload Your Documents from \nGallery/Photos",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.",
    "Upload Your ID.": "Upload Your ID.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Make sure your ID is clear and not blurry to \nensure quick verification.",
    "Valid Proof of Address": "Valid Proof of Address",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ",
    "Personal Transactions": "Personal Transactions",
    "Business Transactions": "Business Transactions",
    "Shopping & Purchases": "Shopping & Purchases",
    "Bills & Utilities": "Bills & Utilities",
    "Entertainment & Leisure": "Entertainment & Leisure",
    "Investments & Savings": "Investments & Savings",
    "Health & Wellness": "Health & Wellness",
    "Transportation": "Transportation",
    "Education": "Education",
    "Miscellaneous": "Miscellaneous",
    "Family Support": "Family Support",
    "WALLET": "WALLET",
    "Add a currency": "Add a currency",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.",

    "balance amount ": "balance amount ",
    "referral ": "referral ",
    "crypto wallet balance": "crypto wallet balance",
    "money in": "money in",
    "money out": "money out",
    "analytics": "analytics",
    "see more": "see more",
    "manage my Wallets and see the transaction details": "manage my Wallets and see the transaction details",
    "set as default Wallet": "set as default Wallet",
    "add currency": "add currency",
    "convert funds ": "convert funds ",
    "withdraw balance": "withdraw balance",
    "chart names": "chart names",
    "withdraw balance from PKR wallet": "withdraw balance from PKR wallet",
    "select a payout channel": "select a payout channel",
    "you have entered an amount below the minimum payment range": "you have entered an amount below the minimum payment range",
    "change": "change",
    "edit": "edit",
    "moving to next step in ...": "moving to next step in ...",
    "you are withdrawing ...": "you are withdrawing ...",
    "where is my payment": "where is my payment",
    "download": "download",
    "IBAN or swift/BIC code": "IBAN or swift/BIC code",
    "swift code": "swift code",
    "account number": "account number",
    "branch street": "branch street",
    "city": "city",
    "province": "province",
    "postal code": "postal code",
    "all payout channels": "all payout channels",
    "bussiness beneficiary": "bussiness beneficiary",
    "no quotation": "no quotation",
    "no pending items": "no pending items",
    "QR status": "QR status",
    "Portfolio": "Portfolio",
    "edit whole portfolio page": "edit whole portfolio page",
    "personal account": "personal account",
    "Chart names": "Chart names",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "more",
    "not connected": "not connected",
    "code": "code",
    "ratings": "ratings",
    "current password": "current password",
    "include at least one uppercase and lowercase": "include at least one uppercase and lowercase",
    "my social network accounts": "my social network accounts",
    "SMS ": "SMS ",
    "view identity verfication guide": "view identity verfication guide",
    "boimetric face recognition": "boimetric face recognition",
    "please select a payer (withdrawl options)": "please select a payer (withdrawl options)",
    "IBAN number ": "IBAN number ",
    "account holder name": "account holder name",
    "download data": "download data",
    "bank transfer": "bank transfer",
    "mobile wallet": "mobile wallet",
    "all bank names": "all bank names",
    "pay via card": "pay via card",
    "you have entered an amount that is below the payer range": "you have entered an amount that is below the payer range",
    "trouble sending money": "trouble sending money",
    "please follow these example": "please follow these example",
    "wallet ID ": "wallet ID ",
    "username": "username",
    "email": "email",
    "phone number": "phone number",
    "no user found": "no user found",
    "search": "search",
    "scan/upload QR code of the recipient": "scan/upload QR code of the recipient",
    "invite them": "invite them",
    "trouble quoting money": "trouble quoting money",
    "select a country": "select a country",
    "airtime details": "airtime details",
    "enter the phone number": "enter the phone number",
    "service type": "service type",
    "select a service": "select a service",
    "service list": "service list",
    "amount entered is less than the ....": "amount entered is less than the ....",
    "total": "total",
    "total credit the receiver gets": "total credit the receiver gets",
    "take ": "take ",
    "OTP has been verified , You'll be redirected......": "OTP has been verified , You'll be redirected......",
    "Link Your Instagram Account to InstaPay": "Link Your Instagram Account to InstaPay",
    "Step 01 – From Instagram App": "Step 01 – From Instagram App",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Step 02 – From InstaPay Instagram Profile Page ",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.",
    "Step 03 – From InstaPay Instagram Profile Page": "Step 03 – From InstaPay Instagram Profile Page",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.",
    "Step 04 – From InstaPay Instagram Profile Page": "Step 04 – From InstaPay Instagram Profile Page",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.",
    "Additional Tips": "Additional Tips",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.",
    "Future Enhancements": "Future Enhancements",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!",
    "Personal Transactions": "Personal Transactions",
    "Business Transactions": "Business Transactions",
    "Shopping & Purchases": "Shopping & Purchases",
    "Bills & Utilities": "Bills & Utilities",
    "Entertainment & Leisure": "Entertainment & Leisure",
    "Investments & Savings": "Investments & Savings",
    "Health & Wellness": "Health & Wellness",
    "Transportation": "Transportation",
    "Education": "Education",
    "Miscellaneous": "Miscellaneous",
    "Family Support": "Family Support",
    "Web - login with phone number": "Web - login with phone number",
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Start Your InstaPay Journey Today!",
    "Enter your phone number": "Enter your phone number",
    "Login with email": "Login with email",
    "Don't have an account?": "Don't have an account?",
    "Sign Up": "Sign Up",
    "Continue": "Continue",
    "We recommend you download and install the Google Authenticator app": "We recommend you download and install the Google Authenticator app",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Two-factor authentication (2FA) is the best way to protect yourself online.",
    "Strong security with Google Authenticator": "Strong security with Google Authenticator",
    "Enter your password": "Enter your password",
    "Web Mobile": "Web Mobile",
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.",
    "Web - Google Authenticator": "Web - Google Authenticator",
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Two Factor Authentication Code",
    "Problem with the token?": "Problem with the token?",
    "Verify using SMS": "Verify using SMS",
    "Verify": "Verify",
    "Web Mobile - Google Authenticator": "Web Mobile - Google Authenticator",
    "SMS - Web ": "SMS - Web ",
    "SMS Verification": "SMS Verification",
    "Enter the SMS code sent to +41********4053": "Enter the SMS code sent to +41********4053",
    "Verify using Authenticator App": "Verify using Authenticator App",
    "Resend code in 120 seconds": "Resend code in 120 seconds",
    "Verify ": "Verify ",
    "SMS - Web Mobile": "SMS - Web Mobile",
    "My own account": "My own account",
    "Friends & Family": "Friends & Family",
    "Aunt": "Aunt",
    "Brother-in-law": "Brother-in-law",
    "Cousin": "Cousin",
    "Daughter": "Daughter",
    "Father": "Father",
    "Father-in-law": "Father-in-law",
    "Friend": "Friend",
    "Grandfather": "Grandfather",
    "Grandmother": "Grandmother",
    "Husband": "Husband",
    "Mother": "Mother",
    "Mother-in-law": "Mother-in-law",
    "Nephew": "Nephew",
    "Niece": "Niece",
    "Self (i.e. the sender, himself)": "Self (i.e. the sender, himself)",
    "Sister": "Sister",
    "Sister-in-law": "Sister-in-law",
    "Son": "Son",
    "Uncle": "Uncle",
    "Wife": "Wife",
    "Others not listed": "Others not listed",
    "Merchant": "Merchant",
    "No relationship": "No relationship",
    "Add Funds": "Add Funds",
    "Enter the Amount": "Enter the Amount",
    "Fees": "Fees",
    "You Will Get": "You Will Get",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation",
    "Next": "Next",
    "Upgrade Identity Verification": "Upgrade Identity Verification",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.",
    "You’ve been logged out.": "You’ve been logged out.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.",
    "LOGIN AGAIN": "LOGIN AGAIN",
    "Brother": "Brother",
    "Current Password": "Current Password",
    "The new password cannot be the same as the old one": "The new password cannot be the same as the old one",
    "Minimum password length: [X] characters ": "Minimum password length: [X] characters ",
    "Include at least one uppercase and one lowercase letter.": "Include at least one uppercase and one lowercase letter.",
    "Nominee Information": "Nominee Information",
    "Verification Code Preferences": "Verification Code Preferences",
    "How Others Can Find You": "How Others Can Find You",
    "My Social Network Accounts": "My Social Network Accounts",
    "Login Activity": "Login Activity",
    "Receiving Accounts": "Receiving Accounts",
    "Change Your Password To Continue": "Change Your Password To Continue",
    "Continue with": "Continue with",
    "Back to Login": "Back to Login",
    "Send OTP": "Send OTP",
    "A Verification code has been sent to your email and number.": "A Verification code has been sent to your email and number.",
    "Resend available in": "Resend available in",
    "Create a strong Password": "Create a strong Password",
    "Password must:": "Password must:",
    "Be between 9 and 64 characters": "Be between 9 and 64 characters",
    "Include at least two of the following:": "Include at least two of the following:",
    "Lowercase Character": "Lowercase Character",
    "Special Character": "Special Character",
    "Password Updated Successfully!": "Password Updated Successfully!",
    "Click below to login": "Click below to login",

    "Send Money": "Send Money",
    "Enter the amount": "Enter the amount",
    "Recent Transfer": "Recent Transfer",
    "See all": "See all",
    "Send again": "Send again",
    "You Send": "You Send",
    "They Receive": "They Receive",
    "Receive Method": "Receive Method",
    "Select method": "Select method",
    "Next": "Next",
    "Trouble sending money?": "Trouble sending money?",
    "Where do you want to send money ?": "Where do you want to send money ?",
    "Search ": "Search ",
    "Confirm": "Confirm",
    "Bank Deposit": "Bank Deposit",
    "Cash Pick-up": "Cash Pick-up",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Bank Card",
    "Crypto Wallet": "Crypto Wallet",
    "What is the receiver’s Mobile Wallet?": "What is the receiver’s Mobile Wallet?",
    "Transfer fees": "Transfer fees",
    "Total to pay": "Total to pay",
    "Choose the beneficiary!": "Choose the beneficiary!",
    "Country": "Country",
    "Receive method": "Receive method",
    "You send": "You send",
    "change": "change",
    "edit": "edit",
    "add new": "add new",
    "Beneficiaries": "Beneficiaries",
    "Manage Beneficiaries": "Manage Beneficiaries",
    "Search": "Search",
    "CASH PICK-UP": "CASH PICK-UP",
    "Where will your receiver collect the money": "Where will your receiver collect the money",
    "Cash is available to collect within minutes from any branch of [name] ": "Cash is available to collect within minutes from any branch of [name] ",
    "BANK ACCOUNT": "BANK ACCOUNT",
    "Transfer to recipient’s bank account": "Transfer to recipient’s bank account",
    "BANK CARD": "BANK CARD",
    "Fast transfer directly to recipient’s bank card": "Fast transfer directly to recipient’s bank card",
    "CRYPTO WALLET": "CRYPTO WALLET",
    "Fast transfer directly to a crypto wallet": "Fast transfer directly to a crypto wallet",
    "Review transfer": "Review transfer",
    "Receiver's Details": "Receiver's Details",
    "Edit": "Edit",
    "Name": "Name",
    "Reason for sending": "Reason for sending",
    "Sending": "Sending",
    "Receiver gets": "Receiver gets",
    "Exchange rate": "Exchange rate",
    "Estimated time": "Estimated time",
    "Select Payment Type": "Select Payment Type",
    "Credit cards": "Credit cards",
    "Domestic cards": "Domestic cards",
    "Mobile Wallets": "Mobile Wallets",
    "InstaPay Wallet": "InstaPay Wallet",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "CREDIT CARD",
    "Credit Card": "Credit Card",
    "Select card": "Select card",
    "Card Number": "Card Number",
    "Enter the 16-digit card number on the card": "Enter the 16-digit card number on the card",
    "Expiry Date": "Expiry Date",
    "Enter the expiration date of the card": "Enter the expiration date of the card",
    "CVV Number": "CVV Number",
    "Enter the 3 or 4 digit number on the card": "Enter the 3 or 4 digit number on the card",
    "INSTAPAY WALLET": "INSTAPAY WALLET",
    "Select Wallet": "Select Wallet",
    "My Wallet": "My Wallet",
    "Conversion": "Conversion",
    "Convert": "Convert",
    "Your payment is confirmed": "Your payment is confirmed",
    "Thanks for using InstaPay": "Thanks for using InstaPay",
    "Status": "Status",
    "Share": "Share",
    "Transfer created": "Transfer created",
    "Payment received": "Payment received",
    "Payment processed": "Payment processed",
    "Transfer successful": "Transfer successful",
    "It may take few hours for the funds to appear in [user name] account": "It may take few hours for the funds to appear in [user name] account",
    "Transfer failed": "Transfer failed",
    "Transaction Details": "Transaction Details",
    "Need help?": "Need help?",
    "Service Details": "Service Details",
    "Funds Deposit Speed": "Funds Deposit Speed",
    "Cut-off Time": "Cut-off Time",
    "Bank holiday list": "Bank holiday list",
    "Threshold limits": "Threshold limits",
    "null": null,
    "Instant, Real Time": "Instant, Real Time",
    "Same day, T+1": "Same day, T+1",
    "Non-instant, T+1": "Non-instant, T+1",
    "Instant Payment, Real Time": "Instant Payment, Real Time",
    "Transaction processed before 3:00 PM Local time": "Transaction processed before 3:00 PM Local time",
    "Delivered the same day": "Delivered the same day",
    "Transactions processed post 3:00 PM": "Transactions processed post 3:00 PM",
    "T+1 business days": "T+1 business days",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Payments made on weekdays after 15:00 will be credited by midnight the following day.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09hrs - 18 hrs Local Time",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries ",
    "Min per transaction limit:": "Min per transaction limit:",
    "Maximum per transaction limit:": "Maximum per transaction limit:",
    "Daily threshold limit:": "Daily threshold limit:",
    "Weekly threshold limit:": "Weekly threshold limit:",
    "Monthly threshold limit:": "Monthly threshold limit:",
    "Yearly threshold limit:": "Yearly threshold limit:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Basic Account (Unverified)",
    "Identity Verified": "Identity Verified"

}