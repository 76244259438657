import React, { useMemo } from 'react'
import { CheckCircle } from 'react-feather'
import { Link, useParams } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import errors from "../TrustPaymentErrors.json"

const Success = () => {
    const { code } = useParams()

    const error_message = () => {
        return errors.find(item => item['Error code'] == code)?.Message ?? "Transaction Successful!"
    }
    return (
        <Modal isOpen={true}>
            <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <CheckCircle color='green' size={90} />
                <h4 style={{ color: "green", margin: "10px 0 20px", fontSize: "1.25rem" }}>Funds Added Successfully!</h4>
                <p style={{ color: "#a8a8a8" }}>{error_message} <Link to={"/wallets"} style={{ color: "#5926f0" }}>Go To My Wallets.</Link></p>
            </ModalBody>
        </Modal>
    )
}

export default Success