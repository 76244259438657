export const it = {
    "How Others Can Find You": "Come gli altri possono trovarti",
    "Schedules": "Orari",
    "Login": "Accedi",
    "Start Your InstaPay Journey Today!": "Inizia oggi il tuo viaggio con InstaPay!",
    "Phone Number or Email": "Numero di telefono o email",
    "Login Using Social Media": "Accedi tramite Social Media",
    "Continue": "Continua",
    "Don't Have an Account? Sign Up": "Non hai un account? Iscriviti",
    "Sign Up": "Iscriviti",
    "Edit": "Modifica",
    "Enter Your Password": "Inserisci la tua password",
    "Forgot Password?": "Hai dimenticato la password?",
    "Account Type": "Tipo di account",
    "Individual": "Individuale",
    "Business": "Business",
    "Already Have an Account? Sign In": "Hai già un account? Accedi",
    "Sing In": "Accedi",
    "Enter Your Personal Details": "Inserisci i tuoi dati personali",
    "Add Photo or Logo": "Aggiungi una foto o un logo",
    "Enter Your Name": "Inserisci il tuo nome",
    "Back": "Indietro",
    "Phone Number": "Numero di telefono",
    "Email Address": "Indirizzo Email",
    "Create a Strong Password": "Crea una password forte",
    "Password Requirements": "Requisiti Password",
    "Must be between 9 and 64 characters": "Deve essere tra 9 e 64 caratteri",
    "Include at least two of the following:": "Includere almeno due dei seguenti:",
    "Uppercase character": "Carattere maiuscolo",
    "Lowercase character": "Carattere minuscolo",
    "Number": "Numero",
    "Special character": "Carattere speciale",
    "Confirm Password": "Conferma Password",
    "Finalize Your Process": "Finalizza il processo",
    "Referral Code (Optional)": "Codice di riferimento (opzionale)",
    "It will autofill if you're signing up with an invitation link.": "Si autocompilerà se ti iscrivi con un link di invito.",
    "I agree with": "Sono d'accordo con",
    "Terms & Conditions": "Termini & Condizioni",
    "Privacy Policy": "Politica sulla privacy",
    "Create Your Account": "Crea il tuo account",
    "Enter Code": "Inserisci il codice",
    "A one-time verification code has been sent to your email address  and phone number.": "Un codice di verifica una tantum è stato inviato al vostro indirizzo e-mail e al numero di telefono.",
    "Resend available in ": "Rinvio disponibile in",
    "seconds": "secondi",
    "Verify": "Verifica",
    "Verification Code Verified": "Codice di verifica verificato",
    "Please continue to the next step.": "Si prega di continuare al prossimo passo.",
    "Finish": "Fine",
    "Finishing": "Terminando",
    "Company Name": "Nome dell'azienda",
    "Enter Your Business Details": "Inserisci i dettagli della tua azienda",
    "Country ": "Paese",
    "You cannot change the country once your account is verified.": "Non puoi cambiare il paese una volta che il tuo account è verificato.",
    "Learn more": "Scopri di più",
    "Optional": "Opzionale",
    "Continuing": "Continuando",
    "Didn't receive the verification code?": "Non hai ricevuto il codice di verifica?",
    "Resend Now": "Invia di nuovo ora",
    "Search Here": "Cerca qui",
    "Search for users, services, and more on InstaPay": "Cerca utenti, servizi e altro su InstaPay",
    "Search users here": "Cerca utenti qui",
    "Earn money with our affiliate program!": "Guadagna con il nostro programma di affiliazione!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Genera un reddito passivo condividendo il tuo codice affiliato InstaPay con i tuoi follower. Per ogni transazione che fanno,\nguadagnerai una commissione, massimizzando così i profitti dalla tua influenza online.",
    "Learn More": "Scopri di più",
    "Balance Amount": "Importo del saldo",
    "You spent less compared to last month.": "Hai speso meno rispetto al mese scorso.",
    "Referral Rewards": "Premi di riferimento",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Trasferimento veloce ai tuoi contatti più recenti:",
    "Number of Transactions": "Numero di transazioni",
    "Total Transaction Amount": "Importo totale della transazione",
    "Total Sent": "Totale inviato",
    "Total Received": "Totale ricevuto",
    "Add Funds": "Aggiungi fondi",
    "Send Money": "Invia Denaro",
    "Request Money": "Richiedi Denaro",
    "Send a Quote": "Invia un preventivo",
    "Pay With Crypto": "Paga con Crypto",
    "Payment Insights": "Intuizioni sui pagamenti",
    "Recent Transactions ": "Transazioni recenti",
    "Today": "Oggi",
    "My Wallets": "I miei Wallets",
    "Balance": "Saldo",
    "Wallet ID": "ID del Wallet",
    "Monthly Limit": "Limite mensile",
    "Top Up": "Ricarica",
    "Summary": "Riepilogo",
    "Settings": "Impostazioni",
    "More": "Altro",
    "Upgrade to Business Account": "Passa a un account Business",
    "Conversion": "Conversione",
    "Enter Amount": "Inserisci importo",
    "Exchanged Amount": "Importo convertito",
    "Convert": "Converti",
    "You Are Converting": "Stai convertendo",
    "From": "Al Destinatario",
    "To": "A",
    "Exchange Rate": "Tasso di cambio",
    "Fees": "Tariffe",
    "You Will Receive": "Riceverai",
    "Slide To Confirm": "Scorri per confermare",
    "Verification Code Confirmed - You'll be redirected to the next step": "Codice di verifica confermato - Sarai reindirizzato al prossimo passo",
    "Moving to next step in": "Passando al prossimo passo in",
    "Thanks for using InstaPay": "Grazie per aver usato InstaPay",
    "Your funds have been credited to your wallet.": "I tuoi fondi sono stati accreditati sul tuo Wallet.",
    "Dashboard": "Dashboard",
    "Accounts": "Account",
    "Transactions": "Transazioni",
    "Other Transactions": "Altre transazioni",
    "Payments": "Pagamenti",
    "Beneficiaries": "Beneficiari",
    "Referrals": "Riferimenti",
    "Quotations": "Preventivi",
    "Pending": "In sospeso",
    "My QR Code Sticker": "Il mio adesivo con codice QR",
    "My Portfolio": "Il mio portfolio",
    "My Payment Address": "Il mio indirizzo di pagamento",
    "Analytics": "Analitica",
    "Profile": "Profilo",
    "Dark Mode": "Modalità scura",
    "Support": "Supporto",
    "Logout": "Logout",
    "Powered By": "Powered by",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Una società Fintech svizzera",
    "MAIN MENU": "MENU PRINCIPALE",
    "OTHERS": "ALTRI",
    "Wallet": "Wallet",
    "Default": "Predefinito",
    "Breakdown": "Dettaglio",
    "Credit": "Credito",
    "Debit": "Debito",
    "Wallet Management": "Gestione del Wallet",
    "Top-up Your Wallet": "Ricarica il tuo Wallet",
    "Download Statement": "Scarica estratto conto",
    "Block the Wallet": "Blocca il Wallet",
    "Wallet Status": "Stato del Wallet",
    "-Select-File-Type-": "-Selezione-Tipo-File-",
    "Download Now": "Scarica ora",
    "Downloading": "Scaricando",
    "All": "Tutto",
    "Sent": "Inviato",
    "Received": "Ricevuto",
    "Requested": "Richiesto",
    "Quoted": "Quotato",
    "Search Transactions": "Cerca transazioni",
    "Date & Time": "Data e ora",
    "Type": "Tipo",
    "Transaction ID": "ID transazione",
    "Recipient": "Destinatario",
    "Amount": "Importo",
    "Status": "Stato",
    "Payment Type": "Tipo di pagamento",
    "Sent Payments": "Pagamenti inviati",
    "Received Payments": "Pagamenti ricevuti",
    "Date": "Data",
    "Cycles/Time": "Cicli/Tempo",
    "Payment As": "Pagamento come",
    "Name": "Nome",
    "Cancel": "Annulla",
    "Subscriptions & Scheduled": "Abbonamenti e programmati",
    "Select the option that matches your needs from the list below:": "Seleziona l'opzione che corrisponde alle tue esigenze dall'elenco qui sotto:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Invia facilmente denaro ad amici, famiglia o aziende localmente o internazionalmente. Scegli tra molteplici opzioni di \ntrasferimento, incluse depositi bancari, mobile wallets, ritiro in contanti, carte di pagamento, e trasferimenti da wallet a wallet. \nGoditi la comodità e la sicurezza di Instapay per trasferimenti di denaro fluidi e istantanei.",
    "International Transfer": "Trasferimento Internazionale\n",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Invia denaro oltre confine con facilità. Trasferisci fondi a livello internazionale ad amici, famiglia o aziende in pochi clic. Scegli tra una \nvasta gamma di canali di pagamento inclusi conto bancario, mobile money/mobile wallet, luogo di ritiro contanti, o carta di pagamento\nper transazioni comode e sicure.",
    "Select the country where you want to send the money!": "Seleziona il paese dove vuoi inviare il denaro!",
    "Search Country": "Cerca Paese",
    "Trouble Sending Money?": "Problemi nell'invio di denaro?",
    "Confirm": "Conferma",
    "Select Payout Channel": "Seleziona Canale di Pagamento",
    "Select your most convenient option to send the money.": "Seleziona l'opzione più comoda per inviare il denaro.",
    "Select The Bank": "Seleziona la Banca",
    "Search Bank": "Cerca Banca",
    "Send money directly to a recipient's bank account. Enjoy the convenience \nand security of transferring funds seamlessly to any bank around the world.": "Invia denaro direttamente sul conto bancario del destinatario. Goditi la comodità e la sicurezza nel trasferire fondi senza problemi in \nqualsiasi banca nel mondo.",
    "Bank Transfer": "Trasferimento Bancario",
    "Enter the amount": "Inserisci l'importo",
    "Tax": "Tasse",
    "Amount the recipient will receive": "Quanto riceverà il destinatario",
    "Select Remarks / Reason": "Seleziona Osservazioni / Motivo",
    "Comments": "Commenti",
    "Attach Files": "Allega File",
    "Groups": "Gruppi",
    "Manage Groups": "Gestisci Gruppi",
    "Add New Group": "Aggiungi Nuovo",
    "Invite": "Invita",
    "Manage Beneficiaries": "Gestisci Beneficiari",
    "Recipients List": "I Miei Beneficiari",
    "Search Contacts": "Cerca Contatto",
    "Confirm Your Payment": "Conferma il Tuo Pagamento",
    "Select Country": "Paese",
    "Select the Recipient's Payment Channel": "Canale di Pagamento",
    "Change Selection": "cambia",
    "You Are Transferring ": "Stai trasferendo",
    "Oops! Something Went Wrong.": "Oops! Qualcosa è andato storto.",
    "We're Sorry About That. ": "Ci dispiace per questo",
    "Go back to Payments Page.": "Torna alla pagina dei Pagamenti.",
    "Wallet to Wallet Transfer": "Trasferimento da Wallet a Wallet",
    "Request a customized price quote from service providers or vendors.": "Richiedi un preventivo personalizzato da fornitori o venditori. Ottieni stime di costo accurate e preventivi per i servizi o i prodotti di cui \nhai bisogno.",
    "Enter the Wallet ID": "Inserisci l'ID Wallet",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Inserisci l'ID Wallet / Email / Numero di telefono del destinatario.",
    "Recipient not on InstaPay?": "Il destinatario non è su InstaPay?",
    "Invite Them": "Invitalo",
    "My Beneficiaries": "I Miei Beneficiari",
    "Enter the Amount You Wish to Transfer.": "Inserisci l'importo che desideri trasferire.",
    "Sending to ": "Invio a",
    "You Have Selected Wallet to Wallet Transfer": "Hai Selezionato Trasferimento da Wallet a Wallet",
    "Authenticate Your Payment.": "Autentica il Tuo Pagamento.",
    "You are transferring": "Stai trasferendo",
    "Total ": "Totale",
    "Where Is My Payment?": "Dove è il mio pagamento?",
    "Share": "Condividi",
    "Your Payment Is Confirmed": "Il tuo pagamento è confermato",
    "QR Code Payment": "Pagamento con Codice QR",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Trasferisci denaro istantaneamente ad altri utenti InstaPay con il nostro servizio sicuro da wallet a wallet. Invia e ricevi fondi senza \nproblemi in pochi secondi.",
    "Scan QR with Your Camera or Upload from Your Device.": "scansiona qr con la tua fotocamera",
    "Upload from Your Device.": "Carica dal tuo dispositivo",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Usa l'indirizzo di pagamento unico InstaPay invece del Codice QR:",
    "Enter InstaPay UPA": "Inserisci l'UPA InstaPay",
    "Recipient not on InstaPay? Invite Them": "Il destinatario non è su InstaPay?",
    "Verify QR Code": "Verifica Codice QR",
    "Scan/Upload QR Code of the Recipient": "Scansiona/Carica il Codice QR del destinatario",
    "Payment Confirmed": "Pagamento Confermato",
    "Request customized price quotes from service providers or vendors to receive \naccurate cost estimates for the services or products you need.": "Richiedi un preventivo personalizzato da fornitori o venditori. Ottieni stime di costo accurate e preventivi per i servizi o i prodotti di \ncui hai bisogno.",
    "Choose The Beneficiary or Group": "Scegli il Beneficiario o il Gruppo",
    "Enter the Amount You Wish to Request.": "Inserisci l'importo che desideri richiedere.",
    "You Are Requesting Money": "Stai Richiedendo Denaro",
    "You are requesting": "Stai richiedendo",
    "As": "Come",
    "Conversion of Your Cryptocurrencies": "Invia Crypto",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "La funzione di Conversione da Crypto a Fiat pone l'accento su un'esperienza incentrata sull'utente, sicura ed efficiente. Gli utenti \npossono comodamente selezionare la loro criptovaluta, inserire l'importo del trasferimento, nominare un canale di pagamento, e \nconvalidare la transazione.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Scegli la Cripto che intendi liquidare!",
    "Next": "Avanti",
    "Enter the Crypto Wallet Address of the Recipient.": "Inserisci l'indirizzo del Wallet Crypto del destinatario.",
    "Minimum Transactional Value: $100.": "Il valore minimo della transazione è di $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Il prezzo delle cripto cambia frequentemente in base alle condizioni di mercato. Si prega di fare riferimento al prezzo sulla pagina di \nconferma dell'ordine come preventivo finale.",
    "Select Network": "Seleziona Rete",
    "Send A Quote": "Invia un Preventivo",
    "Send a detailed price quote to your clients or customers. Present your services, products, \nor offers professionally with a personalized quotation.": "Invia un preventivo dettagliato ai tuoi clienti o consumatori. Presenta i tuoi servizi, prodotti o offerte in modo professionale con un \npreventivo personalizzato.",
    "Choose the Beneficiary": "Scegli il Beneficiario",
    "Allow Receiver to Bargain": "Permetti al ricevente di contrattare",
    "Enter the Title for Your Quotation.": "Inserisci il titolo per il Preventivo",
    "Enter the Description for Your Quotation": "Inserisci la descrizione per il Preventivo",
    "You are Quoting": "Stai quotando",
    "Title": "Titolo",
    "Description": "Descrizione",
    "Attachments": "Allegati",
    "Confirm Your Quotation": "Conferma il Tuo Preventivo",
    "Verify The Quotation": "Verifica il Preventivo",
    "Your quotation is confirmed": "Il tuo preventivo è confermato",
    "Request A Quote": "Richiedi un Preventivo",
    "Coming Soon": "Prossimamente",
    "Add Beneficiary": "Aggiungi Beneficiario",
    "Search for Beneficiaries": "Cerca tra i Beneficiari",
    "Connected Accounts": "Account Collegati",
    "Details": "Dettagli",
    "First Name": "Nome",
    "Last Name": "Cognome",
    "Country": "Paese",
    "City": "Città",
    "Address Line": "Indirizzo",
    "Mobile Number": "Numero di Telefono",
    "Relationship With Beneficiary": "Relazione con il Beneficiario",
    "Bank Account": "Conto Bancario",
    "Mobile Money/Mobile Wallet": "Mobile Money/Mobile Wallet",
    "Payment Card": "Carta di Pagamento",
    "Crypto Wallet": "Wallet Crypto",
    "InstaPay Account": "Account InstaPay",
    "Cash Pickup": "Ritiro Contanti",
    "IBAN Or Swift/BIC code": "Codice IBAN o Swift/BIC",
    "Swift Code": "Codice Swift",
    "Account Number": "Numero di Conto",
    "Bank Name": "Nome della Banca",
    "Branch Name": "Nome della Filiale",
    "Branch Street": "Indirizzo della Filiale",
    "Province": "Provincia",
    "Postal Code": "Codice Postale",
    "Mobile Money Provider": "Fornitore di Mobile Money",
    "Wallet Name": "Nome del Wallet",
    "Wallet Number": "Numero del Wallet",
    "Card Holder Name": "Nome del Titolare della Carta",
    "Card Number": "Numero della Carta",
    "Expiry Date": "Data di Scadenza",
    "Crypto Currency": "Criptovaluta",
    "Wallet Address": "Indirizzo del Wallet",
    "Wallet Holder Name": "Nome del Titolare del Wallet",
    "Wallet Currency": "Valuta del Wallet",
    "Select Document Type": "Seleziona Tipo di Documento",
    "Enter Document Number": "Inserisci Numero Documento",
    "Add Individual Account": "Aggiungi Account Individuale",
    "Add Business Account": "Aggiungi Account Aziendale",
    "Company Address": "Indirizzo Aziendale",
    "Company Email": "Email Aziendale",
    "Company Phone No": "Numero di Telefono Aziendale",
    "Total Referrals": "Referral Totali",
    "My Earnings": "Le Mie Entrate",
    "Your Referral ID": "Il Tuo ID di Referral",
    "Share Your Unique Referral Link": "Condividi il Tuo Link di Referral Unico di InstaPay",
    "How do I Refer a Friend to InstaPay?": "Come Posso Riferire un Amico a InstaPay?",
    "Step 01": "Passo 01",
    "Share your InstaPay unique referral link with your friends.": "Condividi il tuo link di referral unico di InstaPay con i tuoi amici.",
    "Step 02": "Passo 02",
    "Earn rewards when they transact $100 or more": "Guadagna ricompense quando effettuano transazioni di $100 o più.",
    "Step 03": "Passo 03",
    "Earn up to $0.06 for every transaction your followers make.": "Guadagna fino a $0.06 per ogni transazione effettuata dai tuoi follower.",
    "My Referrals": "I Miei Referral",
    "Referral Complete": "Referral Completato",
    "Received Quotations": "Preventivi Ricevuti",
    "Sent Quotations": "Preventivi Inviati",
    "Search Pending Items": "Cerca Elementi in Sospeso",
    "Actions": "Azioni",
    "Received Requests": "Richieste Ricevute",
    "Sent Requests": "Richieste Inviati",
    "No Actions": "Nessuna Azione",
    "You Requested": "Hai Richiesto",
    "Via": "Tramite",
    "How was your experience?": "Come è stata la tua esperienza?",
    "Leave a Review": "Lascia una Recensione",
    "Overall Ratings": "Valutazioni Generali",
    "Visit Profile": "Visita il Profilo",
    "Ratings": "Valutazioni",
    "Reviews": "Recensioni",
    "No reviews available": "Nessuna recensione disponibile",
    "Select Your Currency": "Seleziona la Tua Valuta",
    "Proceed": "Procedi",
    "Commision": "Commissione",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Sblocca Transazioni Senza Sforzo con lo Sticker del Codice QR di InstaPay",
    "Effortless and Swift:": "Senza Sforzo e Veloce:",
    "Instantly download your unique InstaPay QR code sticker.": "Scarica immediatamente il tuo sticker unico del codice QR di InstaPay.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Esperienza di pagamenti senza contanti e senza problemi, senza bisogno di infrastrutture complesse come terminali di pagamento.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Raccogli pagamenti velocemente - niente più noiose digitazioni di numeri di carte di credito o telefoni.",
    "Each payment includes customer information for your convenience.": "Ogni pagamento include informazioni sul cliente per la tua comodità.",
    "Economically Smart:": "Economicamente Intelligente:",
    "Zero activation fees – start immediately without upfront costs.": "Zero tariffe di attivazione - inizia immediatamente senza costi iniziali.",
    "Forget about fixed monthly charges; pay as you transact.": "Dimentica le spese mensili fisse; paga mentre transazioni.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Lascia l'hardware; nessun costo del terminale significa più risparmi per te.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Goditi basse tariffe di transazione di soli 0.75% per pagamento con codice QR.",
    "We don't impose minimum commissions; you save more with each transaction.": "Non imponiamo commissioni minime; risparmi di più con ogni transazione.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Vivi la gioia dei pagamenti istantanei, migliorando il tuo flusso di cassa.",
    "Make Every Transaction Count with InstaPay": "Rendi Ogni Transazione Importante con InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Scarica QR",
    "QR Code Status": "Stato del Codice QR",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Condividi il tuo Codice QR o l'indirizzo di pagamento per ricevere pagamenti istantanei nel tuo wallet InstaPay.",
    "QR Code Title": "Titolo del Codice QR",
    "View Transactions": "Visualizza Transazioni",
    "My QR Codes:": "I Miei Codici QR:",
    "Load More": "Carica Altri\n",
    "Benefits Of Portfolio": "Vantaggi del Portfolio",
    "Benefits:": "Benefici:",
    "Enhanced Visibility:": "Maggiore Visibilità:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible \ndirectly from your Payment Address.": "Enfatizza il tuo profilo pubblico. Il tuo portfolio agisce come una vetrina dinamica, accessibile direttamente dal tuo Indirizzo di Pagamento.",
    "Engage Your Audience:": "Coinvolgi il Tuo Pubblico:",
    "Connect with visitors through your creative or professional journey. Let your work \nspeak for you, creating a deeper engagement with potential clients or supporters.": "Connettiti con i visitatori attraverso il tuo viaggio creativo o professionale. Lascia che il tuo lavoro parli per te, creando un coinvolgimento più \nprofondo con potenziali clienti o sostenitori.",
    "Integrated Social Proof:": "Prova Sociale Integrata:\n",
    "Display your social media influence. Visitors can see your social media reach, \nadding credibility and context to your portfolio.": "Mostra la tua influenza sui social media. I visitatori possono vedere la tua portata sui social media, aggiungendo credibilità e contesto al \ntuo portfolio.",
    "Seamless Transactions:": "Transazioni Senza Intoppi:\n",
    "Enable visitors to request or initiate payments right from your public page. Your \nportfolio not only showcases your work but also facilitates easy financial interactions.": "Permetti ai visitatori di richiedere o avviare pagamenti direttamente dalla tua pagina pubblica. Il tuo portfolio non solo mostra il tuo lavoro, \nma facilita anche le interazioni finanziarie.",
    "Personalized Storytelling:": "Racconto Personalizzato:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a\n personal connection with your audience.": "Utilizza la sezione 'About Me' per condividere la tua storia, missione o visione, creando una connessione personale con il tuo pubblico.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your \naudience, and streamline your financial interactions on InstaPay.": "Il Mio Portfolio' è più di una semplice galleria; è uno strumento per costruire il tuo marchio, coinvolgere il tuo pubblico e semplificare le tue \ninterazioni finanziarie su InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Mostra il Tuo Lavoro, Amplifica la Tua Portata. 'Il Mio Portfolio' è la tua tela digitale su InstaPay. Carica e mostra una gamma diversificata \ndi contenuti - da immagini affascinanti e video a PDF informativi. Che tu sia un artista, un libero professionista o un imprenditore, questa \nfunzionalità ti permette di presentare il tuo lavoro o progetti in modo visivamente coinvolgente.",
    "Add/Manage Portfolio": "Aggiungi/Gestisci Portfolio",
    "Add New": "Aggiungi Nuovo",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Massimizza i Tuoi Guadagni sui Social Media con il Tuo Indirizzo di Pagamento InstaPay",
    "Make It Effortless for Your Audience to Support You:": "Rendi Semplice per il Tuo Pubblico Sostenerti:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Integra facilmente il tuo Indirizzo di Pagamento InstaPay nei tuoi post sui social media.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Copia e incolla facilmente il link del tuo indirizzo di pagamento in ciascuno dei tuoi post, storie o in modo prominente sul tuo profilo.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideale per influencer, artisti, imprenditori o chiunque abbia una comunità desiderosa di mostrare il proprio sostegno.",
    "Instant Support, Endless Possibilities:": "Supporto Istantaneo, Possibilità Infinite:",
    "Transform the way you monetize your social media content.": "Trasforma il modo in cui monetizzi i tuoi contenuti sui social media.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Permetti ai tuoi follower di inviarti fondi istantaneamente con un solo clic, direttamente dai loro social network preferiti.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideale per il supporto in tempo reale durante le dirette, come sistema di mance per i tuoi contenuti creativi o per finanziare il tuo prossimo \ngrande progetto.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Dove Collocare il Tuo Indirizzo di Pagamento InstaPay per Massimizzare l'Impatto:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Profili Social Media: Fissalo nella tua bio o nella sezione about per una visibilità costante.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Post & Annunci: Incorporalo nei tuoi post individuali, specialmente quelli che evidenziano il tuo lavoro o specifici richiami al supporto.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Storie & Reels: Integralo nelle tue storie interattive o nei reels accattivanti per catturare contributi istantanei.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Live Streams: Condividilo durante le tue sessioni LIVE, rendendo facile per i telespettatori contribuire in tempo reale.",
    "Simple, Fast, and Secure:": "Semplice, Veloce e Sicuro:",
    "No complex setup – get started in moments.": "Nessuna configurazione complessa - inizia in pochi momenti.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Goditi la sicurezza e la velocità del processamento dei pagamenti affidabile di InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Ricevi pagamenti da chiunque, ovunque, senza che abbiano bisogno di un account InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Eleva il Tuo Gioco sui Social Media con l'Indirizzo di Pagamento InstaPay.",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Indirizzo di Pagamento Virtuale)",
    "Payment Address Title": "Titolo dell'Indirizzo di Pagamento",
    "Currency": "Valuta",
    "Payment Address Description": "Descrizione dell'Indirizzo di Pagamento",
    "Update": "Aggiorna",
    "Updating": "Aggiornamento",
    "Total Transaction": "Transazione Totale",
    "Last 30 days": "Ultimi 30 giorni",
    "Last 1 month": "Ultimo mese",
    "Last 1 year": "Ultimo anno",
    "Custom Date": "Data Personalizzata",
    "Payment Methods": "Metodi di Pagamento",
    "Payment Types": "Tipi di Pagamento",
    "Requested Amount": "Importo Richiesto",
    "Quoted Amount": "Importo Preventivato",
    "About Me": "Riguardo a Me",
    "Followers": "Follower",
    "InstaPay ID": "ID InstaPay",
    "Download QR Code": "Scarica il Codice QR",
    "Initiate A Payment": "Inizia un Pagamento",
    "Report this person": "Segnala questa persona",
    "Add now": "Aggiungi ora",
    "Recent Reviews": "Recensioni Recenti",
    "Reviews As Seller": "Recensioni Come Venditore",
    "Reviews As Buyer": "Recensioni Come Acquirente",
    "Select Language": "Seleziona Lingua",
    "Profile Completion": "Completamento del Profilo",
    "Profile Completed": "Profilo Completato",
    "Basic Info": "Informazioni di Base",
    "Username": "Nome Utente",
    "Gender": "Genere",
    "Male": "Maschio",
    "Female": "Femmina",
    "-Select-Gender-": "-Selezione Genere-",
    "Date Of Birth": "Data di Nascita",
    "Address": "Indirizzo",
    "Edit Profile": "Modifica Profilo",
    "Update Profile": "Aggiorna Profilo",
    "Updating Profile": "Aggiornamento Profilo",
    "Complete Now": "Completa Ora",
    "Password": "Password",
    "Change Password": "Cambia Password",
    "New Password": "Nuova Password",
    "Re-enter New Password": "Reinserisci Nuova Password",
    "Please follow this guide for a strong password": "Segui questa guida per una password forte",
    "Include at least one special character.": "Includi almeno un carattere speciale.",
    "Minimum of 8 characters.": "Minimo di 8 caratteri.",
    "Include at least one number": "Includi almeno un numero",
    "Change it often for enhanced security.": "Cambia spesso per una maggiore sicurezza.",
    "Security Questions": "Domande di Sicurezza",
    "Security Question": "Domanda di Sicurezza",
    "Select your question": "Seleziona la tua domanda",
    "Answer To The Question": "Risposta alla Domanda",
    "Nominee": "Candidato",
    "Relationship with Nominee": "Relazione con il Candidato",
    "Nominee Contact Number": "Numero di Contatto del Candidato",
    "Nominee Address": "Indirizzo del Candidato",
    "Delete Nominee": "Elimina Candidato",
    "Update Nominee": "Aggiorna Candidato",
    "Details Updated Successfully!": "Dettagli Aggiornati con Successo!",
    "Success": "Successo",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay \nplatform itself. You acknowledge that no wet signature is required to appoint the nominee, and \nthe online registration will be considered final and binding. In the event of the death of an \nInstaPay Wallet holder, the balance in the Wallet will be transferred to the registered Nominee. \nKEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to \nthe Nominee. The Nominee will provide any documents required by InstaPay, including those \nfor identification and proof of death.": "Puoi nominare un Candidato a tua scelta, purché non sia minorenne, attraverso la piattaforma InstaPay stessa. Riconosci che non è richiesta \nuna firma bagnata per nominare il candidato, e la registrazione online sarà considerata definitiva e vincolante. In caso di decesso di un\ntitolare di Wallet InstaPay, il saldo nel Wallet verrà trasferito al Candidato registrato. KEMIT KINGDOM sarà sollevata da tutte le sue responsabilità \nalla consegna dell'importo al Candidato. Il Candidato fornirà tutti i documenti richiesti da InstaPay, inclusi quelli per l'identificazione e la prova di morte.",
    "Add Nominee": "Aggiungi Candidato",
    "Two Factor Authentication": "Autenticazione a Due Fattori",
    "SMS Number": "Numero SMS",
    "Updated Successfully!": "Aggiornato con Successo!",
    "Social Network Accounts": "Account di Reti Sociali",
    "Here, you can set up and manage your integration settings.": "Qui puoi configurare e gestire le tue impostazioni di integrazione.",
    "Social Network Account": "Account di Rete Sociale",
    "Activate Account": "Attiva Account",
    "Enter the code below in the InstaPay Chatbot.": "Inserisci il codice seguente nel Chatbot di InstaPay.",
    "The code will expire in ": "Il codice scadrà tra",
    "Notifications": "Notifiche",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Personalizza come ricevi le notifiche. Queste impostazioni si applicano alle attività che stai monitorando.",
    "Activity": "Attività",
    "Email": "Email",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Fonte",
    "Payment Requests": "Richieste di pagamento",
    "Bulk Payments": "Pagamenti in blocco",
    "Identity Verification": "Verifica dell'Identità",
    "Verify Your Identity": "Verifica la Tua Identità",
    "Identity Verification Status:": "Stato della Verifica dell'Identità:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Descriviti brevemente e spiega le tue ragioni per usare InstaPay.",
    "Enter Your Message": "Inserisci il Tuo Messaggio",
    "Select Occupation": "Seleziona Professione",
    "It must be a government-issued identification document with a photo. All identification documents \nmust be written in the Latin alphabet. If not, a certified translation is required.": "Deve essere un documento di identificazione rilasciato dal governo con foto. Tutti i documenti di identificazione devono essere scritti in alfabeto latino. \nIn caso contrario, è richiesta una traduzione certificata.",
    "Accepted Documents": "Documenti Accettati",
    "A national identity card": "Carta d'identità nazionale",
    "A valid passport": "Passaporto valido",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Permessi di soggiorno validi (accettati solo se rilasciati da un paese europeo o dalla Svizzera)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Riconoscimento Facciale Biometrico: Confronto del video con la foto del passaporto",
    "Address Verification:": "Verifica dell'Indirizzo:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Fornisci un estratto conto o una bolletta (meno di 3 mesi) come prova di indirizzo",
    "Additional Documents:": "Documenti Aggiuntivi:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) \nor further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom si riserva il diritto di richiedere qualsiasi documento aggiuntivo (Enhanced Due Diligence - EDD) o ulteriori informazioni necessarie per \ncondurre verifiche approfondite, in conformità con i suoi obblighi legali.",
    "Start Verification": "Inizia la Verifica",
    "Sessions": "Sessioni",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Questa è una lista di dispositivi che hanno effettuato l'accesso al tuo account. Rimuovi quelli che non riconosci.",
    "Your Withdrawal Options": "Opzioni di Prelievo",
    "Choose your preferred method for withdrawing payments.": "Scegli il tuo metodo preferito per prelevare i pagamenti.",
    "No withdrawal options currently available in your country.": "Nessuna opzione di prelievo attualmente disponibile nel tuo paese.",
    "Download Your Data": "Scarica i Tuoi Dati",
    "Request a Copy of Your Data": "Richiedi una Copia dei Tuoi Dati",
    "Submit a request to download a copy of your data": "Invia una richiesta per scaricare una copia dei tuoi dati",
    "Which Data Should Be Included in the Download?": "Quali Dati Dovrebbero Essere Inclusi nel Download?",
    "Select all applicable options.": "Seleziona tutte le opzioni applicabili.",
    "Personal Information": "Informazioni Personali",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Queste informazioni sono utilizzate per proteggere il tuo account, personalizzare la tua esperienza e contattarti se necessario.",
    "Name and date of birth": "Nome e data di nascita",
    "Phone number": "Numero di telefono",
    "Financial Information": "Informazioni Finanziarie",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Usiamo queste informazioni per velocizzare il checkout e per inviare o ricevere denaro in pochi clic.",
    "Bank accounts": "Conti bancari",
    "Debit or credit cards": "Carte di debito o di credito",
    "Credit Information": "Informazioni sul Credito",
    "Includes your credit application information": "Include le informazioni sulla tua richiesta di credito",
    "Other Information (not included in file)": "Altre Informazioni (non incluse nel file)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Include informazioni sul dispositivo, dati di utilizzo tecnico, informazioni di geolocalizzazione, preferenze di marketing, cronologia del consenso,\ne dati utilizzati per altri servizi come credito, verifica dell'identità, comunicazioni con PayPal e processori di terze parti.",
    "Choose file type:": "Scegli il tipo di file:",
    "Select file type": "Seleziona il tipo di file",
    "PDF file can be easily opened on any computer": "Il file PDF può essere facilmente aperto su qualsiasi computer",
    "Submit Request": "Invia Richiesta",
    "Delete Your Account": "Elimina il Tuo Account",
    "Ask us to delete your data and close this account": "Chiedici di eliminare i tuoi dati e chiudere questo account",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Usiamo i tuoi dati per fornire servizi, quindi se eliminiamo i tuoi dati, dobbiamo anche chiudere il tuo account.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Se il tuo account è limitato, vai al Centro Risoluzioni, seleziona 'Vai alle Limitazioni dell'Account', poi clicca 'Risolvi'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Assicurati che tutti i pagamenti in sospeso siano stati chiariti. Non saremo in grado di chiudere il tuo account fino a quando non lo faranno.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Se hai un saldo negativo, clicca sul link Risolvi accanto al tuo saldo.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Una volta che il tuo account è chiaro, e se sei idoneo, chiuderemo il tuo account ed elimineremo i tuoi dati. Abbiamo bisogno\ndi trattenere alcuni dei tuoi dati per ragioni legali e normative. Per saperne di più, vedi la nostra ",
    "privacy statement": "dichiarazione sulla privacy",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Ti invieremo un'email per farti sapere lo stato e se ci sono altri passaggi che devi fare.",
    "Have more questions about data deletion?": "Hai altre domande sulla cancellazione dei dati?",
    "Contact us": "Contattaci",
    "I understand that account deletion is permanent and can't be reversed.": "Capisco che la cancellazione dell'account è permanente e non può essere invertita.",
    "Are you sure you want to close your account?": "Sei sicuro di voler chiudere il tuo account?",
    "Yes, Continue": "Sì, Continua",
    "Logging In": "Accesso",
    "Complete your profile to streamline your transaction process.": "Completa il tuo profilo per semplificare il processo di transazione.",
    "Skip": "Salta",
    "Complete Your Profile": "Completa il Tuo Profilo",
    "API Key": "Chiave API",
    "Get API Key": "Ottieni Chiave API",
    "Generating New API Key": "Generando Nuova Chiave API",
    "Generate API Key": "Genera Chiave API",
    "Regenerate API Key": "Rigenera Chiave API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. \nFirst, sign up for an account at [your registration URL] and access the Developer Dashboard. Navigate to the \n'API Keys' section, generate a new key, and ensure its secure storage.": "Per sfruttare appieno il potenziale delle transazioni senza interruzioni di InstaPay, segui questi passaggi rapidi per generare la tua chiave API.\nPrima, registrati per un account su [URL di registrazione] e accedi alla Dashboard degli Sviluppatori. Naviga alla sezione 'Chiavi API', genera una nuova \nchiave e assicurati della sua conservazione sicura.",
    "Delete API Key": "Elimina Chiave API",
    "Webhook URL": "URL Webhook",
    "KYB Verification": "Verifica KYB",
    "Additional Files": "File Aggiuntivi",
    "Business Verification": "Verifica Aziendale",
    "--Select-Your-Company-Type--": "--Seleziona-il-Tuo-Tipo-di-Azienda--",
    "Documents Required:": "Documenti Richiesti:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Estratto Ufficiale del Registro Aziendale (Documento di Registrazione/Incorporazione dell'Entità)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. \nIt reflects the current status of the business (active or closed).": "Questo documento funge da 'carta d'identità' ufficiale dell'azienda e dovrebbe essere ottenuto dal registro aziendale locale. Riflette lo stato attuale \ndell'azienda (attiva o chiusa).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Il documento dovrebbe essere attuale, con una data di emissione non superiore a 3 mesi.",
    "The document must include the following details:": "Il documento deve includere i seguenti dettagli:",
    "Legal name of the company": "Nome legale dell'azienda",
    "Full registered address": "Indirizzo completo registrato",
    "Company registration number": "Numero di registrazione dell'azienda",
    "Date of incorporation": "Data di incorporazione",
    "Description of the company's activities/purpose": "Descrizione delle attività/scopo dell'azienda",
    "Legal representative's information: full name, date and place of birth, residential address \n(minimum requirement: country of residence), and nationality": "Informazioni sul rappresentante legale: nome completo, data e luogo di nascita, indirizzo residenziale (requisito minimo: paese di residenza), \ne nazionalità",
    "If applicable, the document should also include:": "Se applicabile, il documento dovrebbe anche includere:",
    "Brand or trading names": "Nomi commerciali o di marca",
    "Value Added Tax (VAT) number": "Numero di Partita IVA",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, \nit may be provided as a separate document.": "Elenco dei Proprietari Beneficiari Finali (UBOs) - questo è obbligatorio in alcuni paesi, altrimenti, potrebbe essere fornito come documento separato.",
    "Proof of Legal Representative's Authority": "Prova dell'Autorità del Rappresentante Legale",
    "This document validates an individual's authority to represent the company": "Questo documento convalida l'autorità di un individuo a rappresentare l'azienda",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a \ndocument such as a Power of Attorney or Delegation of Powers must be secured.": "Tipicamente, il rappresentante legale è nominato nell'estratto del registro aziendale. Tuttavia, se questo non è il caso, un documento come una Procura \no Delega di Poteri deve essere ottenuto.",
    "Power of Attorney/Delegation of Powers:": "Procura/Delega di Poteri:\n",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal \nrepresentative in the company's registry extract.": "L'individuo che conferisce l'autorità deve essere legittimamente autorizzato a farlo. Questo individuo dovrebbe essere elencato come \nrappresentante legale nell'estratto del registro aziendale.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Documentazione della Struttura di Proprietà e dei Proprietari Beneficiari Finali (UBOs)\n",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the \nstock exchange website displaying the company's details.": "Si dovrebbe fornire la prova che l'azienda è quotata in borsa pertinente. Potrebbe essere una pagina dal\nsito web della borsa che mostra i dettagli dell'azienda.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of \nthe shares will be subject to Ultimate Beneficial Owner (UBO) verification requirements.": "Se meno del 75% delle azioni dell'azienda sono di proprietà pubblica, tutti gli azionisti che possiedono o controllano più del 25% delle\nazioni saranno soggetti ai requisiti di verifica del Proprietario Beneficiario Finale (UBO).",
    "Collection of Identification Documents": "Raccolta di Documenti di Identificazione\n",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification \ndocuments must be written in the Latin alphabet. If not, a certified translation is required.": "Il documento di identificazione deve essere rilasciato dal governo, contenente una fotografia dell'individuo. Tutti i documenti di identificazione\ndevono essere scritti in alfabeto latino. In caso contrario, è richiesta una traduzione certificata.",
    "Accepted documents:": "Documenti accettati:",
    "Valid National Identity Card": "Carta d'Identità Nazionale valida",
    "Valid Passport": "Passaporto valido",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Permessi di Soggiorno validi - Saranno accettati solo se rilasciati da un paese europeo.",
    "Only legal representatives are subject to the identification document collection process.": "Solo i rappresentanti legali sono soggetti al processo di raccolta dei documenti di identificazione.\n",
    "Additional Requirement:": "Requisito Aggiuntivo:\n",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their ID.": "In casi che coinvolgono una Procura o Delega di Poteri, entrambe le parti (l'individuo che concede l'autorità e quello che la riceve) devono \nfornire una copia del loro documento d'identità.",
    "Additional Documents": "Documenti Aggiuntivi\n",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department \nthat shows the End-user’s address and name (e.g., Residential Certificate); or": "Bolletta (come gas, elettricità, telefono o cellulare); o un documento emesso da un ente governativo che mostri l'indirizzo e il nome dell'utente finale \n(ad esempio, Certificato di Residenza); oppure",
    "Bank Statement; or": "Estratto conto bancario; oppure\n",
    "a document issued by a government department that shows the End-user’s address and name \n(e.g., Residential Certificate).": "un documento emesso da un ente governativo che mostri l'indirizzo e il nome dell'utente finale (ad esempio, Certificato di Residenza).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting \nthorough verifications, in line with its legal obligations.": "Kemit Kingdom si riserva il diritto di richiedere ulteriori documenti o informazioni aggiuntive necessarie per condurre verifiche approfondite, in linea con i \nsuoi obblighi legali.",
    "Note:": "Nota:",
    "Partners": "Partner",
    "Add New Partner": "Aggiungi Nuovo Partner",
    "Designation": "Designazione",
    "Partner Name": "Nome del Partner",
    "Partner Email": "Email del Partner",
    "Partner Designation": "Designazione del Partner",
    "Add Now": "Aggiungi Ora",
    "Adding": "Aggiungendo",
    "Delete Partner": "Elimina Partner",
    "Delete": "Elimina",
    "Deleting": "Eliminando",
    "Are you sure, you want to remove sxz as partner?": "Sei sicuro di voler rimuovere sxz come partner?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Estratto del Registro dell'Entità (Documento di Registrazione/Creazione dell'Entità)",
    "This document should be either:": "Questo documento dovrebbe essere:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Una prova di creazione dell'entità, fornendo i dettagli dell'entità, data di creazione e scopo.",
    "A local registry extract:": "Un estratto del registro locale:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status \n(active or closed).": "Richiesto secondo le normative del paese, fungendo da attuale 'carta d'identità' dell'entità, indicando il suo stato (attivo o chiuso).",
    "If a registry extract exists, it must not be older than 3 months.": "Se esiste un estratto del registro, non deve avere più di 3 mesi.",
    "Entity name": "Nome dell'entità",
    "Registration number (if applicable, based on the country's requirements)": "Numero di registrazione (se applicabile, in base ai requisiti del paese)",
    "Date of creation": "Data di creazione",
    "Description of the entity's activities or purpose": "Descrizione delle attività o dello scopo dell'entità",
    "Trading names": "Nomi commerciali",
    "Articles of Association": "Statuto Sociale",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating \nrules.": "Lo Statuto Sociale funge da documento costitutivo dell'entità, delineando le sue regole operative.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of \nentities require at least one annual meeting that may result in an updated version of the Articles of Association.": "Non vi è alcuna limitazione temporale su questo documento, il che significa che è richiesta la versione più recente. Tipicamente, questi tipi di\nentità richiedono almeno un incontro annuale che può risultare in una versione aggiornata dello Statuto Sociale.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, \nroles, responsibilities, etc.": "Esempi di cambiamenti che potrebbero verificarsi includono aggiornamenti al nome dell'entità, indirizzo, scopo, consiglio di amministrazione,\nruoli, responsabilità, ecc.",
    "The document should contain information about:": "Il documento dovrebbe contenere informazioni su:",
    "The entity itself, including its legal name, registered address, and purpose": "L'entità stessa, inclusi il suo nome legale, indirizzo registrato e scopo",
    "Its internal operating rules, such as the appointment process for management board members, their \nroles and powers, among other details. This list is not exhaustive.": "Le sue regole operative interne, come il processo di nomina dei membri del consiglio di amministrazione, i loro ruoli e poteri, tra gli altri dettagli. \nQuesta lista non è esaustiva.",
    "How the entity is funded": "Come l'entità è finanziata",
    "Appointment/Removal/Changes on Board of Management Members:": "Nomina/Rimozione/Cambiamenti nei Membri del Consiglio di Amministrazione:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are \ndocumented through board of management meeting minutes, which are registered and certified to reflect the updated \nlist of members, such as the president, vice-president, treasurer, and general secretary.": "Questi aggiornamenti di solito avvengono in base alle esigenze dell'entità o come stabilito nello Statuto Sociale. I cambiamenti sono\ndocumentati attraverso verbali delle riunioni del consiglio di amministrazione, che sono registrati e certificati per riflettere l'elenco aggiornato\ndei membri, come il presidente, il vicepresidente, il tesoriere e il segretario generale.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the \nArticles of Association, if available.": "Gli ultimi verbali della riunione del consiglio di amministrazione, inclusa la lista attuale dei membri, saranno richiesti in aggiunta allo Statuto Sociale, \nse disponibile.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there \nare no board meeting minutes available, this list must be provided. It should be dated and signed by the most recently \nappointed legal representative, whose appointment can be verified through related documentation.": "Nota: Se l'elenco attuale dei membri del consiglio di amministrazione non è incluso negli ultimi Statuti Sociali e non ci sono verbali di riunione del\nconsiglio disponibili, questa lista deve essere fornita. Dovrebbe essere datata e firmata dal rappresentante legale più recentemente nominato, la cui\nnomina può essere verificata tramite documentazione correlata.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Questo documento dimostra l'autorità di un individuo di rappresentare l'entità. Questo documento potrebbe essere:",
    "The latest Articles of Association": "Gli ultimi Statuti Sociali",
    "The current list of board management members \n(which may be included in the board management meeting minutes)": "L'elenco attuale dei membri del consiglio di amministrazione (che può essere incluso nei verbali della riunione del consiglio di amministrazione)\"",
    "The document should specify:": "Il documento dovrebbe specificare:",
    "Full name of the individual(s)": "Nome completo del(i) individuo(i)",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to \nensure that the individual's function permits them to bind the entity. This is particularly important when the \napplicant is not the president, and other functions have limited representation authority as stated in \nthe Articles of Association.": "Funzione del(i) individuo(i). Queste informazioni saranno verificate incrociate con gli Statuti Sociali per assicurare che la funzione dell'individuo gli \npermetta di vincolare l'entità. Questo è particolarmente importante quando il richiedente non è il presidente, e altre funzioni hanno un'autorità di \nrappresentanza limitata come dichiarato negli Statuti Sociali.",
    "Additional information about the legal representative that will be collected includes:": "Ulteriori informazioni sul rappresentante legale che saranno raccolte includono:",
    "Date and place of birth": "Data e luogo di nascita",
    "Residential address (at least the country of residence should be stated)": "Indirizzo residenziale (almeno il paese di residenza dovrebbe essere indicato)",
    "Nationality": "Nazionalità",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Struttura di Proprietà e Proprietari Beneficiari Finali (UBO)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board \nmembers will be identified and treated as such for screening purposes, ID collection and verification, etc.": "Tipicamente, questi tipi di entità, date la loro struttura e scopo, non hanno proprietari benefici. Tuttavia, i membri del\nconsiglio saranno identificati e trattati come tali a fini di screening, raccolta e verifica dell'identità, ecc.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, \nthis detail will be mentioned in the Articles of Association. These individuals will be easily identified and treated as such, \nalongside management board members.": "Un piccolo numero di associazioni potrebbe avere proprietari benefici, in particolare quelle che generano profitti. In tali casi, questo dettaglio sarà \nmenzionato negli Statuti Sociali. Questi individui saranno facilmente identificati e trattati come tali, insieme ai membri del consiglio di amministrazione.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Requisito Aggiuntivo: In casi che coinvolgono una Procura o Delega di Poteri, entrambe le parti\n(l'individuo che concede l'autorità e quello che la riceve) devono fornire una copia del loro documento di identità.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Estratto del Registro di Commerciante Individuale (Documento di Registrazione/Creazione dell'Entità)\n",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Questo documento funge da registro locale per i commercianti individuali, richiesto nei paesi dove tale registrazione è obbligatoria.\nVerifica che l'individuo sia impegnato in un'attività professionale come commerciante individuale. Il nome di questo documento varia da\npaese a paese.",
    "Where applicable, the document should not be older than 3 months.": "Dove applicabile, il documento non deve essere più vecchio di 3 mesi.",
    "The document must contain:": "Il documento deve contenere:",
    "Individual's name": "Nome dell'individuo",
    "Residential address": "Indirizzo residenziale",
    "Registration number": "Numero di registrazione",
    "The sole trader is the only individual subject to the identification document collection process.": "Il commerciante individuale è l'unico soggetto al processo di raccolta dei documenti di identificazione.",
    "No Transactions": "Nessuna Transazione",
    "No Pending Items": "Nessun Elemento in Sospeso",
    "No Beneficiaries": "Nessun Beneficiario",
    "Send Money To": "Invia Denaro a",
    "Confirmation": "Conferma",
    "You are setting": "Stai impostando",
    "as default.": "come predefinito.",
    "Previously": "In precedenza",
    "was default QR.": "era il QR predefinito.",
    "Category": "Categoria",
    "-Select-From-The-List-": "-Selezione-Dall'Elenco-",
    "Add a note": "Aggiungi una nota",
    "Categories Added Successfully!": "Categorie Aggiunte con Successo!",
    "Subscription starting date": "Data di inizio dell'abbonamento",
    "Until": "Fino a",
    "Next Transaction": "Prossima Transazione",
    "Transaction Method": "Metodo di Transazione",
    "Accept the quotation": "Accetta la quotazione",
    "Choose Currency": "Scegli la Valuta",
    "Accept": "Accetta",
    "Bargain the quotation": "Contratta la quotazione",
    "Enter Counter Amount:": "Inserisci l'Importo Controproposta:",
    "Bargain": "Contratta",
    "Decline the quotation": "Annulla la quotazione",
    "Are you sure, you want to decline this quotation?": "Sei sicuro di voler rifiutare questa quotazione?",
    "Decline": "Rifiuta",
    "Personal Account": "Account Personale",
    "Jan": "Gen",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Apr",
    "May": "Mag",
    "Jun": "Giu",
    "Jul": "Lug",
    "Aug": "Ago",
    "Sep": "Set",
    "Oct": "Ott",
    "Nov": "Nov",
    "Dec": "Dic",
    "Today": "Oggi",
    "Spent In": "Trascorso in",
    "Revised Amount": "Importo rivisto",
    "You spent more comparing to last month": "Hai speso di più rispetto al mese scorso",
    "Last": "Scorso",
    "days": "Giorni",
    "Year": "Anni",
    "Instant Mobile Top-up Across 150+ Countries": "Ricarica Mobile Istantanea in Oltre 150 Paesi",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Rendi felici i tuoi cari o ricarica il tuo telefono, ovunque tu sia! Il servizio di Ricarica Mobile di InstaPay ti permette di ricaricare istantaneamente i cellulari in oltre 150 paesi. Perfetto per regali o per garantire di essere sempre connessi, il nostro servizio è progettato per essere facile, veloce e sicuro.",
    "How It Works - Step-by-Step Guide:": "Come Funziona - Guida Passo Dopo Passo:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Scegli 'Ricarica Mobile': Accedi a questa funzione direttamente nell'app InstaPay su piattaforme come WhatsApp, Twitter, Telegram o Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Seleziona Valuta e Importo: Scegli tra diverse valute (USD, GBP, EUR, ecc.) e specifica quanto desideri ricaricare.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Inserisci il Numero di Cellulare: Digita il numero che desideri ricaricare—il tuo o quello di un caro. Il nostro sistema garantisce un processo sicuro e veloce.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Conferma e Paga: Verifica i dettagli della ricarica, conferma e effettua il pagamento con il metodo preferito. La transazione viene elaborata istantaneamente e in sicurezza.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Ricevi Conferma Immediata: Sia tu che il destinatario (se applicabile) riceverete una notifica di conferma non appena la ricarica sarà riuscita.",
    "Benefits:": "Vantaggi:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Raggi Globale: Invia facilmente il tempo aria in oltre 150 paesi.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Impatto Istantaneo: Vedi subito i benefici—perfetto per regali dell'ultimo minuto o comunicazioni urgenti.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Sicurezza Completa: Goditi la tranquillità con il nostro sistema di pagamento sicuro.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Opzioni di Pagamento Versatili: Scegli tra carte di credito, PayPal o il tuo portafoglio InstaPay per i pagamenti.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Pronto a diffondere gioia o a rimanere connesso? Ricarica ora e rendi felice il giorno di qualcuno—o il tuo!",
    "Pick a language": "Scegli una lingua",
    "French": "Francese",
    "Spanish": "Spagnolo",
    "Portuguese": "Portoghese",
    "German": "Tedesco",
    "Ukrainian": "Ucraino",
    "Italian": "Italiano",
    "Russian": "Russo",
    "Arabic": "Arabo",
    "Polish": "Polacco",
    "Dutch (Nederlands)": "Olandese",
    "Yoruba": "Yoruba",
    "Indonesian": "Indonesiano",
    "Turkish": "Turco",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Cinese",
    "Select Your Preferred Language": "Seleziona la tua lingua preferita",
    "Please select the language you would \nlike to use for the eKYC process.": "Si prega di selezionare la lingua che desideri utilizzare per il processo eKYC.",
    "Select Your Nationality": "Seleziona la tua nazionalità",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Scegli la tua nazionalità per vedere i documenti di identità supportati per il tuo paese.",
    "Grant permission to use the camera.": "Concedi il permesso di utilizzare la fotocamera.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Tocca o fai clic su \"Consenti\" quando richiesto per concedere l'autorizzazione per l'accesso alla fotocamera per acquisire il documento.",
    "Upload Your Documents from \nGallery/Photos": "Carica i tuoi documenti dalla galleria/foto",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Se riscontri problemi con la tua fotocamera o se il tuo sistema non ne ha una, puoi caricare manualmente le foto del documento dal tuo archivio locale.",
    "Upload Your ID.": "Carica il tuo documento di identità.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Assicurati che il tuo documento di identità sia chiaro e non sfocato per garantire una verifica rapida.",
    "Valid Proof of Address": "Prova di Indirizzo Valida",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Assicurati che la tua prova di indirizzo sia aggiornata e che la data dell'estratto conto sia entro gli ultimi tre mesi dalla data di inizio della tua verifica eKYC.\n\n2. Assicurati che la tua prova di indirizzo sia chiara e non sfocata per garantire una verifica rapida.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Avviso Importante\n\nSi prega di leggere attentamente tutti i passaggi di questa guida prima di procedere con la verifica eKYC.\n\nEvitare errori comuni:\n\n* Assicurarsi che tutti i documenti siano chiari, leggibili e aggiornati.\n* Verificare che le informazioni fornite corrispondano ai dettagli sui documenti ufficiali.\n* Seguire attentamente le istruzioni per evitare ritardi o rifiuti.\n\nNota: \nSe la verifica eKYC non riesce e si deve presentare nuovamente, verranno addebitati costi aggiuntivi per il processo di invio.\n\nSeguendo queste linee guida, si può contribuire a garantire una verifica eKYC fluida e di successo.\n\nPer qualsiasi domanda o assistenza, si prega di contattare il nostro team di supporto all'indirizzo support@insta-pay.ch",
    "Personal Transactions": "Transazioni personali",
    "Business Transactions": "Transazioni commerciali",
    "Shopping & Purchases": "Shopping e acquisti",
    "Bills & Utilities": "Bollette e utenze",
    "Entertainment & Leisure": "Intrattenimento e svago",
    "Investments & Savings": "Investimenti e risparmi",
    "Health & Wellness": "Salute e benessere",
    "Transportation": "Trasporto",
    "Education": "Istruzione",
    "Miscellaneous": "Varie",
    "Family Support": "Supporto familiare",
    "WALLET": "WALLET",
    "Add a currency": "Aggiungi una valuta",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Quando aggiungi una valuta, tutti i pagamenti che ricevi in quella valuta saranno accreditati sul tuo saldo InstaPay. La tua valuta principale verrà utilizzata per inviare o richiedere pagamenti, salvo diversa specifica.",

    "balance amount ": "importo del saldo",
    "referral ": "riferimento",
    "crypto wallet balance": "saldo del portafoglio crypto",
    "money in": "denaro in entrata",
    "money out": "denaro in uscita",
    "analytics": "analitica",
    "see more": "vedi di più",
    "manage my Wallets and see the transaction details": "gestire i miei Wallets e vedere i dettagli delle mie transazioni",
    "set as default Wallet": "impostare come Wallet predefinito",
    "add currency": "aggiungere valuta",
    "convert funds ": "convertire fondi",
    "withdraw balance": "prelevare saldo",
    "chart names": "nomi dei grafici",
    "withdraw balance from PKR wallet": "prelevare saldo dal wallet PKR",
    "select a payout channel": "selezionare un canale di pagamento",
    "you have entered an amount below the minimum payment range": "hai inserito un importo inferiore all'importo minimo richiesto",
    "change": "cambiare",
    "edit": "modificare",
    "moving to next step in ...": "passaggio alla fase successiva in ...",
    "you are withdrawing ...": "stai prelevando ...",
    "where is my payment": "dov'è il mio pagamento",
    "download": "scaricare",
    "IBAN or swift/BIC code": "IBAN o codice SWIFT/BIC",
    "swift code": "codice SWIFT",
    "account number": "numero di conto",
    "branch street": "via della filiale",
    "city": "città",
    "province": "provincia",
    "postal code": "codice postale",
    "all payout channels": "tutti i canali di pagamento",
    "bussiness beneficiary": "beneficiario aziendale",
    "no quotation": "nessun preventivo",
    "no pending items": "nessun elemento in sospeso",
    "QR status": "Stato del QR",
    "Portfolio": "Portafoglio",
    "edit whole portfolio page": "modifica tutta la pagina del portafoglio",
    "personal account": "account personale",
    "Chart names": "Nomi dei grafici",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "altro",
    "not connected": "non connesso",
    "code": "codice",
    "ratings": "valutazioni",
    "current password": "password attuale",
    "include at least one uppercase and lowercase": "includi almeno una maiuscola e una minuscola",
    "my social network accounts": "i miei account social network",
    "SMS ": "SMS",
    "view identity verfication guide": "visualizza la guida alla verifica dell'identità",
    "boimetric face recognition": "riconoscimento facciale biometrico",
    "please select a payer (withdrawl options)": "per favore seleziona un pagatore (opzioni di prelievo)",
    "IBAN number ": "numero IBAN",
    "account holder name": "nome del titolare del conto",
    "download data": "scarica i dati",
    "bank transfer": "bonifico bancario",
    "mobile wallet": "portafoglio mobile",
    "all bank names": "tutti i nomi delle banche",
    "pay via card": "paga con carta",
    "you have entered an amount that is below the payer range": "hai inserito un importo inferiore all'intervallo del pagatore",
    "trouble sending money": "problemi con l'invio di denaro",
    "please follow these example": "si prega di seguire questi esempi",
    "wallet ID ": "ID Wallet",
    "username": "nome utente",
    "email": "email",
    "phone number": "numero di telefono",
    "no user found": "nessun utente trovato",
    "search": "cerca",
    "scan/upload QR code of the recipient": "scansiona/carica il codice QR del destinatario",
    "invite them": "invitali",
    "trouble quoting money": "problema nel quotare denaro",
    "select a country": "seleziona un paese",
    "airtime details": "dettagli della ricarica",
    "enter the phone number": "inserisci il numero di telefono",
    "service type": "tipo di servizio",
    "select a service": "seleziona un servizio",
    "service list": "elenco dei servizi",
    "amount entered is less than the ....": "l'importo inserito è inferiore a ....",
    "total": "totale",
    "total credit the receiver gets": "il destinatario riceve in totale",
    "take ": "prendere",
    "OTP has been verified , You'll be redirected......": "OTP è stato verificato, verrai reindirizzato......",
    "Link Your Instagram Account to InstaPay": "Collega il tuo account Instagram a InstaPay",
    "Step 01 – From Instagram App": "Passo 01 – Dall'app Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Apri l'app Instagram, cerca la pagina del profilo \"InstaPay\", seleziona \"Messaggio\" per aprire la chat, digita/dì \"Ciao\" e fai clic su invia per avviare la conversazione con il chatbot di InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Passo 02 – Dalla pagina del profilo Instagram di InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Il chatbot di InstaPay mostrerà due pulsanti di azione: \"Registrati\" e \"Connetti\". Seleziona \"Connetti\" e il chatbot ti chiederà di inserire il tuo nome utente InstaPay.",
    "Step 03 – From InstaPay Instagram Profile Page": "Passo 03 – Dalla pagina del profilo Instagram di InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Il chatbot di InstaPay chiederà quindi il codice di verifica InstaPay. Inserisci il codice che hai ottenuto dalle impostazioni di InstaPay in \"I miei account di social network\" e fai clic su invia.",
    "Step 04 – From InstaPay Instagram Profile Page": "Passo 04 – Dalla pagina del profilo Instagram di InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Riceverai un messaggio di congratulazioni che conferma che il tuo account Instagram è ora sincronizzato con InstaPay. Clicca su \"Menu principale\" per iniziare a usare i servizi di InstaPay.",
    "Additional Tips": "Suggerimenti aggiuntivi",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Proteggi il tuo account: Assicurati che il tuo account Instagram sia sicuro abilitando l'autenticazione a due fattori (2FA) per proteggere i tuoi servizi InstaPay collegati.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Esplora di più: Una volta collegato, esplora la vasta gamma di servizi offerti da InstaPay, dalle transazioni senza interruzioni ai prelievi istantanei.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Rimani aggiornato: Tieni d'occhio i nostri aggiornamenti, poiché presto supporteremo il collegamento di altri account di social network per rendere la tua esperienza ancora più integrata.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Aiuto e supporto: Se riscontri problemi durante il processo di collegamento, contatta il nostro team di supporto per assistenza.",
    "Future Enhancements": "Miglioramenti futuri",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Nel prossimo futuro, InstaPay ti permetterà di collegare più account di social network, offrendo ancora più flessibilità e convenienza. Resta sintonizzato per aggiornamenti entusiasmanti!",
    "Personal Transactions": "Transazioni personali",
    "Business Transactions": "Transazioni commerciali",
    "Shopping & Purchases": "Shopping e acquisti",
    "Bills & Utilities": "Bollette e utenze",
    "Entertainment & Leisure": "Intrattenimento e svago",
    "Investments & Savings": "Investimenti e risparmi",
    "Health & Wellness": "Salute e benessere",
    "Transportation": "Trasporto",
    "Education": "Istruzione",
    "Miscellaneous": "Varie",
    "Family Support": "Supporto familiare",
    "Web - login with phone number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Inizia oggi il tuo viaggio con InstaPay!",
    "Enter your phone number": "Inserisci il tuo numero di telefono",
    "Login with email": "Accedi con email",
    "Don't have an account?": "Non hai un account?",
    "Sign Up": "Iscriviti",
    "Continue": "Continua",
    "We recommend you download and install the Google Authenticator app": "Ti consigliamo di scaricare e installare l'app Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "L'autenticazione a due fattori (2FA) è il modo migliore per proteggerti online.",
    "Strong security with Google Authenticator": "Sicurezza avanzata con Google Authenticator",
    "Enter your password": "Inserisci la tua password",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Ti consigliamo di scaricare e installare l'app Google Authenticator per assicurarti di ricevere i tuoi codici di verifica istantaneamente, evitando eventuali ritardi o problemi con gli SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Codice di Autenticazione a Due Fattori",
    "Problem with the token?": "Problemi con il token?",
    "Verify using SMS": "Verifica tramite SMS",
    "Verify": "Verificare",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Verifica SMS",
    "Enter the SMS code sent to +41********4053": "Inserisci il codice SMS inviato a +41********4053",
    "Verify using Authenticator App": "Verifica utilizzando l'app Authenticator",
    "Resend code in 120 seconds": "Invia nuovamente il codice tra 120 secondi",
    "Verify ": "Verificare",
    "SMS - Web Mobile": null,
    "My own account": "Il mio account",
    "Friends & Family": "Amici e Famiglia",
    "Aunt": "Zia",
    "Brother-in-law": "Cognato",
    "Cousin": "Cugino",
    "Daughter": "Figlia",
    "Father": "Padre",
    "Father-in-law": "Suocero",
    "Friend": "Amico",
    "Grandfather": "Nonno",
    "Grandmother": "Nonna",
    "Husband": "Marito",
    "Mother": "Madre",
    "Mother-in-law": "Suocera",
    "Nephew": "Nipote (maschio)",
    "Niece": "Nipote (femmina)",
    "Self (i.e. the sender, himself)": "Se stesso (cioè il mittente)",
    "Sister": "Sorella",
    "Sister-in-law": "Cognata",
    "Son": "Figlio",
    "Uncle": "Zio",
    "Wife": "Moglie",
    "Others not listed": "Altri non elencati",
    "Merchant": "Commerciante",
    "No relationship": "Nessuna relazione",
    "Add Funds": "Aggiungi fondi",
    "Enter the Amount": "Inserisci l'importo:",
    "Fees": "Commissioni:",
    "You Will Get": "Riceverai:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Il valore della transazione deve essere compreso tra [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Importo non valido: Il tuo attuale livello di verifica dell'identità limita l'importo massimo che puoi aggiungere. Si prega di aggiornare la verifica dell'identità per beneficiare di limiti più elevati.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Il prezzo della valuta cambia frequentemente in base alle condizioni di mercato. Si prega di fare riferimento al prezzo nella pagina di conferma dell'ordine come preventivo finale.",
    "Next": "Avanti",
    "Upgrade Identity Verification": "Aggiorna verifica dell'identità",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Richiedi denaro a chiunque, ovunque, comprese le piattaforme di social network. Con InstaPay, le tue richieste di pagamento sono immediatamente accessibili una volta accettate e i fondi vengono inviati ai tuoi conti di ricezione. Semplifica le tue transazioni e gestisci i pagamenti con facilità e sicurezza.",
    "You’ve been logged out.": "Sei stato disconnesso.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Per la tua sicurezza, sei stato disconnesso dopo 15 minuti di inattività. Effettua di nuovo l'accesso se desideri utilizzare InstaPay.",
    "LOGIN AGAIN": "ACCEDI DI NUOVO",
    "Brother": "Fratello",
    "Current Password": "Password attuale",
    "The new password cannot be the same as the old one": "La nuova password non può essere uguale a quella vecchia.",
    "Minimum password length: [X] characters ": "Lunghezza minima della password: [X] caratteri",
    "Include at least one uppercase and one lowercase letter.": "Includi almeno una lettera maiuscola e una minuscola.",
    "Nominee Information": "Informazioni sul nominato",
    "Verification Code Preferences": "Preferenze del codice di verifica",
    "How Others Can Find You": "Come gli altri possono trovarti",
    "My Social Network Accounts": "I miei account dei social network",
    "Login Activity": "Attività di accesso",
    "Receiving Accounts": "Conti di ricezione",
    "Change Your Password To Continue": "Cambia la tua password per continuare",
    "Continue with": "Continua con",
    "Back to Login": "Torna al login",
    "Send OTP": "Invia OTP",
    "A Verification code has been sent to your email and number.": "Un codice di verifica è stato inviato alla tua email e numero.",
    "Resend available in": "Rinvia disponibile in",
    "Create a strong Password": "Crea una password forte",
    "Password must:": "La password deve:",
    "Be between 9 and 64 characters": "Essere tra 9 e 64 caratteri",
    "Include at least two of the following:": "Includere almeno due dei seguenti:",
    "Lowercase Character": "Carattere minuscolo",
    "Special Character": "Carattere speciale",
    "Password Updated Successfully!": "Password aggiornata con successo!",
    "Click below to login": "Clicca sotto per accedere",

    "Send Money": "Invia denaro",
    "Enter the amount": null,
    "Recent Transfer": "Trasferimento recente",
    "See all": "Vedi tutto",
    "Send again": "Invia di nuovo",
    "You Send": "Tu invii",
    "They Receive": "Loro ricevono",
    "Receive Method": "Metodo di ricezione",
    "Select method": "Seleziona metodo",
    "Next": "Successivo",
    "Trouble sending money?": "Problemi nell'inviare denaro?",
    "Where do you want to send money ?": "Dove vuoi inviare denaro?",
    "Search ": "Cerca",
    "Confirm": "Conferma",
    "Bank Deposit": "Deposito Bancario",
    "Cash Pick-up": "Ritiro in Contanti",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Carta Bancaria",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Qual è il Wallet mobile del destinatario?",
    "Transfer fees": "Commissioni di trasferimento",
    "Total to pay": "Totale da pagare",
    "Choose the beneficiary!": "Scegli il beneficiario!",
    "Country": "Paese",
    "Receive method": "Metodo di ricezione",
    "You send": "Tu invii",
    "change": "cambia",
    "edit": "aggiungi nuovo",
    "add new": "Beneficiari",
    "Beneficiaries": "Gestisci beneficiari",
    "Manage Beneficiaries": "Cerca",
    "Search": "Successivo",
    "CASH PICK-UP": "RITIRO IN CONTANTI",
    "Where will your receiver collect the money": "Dove il tuo destinatario ritirerà il denaro?",
    "Cash is available to collect within minutes from any branch of [name] ": "Il denaro è disponibile per il ritiro entro pochi minuti da qualsiasi filiale di [nome]",
    "BANK ACCOUNT": "CONTO BANCARIO",
    "Transfer to recipient’s bank account": "Trasferisci sul conto bancario del destinatario",
    "BANK CARD": "CARTA BANCARIA",
    "Fast transfer directly to recipient’s bank card": "Trasferimento rapido direttamente sulla carta bancaria del destinatario",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Trasferimento rapido direttamente su un Wallet crypto",
    "Review transfer": "Rivedi trasferimento",
    "Receiver's Details": "Dettagli del destinatario",
    "Edit": "Modifica",
    "Name": "Nome",
    "Reason for sending": "Motivo dell'invio",
    "Sending": "Invio",
    "Receiver gets": "Il destinatario riceve",
    "Exchange rate": "Tasso di cambio",
    "Estimated time": "Tempo stimato",
    "Select Payment Type": "Seleziona il tipo di pagamento",
    "Credit cards": "Carte di credito",
    "Domestic cards": "Carte domestiche",
    "Mobile Wallets": "Wallets mobile",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "CARTA DI CREDITO",
    "Credit Card": "Carta di credito",
    "Select card": "Seleziona carta",
    "Card Number": "Numero di carta",
    "Enter the 16-digit card number on the card": "Inserisci il numero di carta a 16 cifre",
    "Expiry Date": "Data di scadenza",
    "Enter the expiration date of the card": "Inserisci la data di scadenza della carta",
    "CVV Number": "Numero CVV",
    "Enter the 3 or 4 digit number on the card": "Inserisci il numero a 3 o 4 cifre della carta",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Seleziona Wallet",
    "My Wallet": "Il mio Wallet",
    "Conversion": "Conversione",
    "Convert": "Converti",
    "Your payment is confirmed": "Il tuo pagamento è confermato",
    "Thanks for using InstaPay": "Grazie per aver usato InstaPay",
    "Status": "Stato",
    "Share": "Condividi",
    "Transfer created": "Trasferimento creato",
    "Payment received": "Pagamento ricevuto",
    "Payment processed": "Pagamento elaborato",
    "Transfer successful": "Trasferimento riuscito",
    "It may take few hours for the funds to appear in [user name] account": "Potrebbero essere necessarie alcune ore affinché i fondi appaiano nel conto di [nome utente]",
    "Transfer failed": "Trasferimento fallito",
    "Transaction Details": "Dettagli della transazione",
    "Need help?": "Hai bisogno di aiuto?",
    "Service Details": "Dettagli del Servizio",
    "Funds Deposit Speed": "Disponibilità dei Fondi",
    "Cut-off Time": "Ora Limite",
    "Bank holiday list": "Elenco delle Festività Bancarie",
    "Threshold limits": "Limiti Transazionali",
    "null": null,
    "Instant, Real Time": "Istantaneo, in tempo reale",
    "Same day, T+1": "Stesso giorno, T+1",
    "Non-instant, T+1": "Non istantaneo, T+1",
    "Instant Payment, Real Time": "Pagamento istantaneo, in tempo reale",
    "Transaction processed before 3:00 PM Local time": "Transazione elaborata prima delle 15:00 ora locale",
    "Delivered the same day": "Consegnato lo stesso giorno",
    "Transactions processed post 3:00 PM": "Transazioni elaborate dopo le 15:00",
    "T+1 business days": "T+1 giorni lavorativi",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "I pagamenti effettuati nei giorni feriali prima delle 15:00 saranno accreditati sul conto bancario ricevente entro la mezzanotte dello stesso giorno.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "I pagamenti effettuati nei giorni feriali dopo le 15:00 saranno accreditati entro la mezzanotte del giorno successivo.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "I pagamenti effettuati di sabato, domenica o festivi saranno accreditati sul conto entro la mezzanotte del primo giorno lavorativo successivo.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 ora locale",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Limite minimo per transazione:",
    "Maximum per transaction limit:": "Limite massimo per transazione:",
    "Daily threshold limit:": "Limite giornaliero:",
    "Weekly threshold limit:": "Limite settimanale:",
    "Monthly threshold limit:": "Limite mensile:",
    "Yearly threshold limit:": "Limite annuale:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Conto base (Non verificato)",
    "Identity Verified": "Identità verificata"

}